import { ErrorBoundary } from '@invisible/common/components/error-boundary'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { logger } from '@invisible/logger/client'
import { useContext, useQuery } from '@invisible/trpc/client'
import { theme } from '@invisible/ui/mui-theme-v2'
import { Text } from '@invisible/ui/text'
import { gray } from '@invisible/ui/themes'
import { useToasts } from '@invisible/ui/toasts'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGridPro,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowModel,
} from '@mui/x-data-grid-pro'
import { map } from 'lodash/fp'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { DEFAULT_ITEMS_PER_PAGE } from '../../common/constants'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import {
  calculateTextWidth,
  getColumnType,
  isValidFieldValue, paginateWithMeta,
  parseAsArray, removeById,
  replaceObjectById,
  themeStyleOverides,
} from './helpers'
import { CustomFooter, TCustomFooterProps } from './slots/CustomFooter'
import { CustomGridRow } from './slots/CustomGridRow'
import { CustomToolBar } from './slots/CustomToolBar'
import { EditTextarea } from './sub-components/EditTextArea'
import { GridCellExpand } from './sub-components/GridCellExpand'

export interface TModel extends Record<string, unknown> {
  [key: string]: any
}

interface CellValue {
  id: string
  baseRunId: string
  actualValue: string
  isEditable: boolean
  required: boolean
  baseVariableId: string
  expandable?: boolean
}

type TStepRun = NonNullable<inferQueryOutput<'stepRun.findById'>>

type IProps = WizardSchemas.WACConfig.TSchema & {
  stepRun: TStepRun
  isReadOnly: boolean
}


export const FlexibleTableWAC = ({ showName, name, flexibleTable: table, stepRun, isReadOnly }: IProps) => {
  const reactQueryContext = useContext()
  const { addToast } = useToasts()
  const { dispatch } = useWizardState()
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
  })

  const tableBaseId = table?.baseId as string

  const { data: queryResponse, isLoading } = useQuery(
    [
      'baseRunVariable.findOneByBaseVariableIdAndBaseRunId',
      {
        baseRunId: stepRun.baseRunId,
        baseVariableId: table?.baseVariableId as string,
      },
    ],
    { enabled: !!table?.baseVariableId }
  )

  const baseRunVariableValue: Record<string, unknown>[] = useMemo(() => {
    try {
      return parseAsArray(queryResponse?.value ?? [])
    } catch (error: any) {
      logger.error('Unable to read base run variable', error)
      addToast(`Unable to table load data: ${error?.message}`, {
        appearance: 'error',
      })
      return []
    }
  }, [queryResponse])

  // ---------------------------------
  // Dynamically build columns from all unique keys
  // ---------------------------------
  const allKeys = useMemo(() => {
    const keys = new Set<string>()
    for (const item of baseRunVariableValue) {
      Object.keys(item).forEach((k) => keys.add(k))
    }
    return Array.from(keys)
  }, [baseRunVariableValue])


  const parsedRows = useMemo(
    () => (baseRunVariableValue ?? []).map((br) => ({
      ...br,
      __id: uuid(),
    })), [baseRunVariableValue]
  )
  const paginatedRows = useMemo(
    () => paginateWithMeta(parsedRows, paginationModel.page + 1, paginationModel.pageSize),
    [parsedRows, paginationModel]
  )
  const rowCountRef = useRef(parsedRows.length ?? 0)
  const itemCount = useMemo(() => {
    if (parsedRows.length) {
      rowCountRef.current = parsedRows.length
    }
    return rowCountRef.current
  }, [parsedRows])

  const [rowCount, setRowCountState] = useState(itemCount)
  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      itemCount !== undefined ? itemCount : prevRowCountState
    )
  }, [itemCount, setRowCountState])

  const { mutateAsync: updateBaseRunVariableMutation, isLoading: isUpdatingBaseRunVariable } = useBaseRunVariablesWizardUpdate( {
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRunVariable.findOneByBaseVariableIdAndBaseRunId')
    },
  })



  const updateBaseRunVariable = useCallback(
    ({ baseVariableId, value }: { baseVariableId: string; value: any }) => {
      updateBaseRunVariableMutation({
        stepRunId: stepRun.id,
        data: [
          {
            baseRunId: stepRun.baseRunId,
            baseVariableId,
            value,
          },
        ],
      })
    },
    [updateBaseRunVariableMutation]
  )

  const gridColumnData = allKeys.map((eachKey) => ({
      label: eachKey,
      type: 'string',
    }))

  const gridColumns: GridColDef[] = map(
    (field) => {
      if (field.type === 'serial') {
        return {
          field: 'index',
          headerName: '#',
          filterable: false,
          sortable: false,
          width: 20,
          renderCell: (params) => {
            const index = params.api.getRowIndexRelativeToVisibleRows(params.row.__id)
            return paginationModel.page * paginationModel.pageSize + (index + 1)
          },
        }
      }
      const width = calculateTextWidth(field.label as string) + 120
      return {
        field: field.label,
        type: getColumnType(field.type),
        headerName: field.label,
        minWidth: width,
        flex: 1,
        editable: !isReadOnly && !table?.isReadOnly,
        placeholder: 'Enter value',
        renderHeader: () => (
          <strong>
            {field.label}
          </strong>
        ),
        ...(getColumnType(field.type) === 'string'
          ? {
              renderEditCell: (props) => <EditTextarea {...props} />,
              renderCell: (params: GridRenderCellParams<any, string>) => (
                <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
              ),
            }
          : {}),
      }
    },
    [{ type: 'serial', label: '#' }, ...gridColumnData]
  )

  // Notify the wizard that the table is ready for submit
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: `FlexibleTableWAC-${tableBaseId}`,
      value: true,
    })
  }, [baseRunVariableValue])

  const processRowUpdate = async (updatedRow: GridRowModel) => {
    const newData = replaceObjectById(
      parsedRows,
      '__id',
      updatedRow,
    )
    const newDataWithoutUniqueId = newData.map(({ __id, ...rest }) => rest);
    if (!queryResponse?.baseVariableId) {
      addToast("There was an error updating row", { appearance: "error" })
      return updatedRow
    }
    await updateBaseRunVariable({
      baseVariableId: queryResponse.baseVariableId,
      value: newDataWithoutUniqueId,
    })
    return updatedRow
  }

  const handlePaginationModelChange = useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel)
  }, [])

  const addNewRow = async () => {
    if (!queryResponse?.baseVariableId) {
      addToast("There was an error adding new row", { appearance: "error" })
      return
    }
    const newData = [...parsedRows]
    const newDataWithoutUniqueId = newData.map(({ __id, ...rest }) => rest);
    newDataWithoutUniqueId.push({})
    await updateBaseRunVariable({
      baseVariableId: queryResponse.baseVariableId,
      value: newDataWithoutUniqueId,
    })
  }

  const removeRow = async (rowId: string) => {
    if (!queryResponse?.baseVariableId) {
      addToast("There was an error deleting row", { appearance: "error" })
      return
    }
    const newData = removeById([...parsedRows], rowId)
    const newDataWithoutUniqueId = newData.map(({ __id, ...rest }) => rest);
    await updateBaseRunVariable({
      baseVariableId: queryResponse.baseVariableId,
      value: newDataWithoutUniqueId,
    })
  }

  const handleRemoveRow = async (rowId: string) => {
    if (window.confirm('Are you sure you want to delete this base run?') === false) return
    await removeRow(rowId)
  }

  return (
    <Box
      sx={{
        height: '100%',
        borderRadius: '8px',
        backgroundColor: 'white',
        padding: '10px',
        border: `1px solid ${gray(4)}`,
        overflow: 'auto',
        boxSizing: 'border-box',
        boxShadow: 'rgba(0, 0, 0, 0.024) 0px 2px 4px',
      }}>
      <div className='mb-2 flex items-center'>
        {showName ? <Text fontWeight='bold'>{name}</Text> : null}
      </div>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <DataGridPro
            loading={isLoading}
            rows={paginatedRows.items}
            getRowId={(row) => row.__id}
            columns={gridColumns}
            cellModesModel={cellModesModel}
            onCellModesModelChange={setCellModesModel}
            onCellEditStop={(params, event: any) => {
              // Check if the target is inside a Portal
              if (event.target?.nodeType === 1 && !event.currentTarget.contains(event.target)) {
                event.defaultMuiPrevented = true
              }
            }}
            processRowUpdate={processRowUpdate}
            pageSizeOptions={[5, 10, 25, 40, 75, 100]}
            paginationMode='server'
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            rowCount={rowCount}
            showColumnVerticalBorder={true}
            showCellVerticalBorder={true}
            slots={{
              toolbar: CustomToolBar,
              footer: CustomFooter,
              row: CustomGridRow,
            }}
            slotProps={{
              footer: {
                addNewRow: table?.allowAddRows ? addNewRow : undefined,
                isAddingNewRow: isUpdatingBaseRunVariable,
              } as TCustomFooterProps,
              toolbar: {
                refresh: () => reactQueryContext.invalidateQueries('baseRunVariable.findOneByBaseVariableIdAndBaseRunId'),
              },
              row: {
                deleteRow: table?.allowDeleteRows ? handleRemoveRow : undefined,
              },
            }}
            sx={themeStyleOverides}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              const cell = params.row[params.field] as CellValue
              return cell?.required && !isValidFieldValue(params.value) ? 'Mui-error' : ''
            }}
          />
        </ThemeProvider>
      </ErrorBoundary>
    </Box>
  )
}
