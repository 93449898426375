import { z } from '@invisible/zod'

import { schema as WizardConfigSchema } from './WizardConfig'
import { schema as AutosaveConfigSchema } from './AutosaveConfig'

// Add explicit type annotation
const schema: z.ZodObject<{
  wizardConfig: z.ZodOptional<typeof WizardConfigSchema>
  trainingLink: z.ZodOptional<z.ZodString>
  autosaveConfig: z.ZodOptional<typeof AutosaveConfigSchema>
}> = z.object({
  wizardConfig: WizardConfigSchema.optional(),
  trainingLink: z.string().optional(),
  autosaveConfig: AutosaveConfigSchema.optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
