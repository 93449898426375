import { z } from '@invisible/zod'

import { schema as metadataForm } from './Form'

const schema = z.object({
  models: z
    .array(
      z.object({
        id: z.string().optional(),
        name: z.string(),
        numOfCalls: z.number().optional(),
        params: z.array(
          z.object({
            name: z.string(),
            value: z.any(),
            type: z.enum(['string', 'number', 'boolean', 'object', 'array']),
          })
        ),
        responseMetaParams: z
          .array(
            z.object({
              id: z.string(),
              key: z.string(),
              baseVariableId: z.string(),
            })
          )
          .optional(),
      })
    )
    .optional(),
  disablePaste: z.boolean().optional(),
  conversationsBaseId: z.string().optional(),
  promptsBaseId: z.string().optional(),
  responsesBaseId: z.string().optional(),
  promptTextBaseVariableId: z.string().optional(),
  promptDurationInSecsBaseVariableId: z.string().optional(),
  promptIndexBaseVariableId: z.string().optional(),
  promptResponseBaseVariableId: z.string().optional(),
  promptAcceptedModelBaseVariableId: z.string().optional(),
  responseIdBaseVariableId: z.string().optional(),

  conversationPreambleBaseVariableId: z.string().optional(),
  conversationInstructionBaseVariableId: z.string().optional(),
  conversationModelTempBaseVariableId: z.string().optional(),
  conversationMinTurnsBaseVariableId: z.string().optional(),
  conversationMaxTurnsBaseVariableId: z.string().optional(),

  responseTextBaseVariableId: z.string().optional(),
  responseOriginalTextBaseVariableId: z.string().optional(),
  responseModelBaseVariableId: z.string().optional(),
  responseIndexBaseVariableId: z.string().optional(),
  responseRatingBaseVariableId: z.string().optional(),
  responseRankBaseVariableId: z.string().optional(),
  allowEditPrompt: z.boolean().optional(),
  allowDeletePrompt: z.boolean().optional(),
  allowEditResponse: z.boolean().optional(),
  allowSelectResponse: z.boolean().optional(),
  hideResponseModelName: z.boolean().optional(),
  disableAddNewPrompt: z.boolean().optional(),
  disablePromptResponsesRefresh: z.boolean().optional(),
  allowPromptResponsesRefreshOnImportedTurns: z.boolean().optional(),
  allowAllTurnsEditing: z.boolean().optional(),
  disablePromptMetadataEdit: z.boolean().optional(),
  disableResponseMetadataEdit: z.boolean().optional(),
  defaultRenderMode: z.enum(['plaintext', 'markdown', 'latex']).optional(),
  hidePreambleText: z.boolean().optional(),
  minMaxTurns: z.number().array().length(2).optional(),
  allowEndingWithoutResponse: z.boolean().optional(),
  allowEditingImportedTurns: z.boolean().optional(),
  allowRandomSampling: z.boolean().optional(),
  allowRandomizeModelResponses: z.boolean().optional(),
  numOfModelsToSample: z.number().optional(),
  promptResponseTextDirection: z.string().optional(),

  promptMetadata: metadataForm.optional(),
  responseMetadata: metadataForm.optional(),

  responseSelectValidations: z.array(z.string()).optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
