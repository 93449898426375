import { z } from '@invisible/zod'

const schema = z.object({
  stepId: z.string().uuid().optional(),
  outputBaseVariableId: z.string().uuid().optional(),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
