import { useAbilityContext } from '@invisible/authorization/client'
import {
  BreadcrumbsContextProvider,
  GeneralLayout,
  Header,
  PageContextProvider,
  PageHeader,
} from '@invisible/common/layouts'
import { recordPageView } from '@invisible/segment'
import {
  FinanceFilledIcon,
  FinanceIcon,
  GlobeFilledIcon,
  GlobeIcon,
  HomeFilledIcon,
  HomeIcon,
  TechnologiesFilledIcon,
  TechnologiesIcon,
  TrainingGradFilledIcon,
  TrainingGradIcon,
} from '@invisible/ui/icons'
import { ProfileMenu } from '@invisible/ui/profile-menu'
import { Skeleton } from '@invisible/ui/skeleton'
import { setUpRumUser } from '@invisible/utils/datadog/client'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { FC, useEffect } from 'react'

import { RESTRICTED_PATHS, ROUTES, useRouteAccessControl } from './routing'

interface ISidebarOption {
  title: string
  path: string
  renderIcon: (filled?: boolean) => JSX.Element
  items: Omit<ISidebarOption, 'items' | 'renderIcon'>[]
}

const allSidebarOptions: ISidebarOption[] = [
  {
    title: 'Finance',
    path: '/finance',
    renderIcon: (filled) =>
      filled ? <FinanceFilledIcon className='h-5 w-5' /> : <FinanceIcon className='h-5 w-5' />,
    items: [
      { title: 'User Management', path: '/finance/user-management' },
      { title: 'Auto Pay', path: '/finance/auto-pay' },
      { title: 'Draft Off-Cycle', path: '/finance/draft-off-cycle' },
      { title: 'Draft Lead Pay', path: '/finance/draft-lead-pay' },
      { title: 'Bill Change Requests', path: '/finance/bill-change-requests' },
      { title: 'Invoice Change Requests', path: '/finance/invoice-change-requests' },
    ],
  },
  {
    title: 'Operations',
    path: '/operations',
    renderIcon: (filled) =>
      filled ? <GlobeFilledIcon className='h-5 w-5' /> : <GlobeIcon className='h-5 w-5' />,
    items: [
      { title: 'Bulk Upload Results', path: '/operations/bulk-upload-results' },
      { title: 'Bulk Upload Time Entries', path: '/operations/bulk-upload-time-entries' },
      {
        title: 'Upload Geo Wage Floor Time Entries',
        path: '/operations/upload-geo-wage-floor-time-entries',
      },
      { title: 'Onboarding Configurations', path: '/operations/onboarding-config' },
      { title: 'User Management', path: '/operations/user-management' },
      { title: 'Company Specific Provisions', path: '/operations/company-specific-provisions' },
      { title: 'Step Specific Provisions', path: '/operations/step-specific-provisions' },
    ],
  },
  {
    title: 'People',
    path: '/people',
    renderIcon: (filled) =>
      filled ? (
        <TrainingGradFilledIcon className='h-5 w-5' />
      ) : (
        <TrainingGradIcon className='h-5 w-5' />
      ),
    items: [
      { title: 'Badges', path: '/people/badges' },
      { title: 'User Management', path: '/people/user-management' },
    ],
  },
  {
    title: 'Technologies',
    path: '/technologies',
    renderIcon: (filled) =>
      filled ? (
        <TechnologiesFilledIcon className='h-5 w-5' />
      ) : (
        <TechnologiesIcon className='h-5 w-5' />
      ),
    items: [
      { title: 'Banner Manager', path: '/technologies/banner-management' },
      { title: 'Credential Type Management', path: '/technologies/credential-type-management' },
      { title: 'Internal Onboarding', path: '/technologies/internal-onboarding' },
      { title: 'Manticore Testimonials', path: '/technologies/manticore-testimonials' },
      { title: 'Manticore Start Guide', path: '/technologies/manticore-start-guide' },
      { title: 'Permission Management', path: '/technologies/permission-management' },
      { title: 'Reports Management', path: '/technologies/reports-management' },
      { title: 'Step Template Management', path: '/technologies/step-template-management' },
      { title: 'Service Management', path: '/technologies/service-management' },
    ],
  },
]

// eslint-disable-next-line @typescript-eslint/ban-types
const Layout: FC = ({ children }) => {
  const { data: session } = useSession()
  const user = session?.user
  const [Can, ability] = useAbilityContext()
  const router = useRouter()

  const sidebarOptions = allSidebarOptions.filter((o) => {
    if (!RESTRICTED_PATHS.includes(o.path)) return true
    return ability?.can('access', 'Page', { path: o.path })
  })

  useEffect(() => {
    if (session?.user) {
      const memory = window?.isSecureContext ? navigator?.deviceMemory : null
      const user = {
        ...(session.user.email && { id: session.user.email }),
        memory,
      }
      setUpRumUser(user)
    }
  }, [session?.user])

  useEffect(() => {
    recordPageView(router.pathname)
  }, [router.pathname])

  useRouteAccessControl(ROUTES)

  return (
    <BreadcrumbsContextProvider>
      <PageContextProvider>
        <Can I='access' this={{ App: { name: 'admin' } }}>
          <GeneralLayout>
            <GeneralLayout.Sidebar appName='admin'>
              <GeneralLayout.Sidebar.SidebarLink
                option={{
                  title: 'Home',
                  path: '/',
                  renderIcon: (filled: boolean) =>
                    filled ? (
                      <HomeFilledIcon className='h-5 w-5' />
                    ) : (
                      <HomeIcon className='h-5 w-5' />
                    ),
                }}
                exact
              />
              {!ability ? (
                <div className='box-border flex w-full flex-col items-center gap-4 px-4'>
                  <Skeleton.Rectangle height={44} width={220} />
                  <Skeleton.Rectangle height={44} width={220} />
                  <Skeleton.Rectangle height={44} width={220} />
                  <Skeleton.Rectangle height={44} width={220} />
                  <Skeleton.Rectangle height={44} width={220} />
                </div>
              ) : (
                sidebarOptions.map((option, i) => (
                  <GeneralLayout.Sidebar.SidebarItem key={i} option={option}>
                    {option.items?.map((subOption) => (
                      <GeneralLayout.Sidebar.SidebarLink key={subOption.path} option={subOption} />
                    ))}
                  </GeneralLayout.Sidebar.SidebarItem>
                ))
              )}
            </GeneralLayout.Sidebar>
            <GeneralLayout.ContentArea>
              <GeneralLayout.ContentArea.Topbar>
                {user && (
                  <ProfileMenu
                    profilePicture={user?.image || ''}
                    profileText={user?.name || ''}
                    profileSubText={user?.email || ''}
                    profileOptions={[]}
                  />
                )}
              </GeneralLayout.ContentArea.Topbar>
              <GeneralLayout.ContentArea.Page>{children}</GeneralLayout.ContentArea.Page>
              <GeneralLayout.ContentArea.CookieConsentBar />
            </GeneralLayout.ContentArea>
          </GeneralLayout>
        </Can>
      </PageContextProvider>
    </BreadcrumbsContextProvider>
  )
}

const GetSecondaryLayout =
  ({ title, subtitle }: { title: string; subtitle: string }) =>
  (page: NextPage) => {
    const SecondaryLayout = (
      <>
        <Header>
          <PageHeader title={title} subtitle={subtitle} />
        </Header>
        {page}
      </>
    )
    return SecondaryLayout
  }

export { GetSecondaryLayout, Layout }
