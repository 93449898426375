import Box from '@mui/material/Box'
import Stack, { StackProps } from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { FC } from 'react'
import ReactGrid, { WidthProvider } from 'react-grid-layout'

const RGLWidthProvider = WidthProvider(ReactGrid)

// eslint-disable-next-line @typescript-eslint/ban-types
export const WizardTitle = ({ children = 'Wizard' }: { children?: string }) => (
  <Tooltip title={children}>
    <Box maxWidth='100%' minWidth={0}>
      <Typography
        variant='h4'
        color='white'
        fontWeight={500}
        fontSize='18px'
        lineHeight='160%'
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '100%',
        }}>
        {children}
      </Typography>
    </Box>
  </Tooltip>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const WizardContainer: FC = ({ children, ...props }) => (
  <div
    {...props}
    className='bg-void flex h-[calc(100vh_-_20px)] w-[calc(100vw_-_20px)] flex-col overflow-auto rounded-lg shadow-md'>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const Backdrop: FC = ({ children, ...props }) => (
  <div
    {...props}
    className='fixed top-0 left-0 z-[99] flex h-screen w-screen items-center justify-center bg-[rgba(255,255,255,0.3)]'>
    {children}
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const Topbar = ({ children, ...props }: StackProps) => (
  <Stack
    direction='row'
    justifyContent='space-between'
    alignItems='center'
    px={3}
    py={2}
    bgcolor='primary.main'
    color='white'
    {...props}>
    {children}
  </Stack>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export const RGLContainer: FC<ReactGrid.ReactGridLayoutProps & ReactGrid.WidthProviderProps> = ({
  children,
  ...props
}) => (
  <RGLWidthProvider {...props} className='bg-weak-2 relative box-border h-full overflow-auto'>
    {children}
  </RGLWidthProvider>
)
