import { z } from '@invisible/zod'

const jsonSchema: z.ZodType<any> = z.lazy(() =>
  z
    .object({
      $schema: z.string().optional(),
      title: z.string().optional(),
      description: z.string().optional(),
      type: z.enum(['string', 'number', 'boolean', 'object', 'array', 'null']).optional(),
      properties: z.record(jsonSchema).optional(),
      items: z.union([jsonSchema, z.array(jsonSchema)]).optional(),
      required: z.array(z.string()).optional(),
      enum: z.array(z.any()).optional(),
      const: z.any().optional(),
      additionalProperties: z.union([z.boolean(), jsonSchema]).optional(),
      minimum: z.number().optional(),
      maximum: z.number().optional(),
      exclusiveMinimum: z.number().optional(),
      not: z.any().optional(),
      exclusiveMaximum: z.number().optional(),
      error_message: z.string().optional(),
      resolution_steps: z.string().optional(),
      severity: z.string().optional(),
    })
    .strict()
)
type TSchema = z.infer<typeof jsonSchema>

export { jsonSchema }

export type { TSchema }
