import { constObjectToZod, z } from '@invisible/zod'

const FORM_FIELD_TYPE = {
  CODE: 'code',
  INPUT: 'input',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  DATETIME: 'datetime',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  URL: 'url',
  STRING: 'string',
  MULTILINESTRING: 'multilinestring',
  TEXTINPUTPREVIEW: 'textinputpreview',
  SCALE: 'scale',
  YESNO: 'yesno',
  HTML: 'html',
  NOTE: 'note',
  RICHTEXT: 'richtext',
  FILE: 'file',
  RADIO: 'radio',
  MULTISELECT: 'multiselect',
  LOOMVIDEO: 'loomvideo',
} as const

const schema = constObjectToZod(FORM_FIELD_TYPE)
type TSchema = z.infer<typeof schema>

export { FORM_FIELD_TYPE, schema }
export type { TSchema }
