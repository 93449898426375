import { CodeWAC } from '@invisible/common/components/process-base'
import { TBaseVariable } from '@invisible/common/components/process-base'
import { classNames } from '@invisible/common/helpers'
import { SnackbarContext } from '@invisible/common/providers'
import { Button } from '@invisible/ui/button'
import { Card } from '@invisible/ui/card'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import type { UploadedFile } from '@invisible/ui/file-uploader'
import { FileUploaderDropzone } from '@invisible/ui/file-uploader'
import { TextArea } from '@invisible/ui/form'
import { InfoFilledIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { MultiSelect } from '@invisible/ui/multi-select'
import { NullSwitch } from '@invisible/ui/null-switch'
import { Progress } from '@invisible/ui/progress'
import { QuillEditor } from '@invisible/ui/quill-editor'
import { Radio } from '@invisible/ui/radio'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { Box, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { format } from 'date-fns/fp'
import { zonedTimeToUtc } from 'date-fns-tz'
import { isEmpty, isNil, size } from 'lodash/fp'
import dynamic from 'next/dynamic'
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { TBaseRunQueryData } from '../../../process-base/src'
import { CheckList } from './components/CheckList'
import { EmbedLoomVideo } from './components/EmbedLoomVideo'
import MultiLineString from './components/MultiLineString'
import { Preview } from './components/Preview'
import { Section } from './components/Section'
import TextInputPreview from './components/TextInputPreview'
import { GOOGLE_CLOUD_BUCKET_NAME } from './env'
import { useMultiselectFieldTypeValidation } from './useFieldTypeValidation'
import {
  getCharacterAndWordLimitInfo,
  getLimitText,
  getNumberHelperText,
  getText,
} from './utils/string-helpers'

type TCheckListValue = Record<string, boolean>
type TBaseRun = TBaseRunQueryData['items'][number]
type TBaseRunVariable = TBaseRun['baseRunVariables'][number]

export type TFormValues = Record<
  string,
  string | number | boolean | Date | string[] | null | undefined
>

const YesNoButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active, theme }) => ({
  width: '70px',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '4px',
  border: '1px solid #0000003B',
  padding: theme.spacing(1, 2),
  transition: 'background-color 0.3s',
  backgroundColor: active ? '#EDE7F6' : 'transparent',
  color: active ? '#000000DE' : 'inherit',
  '&:hover': {
    backgroundColor: '#0000000A',
  },
}))

export interface IConditionalFormProps {
  form?: WizardSchemas.Form.TSchema
  formValues: TFormValues
  formBaseVariables?: TBaseVariable[]
  onSetFormValues?: (val: TFormValues) => void
  onCanSubmit?: (value: boolean) => void
  onShowReview?: (state: boolean) => void
  setResetState: (func: () => void) => void
  initialFormValues: TBaseRunVariable[]
  formReference?: string
  isReadOnly?: boolean
  baseRunId?: string
  stepRunId?: string
  processId?: string
}

export function ConditionalForm({
  form,
  initialFormValues,
  formValues,
  formBaseVariables,
  baseRunId,
  stepRunId,
  processId,
  onSetFormValues,
  setResetState,
  onShowReview,
  onCanSubmit,
  formReference,
  isReadOnly = false,
}: IConditionalFormProps) {
  const [completedSections, setCompletedSections] = useState(0)
  const [loomVideos, setLoomVideos] = useState<string[]>([])
  const [showReview, setShowReview] = useState(false)
  const [skippedSections, setSkippedSections] = useState<WizardSchemas.FormSection.TSchema[]>([])
  const [formSubmittedFromSection, setFormSubmittedFromSection] = useState<string | undefined>()
  const formWithoutNotes = form?.fields?.filter((field) => field.type !== 'note')
  const [checkListValue, setCheckListValue] = useState<TCheckListValue>({})
  const { showSnackbar } = useContext(SnackbarContext)
  const [baseVariableIdsInError, setBaseVariableIdsInError] = useState<string[]>([])

  setResetState(() => {
    setCompletedSections(0)
    setShowReview(false)
    onShowReview?.(false)
    setCheckListValue({})
    setFormSubmittedFromSection(undefined)
    onSetFormValues?.({})
  })

  useMultiselectFieldTypeValidation(
    form?.fields,
    formBaseVariables,
    processId,
    stepRunId,
    baseRunId
  )

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onSetFormValues:
      | ((
          val: Record<string, string | number | boolean | Date | null | undefined | string[]>
        ) => void)
      | undefined,
    id: string
  ) => {
    const newValue = e.target.value
    onSetFormValues?.({
      [id]: newValue,
    })
  }

  const fieldComponents: {
    [key in WizardSchemas.FormFieldType.TSchema]?: (
      props: WizardSchemas.FormField.TSchema & {
        formType?: 'create' | 'update'
        id: string
        formValues: TFormValues
        key: string
      }
    ) => React.ReactNode
  } = useMemo(
    () => ({
      code: ({ id, formValues }) => (
        <CodeWAC
          readOnly={isReadOnly}
          code={formValues[id] as string}
          onChangeMethod={(code: string) =>
            onSetFormValues?.({
              [id]: code,
            })
          }
        />
      ),
      input: ({ id, formValues }) => (
        <Input
          width='100%'
          value={formValues[id] as string}
          readOnly={isReadOnly}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSetFormValues?.({
              [id]: e.target.value,
            })
          }
        />
      ),
      string: ({ id, formValues }) => {
        const currentValue = (formValues[id] as string) || ''
        const {
          isAtCharLimit,
          isAtWordLimit,
          characterHelperText,
          wordHelperText,
          helperColor,
          characterLimitType,
          wordLimitType,
          characterLimit,
          wordLimit,
          wordHelperColor,
          isNearLimit,
          isNearWordLimit,
          reachedCharLimit,
          reachedWordLimit,
        } = getCharacterAndWordLimitInfo(id, currentValue, form)

        const isHardLimitReached =
          (isAtCharLimit && characterLimitType === 'hard') ||
          (isAtWordLimit && wordLimitType === 'hard')

        const limitText = getLimitText(characterLimit, characterLimitType, wordLimit, wordLimitType)

        return (
          <div>
            {limitText && <div className='mb-1 text-sm italic text-gray-600'>{limitText}</div>}
            <TextArea
              width='100%'
              value={currentValue}
              readOnly={isReadOnly}
              autoresize={true}
              maxRows={4}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(e, onSetFormValues, id)
              }
              borderColor={isHardLimitReached ? '#D32F2F' : '#D0CFD2'}
            />
            <div className='mt-1 flex flex-col gap-1'>
              {characterHelperText && (
                <div
                  style={{
                    color: currentValue.length === 0 ? '#00000099' : helperColor,
                    fontSize: '0.75rem',
                  }}>
                  {`${characterHelperText} characters`}
                  {isNearLimit &&
                    !isAtCharLimit &&
                    getText(
                      characterLimit,
                      characterLimitType,
                      'character',
                      true,
                      reachedCharLimit
                    )}
                  {isAtCharLimit &&
                    getText(
                      characterLimit,
                      characterLimitType,
                      'character',
                      false,
                      reachedCharLimit
                    )}
                </div>
              )}
              {wordHelperText && (
                <div
                  style={{
                    color: currentValue.length === 0 ? '#00000099' : wordHelperColor,
                    fontSize: '0.75rem',
                  }}>
                  {`${wordHelperText} words`}
                  {isNearWordLimit &&
                    !isAtWordLimit &&
                    getText(wordLimit, wordLimitType, 'word', true, reachedWordLimit)}
                  {isAtWordLimit &&
                    getText(wordLimit, wordLimitType, 'word', false, reachedWordLimit)}
                </div>
              )}
            </div>
          </div>
        )
      },
      multilinestring: ({ id, formValues }) => (
        <MultiLineString
          currentValue={(formValues[id] ?? '') as string}
          onchange={(e) => handleChange(e, onSetFormValues, id)}
          id={id}
          isReadOnly={isReadOnly}
          maxRows={4}
          form={form}
        />
      ),
      textinputpreview: ({ id, formValues }) => (
        <TextInputPreview
          currentValue={(formValues[id] ?? '') as string}
          onchange={(e) => handleChange(e, onSetFormValues, id)}
          id={id}
          isReadOnly={isReadOnly}
          form={form}
        />
      ),
      scale: ({ id, formValues }) => {
        const currentValue = (formValues[id] as string) || ''
        const fields = form?.fields?.find((field) => field.baseVariableId === id)
        const scaleMin = fields?.scaleMin ?? '1'
        const scaleMax = fields?.scaleMax ?? '5'
        const scaleMinLabel = fields?.scaleMinLabel
        const scaleMaxLabel = fields?.scaleMaxLabel

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              maxWidth: `${71 * (Number(scaleMax) - Number(scaleMin) + 1)}px`,
              minWidth: `${35 * (Number(scaleMax) - Number(scaleMin) + 1)}px`,
            }}>
            <ToggleButtonGroup
              value={Number(currentValue)}
              exclusive
              sx={{
                border: '1px solid #0000003B',
                width: '100%',
              }}
              color='primary'
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  onSetFormValues?.({
                    [id]: newValue,
                  })
                }
              }}>
              {Array.from({ length: Number(scaleMax) - Number(scaleMin) + 1 }, (_, idx) => {
                const buttonValue = Number(scaleMin) + idx
                return (
                  <ToggleButton
                    key={buttonValue}
                    value={buttonValue}
                    sx={{
                      minWidth: '36px',
                      width: '72px',
                      maxHeight: '36px',
                      border: 'none',
                      backgroundColor:
                        Number(currentValue) === buttonValue ? '#EDE7F6' : 'transparent',
                      color: Number(currentValue) === buttonValue ? '#000000DE' : 'inherit',
                      '&:hover': {
                        backgroundColor: '#0000000A',
                      },
                    }}>
                    {buttonValue}
                  </ToggleButton>
                )
              })}
            </ToggleButtonGroup>
            {(scaleMinLabel || scaleMaxLabel) && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'end',
                  marginTop: '4px',
                  width: '100%',
                  gap: '10px',
                }}>
                {scaleMinLabel && (
                  <span style={{ fontSize: '0.85rem', color: '#00000099' }}>{scaleMinLabel}</span>
                )}
                {scaleMaxLabel && (
                  <span style={{ fontSize: '0.85rem', color: '#00000099', textAlign: 'right' }}>
                    {scaleMaxLabel}
                  </span>
                )}
              </Box>
            )}
          </Box>
        )
      },
      yesno: ({ id, formValues }) => {
        const currentValue = formValues[id] !== undefined ? Boolean(formValues[id]) : undefined

        return (
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <YesNoButton
              active={currentValue === true}
              onClick={() => {
                onSetFormValues?.({ [id]: true })
              }}>
              Yes
            </YesNoButton>
            <YesNoButton
              active={currentValue === false}
              onClick={() => {
                onSetFormValues?.({ [id]: false })
              }}>
              No
            </YesNoButton>
          </Box>
        )
      },
      richtext: ({ id, formValues }) => (
        <QuillEditor
          width='100%'
          value={(formValues[id] ?? '') as string}
          readOnly={isReadOnly}
          maxHeight='none'
          onChange={(v) =>
            onSetFormValues?.({
              [id]: v,
            })
          }
        />
      ),
      number: ({ id, formValues, baseVariableId }) => {
        const fields = form?.fields?.find((field) => field.baseVariableId === id) as
          | WizardSchemas.FormField.TSchema
          | undefined
        const _min = fields?.min
        const _max = fields?.max
        const safeBaseVariableId: string = baseVariableId ?? ''
        const error = baseVariableIdsInError.includes(safeBaseVariableId)
        return (
          <TextField
            size='small'
            fullWidth
            type='number'
            value={formValues[id]?.toString() as string}
            error={error}
            helperText={getNumberHelperText(_min, _max)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const val = e.target.valueAsNumber
              const sectionIds = baseVariableIdsInError.filter((e) => e !== safeBaseVariableId)
              if (!!(_min && val < _min) || !!(_max && val > _max)) {
                sectionIds.push(safeBaseVariableId)
              }
              setBaseVariableIdsInError(sectionIds)
              onSetFormValues?.({
                [id]: Number(val),
              })
            }}
            inputProps={{
              min: _min || '',
              max: _max || '',
            }}
          />
        )
      },
      url: ({ id, formValues }) => (
        <Input
          type='url'
          width='100%'
          value={formValues[id] as string}
          readOnly={isReadOnly}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSetFormValues?.({
              [id]: e.target.value,
            })
          }
        />
      ),
      dropdown: ({ options, placeholder, id, key, formValues, isConditionalInput }) =>
        isConditionalInput ? (
          <Radio.Group
            key={key}
            name={key}
            onChange={(value) => {
              onSetFormValues?.({
                [id]: value,
              })
            }}
            selected={(formValues[id] as string) ?? null}
            orientation='vertical'>
            {options?.map((option) => (
              <Radio label={option.value as string} value={option.key} allowDeselect />
            ))}
          </Radio.Group>
        ) : (
          <Dropdown
            key={key}
            width='100%'
            selectedKey={(formValues[id] as string) ?? null}
            options={options ?? []}
            disabled={isReadOnly}
            onChange={({ key }) =>
              onSetFormValues?.({
                [id]: key,
              })
            }
            placeholder={placeholder}
            name={key}
          />
        ),
      date: ({ id, formValues }) => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const initialValue = formValues[id]
          ? zonedTimeToUtc(formValues[id] as Date, timeZone)
          : null

        const handleDateChange = (date: Date | null) => {
          if (date && isNaN(date.getTime())) {
            showSnackbar({
              message: 'Please enter a valid date in the format MM/DD/YYYY.',
              variant: 'error',
            })
            return
          }

          const formattedDate = date ? format('yyyy-MM-dd', date) : null
          onSetFormValues?.({ [id]: formattedDate })
        }

        return (
          <DateTimePicker
            onChange={handleDateChange}
            value={initialValue}
            disabled={isReadOnly}
            fullWidth
            hideTime
          />
        )
      },
      datetime: ({ id, formValues }) => (
        <DateTimePicker
          onChange={(date) => {
            if (date !== null && isNaN(date.getTime())) {
              showSnackbar({
                message: 'Please enter a valid date time in the format MM/DD/YYYY HH:MM.',
                variant: 'error',
              })
              return
            }
            onSetFormValues?.({
              [id]: date?.toISOString(),
            })
          }}
          value={(formValues[id] as Date) ?? null}
          disabled={isReadOnly}
          fullWidth
          hideTime={false}
        />
      ),
      boolean: ({ id, formValues }) => (
        <NullSwitch
          isOn={(formValues[id] as boolean) ?? false}
          disabled={isReadOnly}
          onToggle={(value) =>
            onSetFormValues?.({
              [id]: value,
            })
          }
        />
      ),
      file: ({ id, uploadMeta }) => {
        const directoryName = uploadMeta?.directoryName || 'manticore'
        const bucketName = uploadMeta?.bucketName || GOOGLE_CLOUD_BUCKET_NAME

        return (
          <FileUploaderDropzone
            disabled={isReadOnly}
            acceptedFileTypes={uploadMeta?.acceptedFiles}
            directoryName={directoryName}
            bucketName={bucketName}
            saveUploadedFiles={(files: UploadedFile[]) => {
              // We get the array of all fileLinks in the format specified in the Form Configuration
              const fileLinks = files.map((file: UploadedFile) => {
                switch (uploadMeta?.linkFormat ?? 'url') {
                  case 'url':
                    return file.url
                  case 'file_path':
                    return file.filePath
                  default:
                    throw Error('This code path should not be reached')
                }
              })

              switch (uploadMeta?.outputFormat ?? 'string') {
                case 'string':
                  onSetFormValues?.({ [id]: fileLinks.join(', ') })
                  break

                case 'a_string':
                  onSetFormValues?.({ [id]: fileLinks as string[] })
                  break
              }
            }}
          />
        )
      },
      radio: ({ options, id, formValues, key }) => (
        <Box
          sx={{
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          <Radio.Group
            key={key}
            onChange={(selected) =>
              onSetFormValues?.({
                [id]: selected,
              })
            }
            name={key as string}
            selected={(formValues[id] as string) ?? null}
            orientation='vertical'>
            {options?.map((option) => (
              <Radio
                label={option.key}
                key={option.key}
                value={option.value as string}
                disabled={isReadOnly}
                allowDeselect
              />
            ))}
          </Radio.Group>
        </Box>
      ),
      multiselect: ({ options, id, key, formValues, label }) => (
        <MultiSelect
          maxHeight='200px'
          key={key}
          name={label as string}
          options={options ?? []}
          disabled={isReadOnly}
          selectedKeys={
            typeof formValues[id] === 'string' && formValues[id] !== ''
              ? (formValues[id] as string)?.split(',') ?? []
              : (formValues[id] as string[]) ?? []
          }
          onSelect={(selected) => {
            const baseVariableType =
              formBaseVariables?.find((variable) => variable.id === id)?.type ?? ''
            onSetFormValues?.({
              [id]: ['a_number', 'a_string', 'a_boolean', 'a_enum'].includes(baseVariableType)
                ? selected.map((s) => String(s.value)) ?? []
                : selected.map((s) => s.value).toString() ?? '',
            })
          }}
        />
      ),
      loomvideo: ({ id, formValues }) => {
        const onLoading = () => (
          <div className='mb-5 h-8 w-full'>
            <Button variant='secondary' size='md' disabled={true}>
              Loom is starting up
            </Button>
          </div>
        )

        const LoomButton = dynamic(() => import('@invisible/loom'), {
          ssr: false,
          loading: onLoading,
        })
        const url = formValues[id]

        return (
          <div className='flex flex-col gap-2'>
            {url ? <EmbedLoomVideo url={url as string} /> : null}
            <LoomButton
              loomVideos={loomVideos}
              setLoomVideos={(videos) => setLoomVideos(videos)}
              handleChange={(_name, videos) =>
                onSetFormValues?.({
                  [id]: videos[videos.length - 1],
                })
              }
              label={url ? 'Update the Loom Video' : 'Add a Loom Video'}
            />
          </div>
        )
      },
    }),
    [isReadOnly, onSetFormValues, form?.fields, baseVariableIdsInError, showSnackbar, loomVideos]
  )

  const renderField = (field: WizardSchemas.FormField.TSchema) => (
    <div className='mb-5 flex items-center' key={`${formReference}-${field.id}`}>
      {field.type === 'note' ? (
        <div className='flex items-center gap-1 text-gray-400'>
          <InfoFilledIcon />
          <span>{field.note}</span>
        </div>
      ) : (
        <div
          className={classNames(
            'w-full',
            field.type === 'boolean' ? 'flex flex-row-reverse items-center justify-end gap-3' : ''
          )}>
          <div className={field.type === 'boolean' ? '' : 'mb-2'}>
            {field.label}
            {field.required ? '*' : ''}
          </div>
          <div className='shrink-0'>
            {fieldComponents[field.type as keyof typeof fieldComponents]?.({
              ...field,
              formType: form?.type,
              formValues,
              id: field.baseVariableId as string,
              key: uuid(),
            })}
          </div>
        </div>
      )}
    </div>
  )

  const handleNextSection = (
    nextSection: string | undefined,
    shouldSubmit: boolean | undefined,
    section: WizardSchemas.FormSection.TSchema
  ) => {
    const formSections = form?.sections ?? []
    const currentSectionIndex = formSections.findIndex(({ id }) => id === section.id)
    const nextSectionId = nextSection ?? formSections?.[currentSectionIndex + 1]?.id
    const nextSectionIndex = formSections.findIndex(({ id }) => id === nextSectionId)

    setSkippedSections((prev) => prev.filter((s) => s.id !== nextSectionId))

    if (shouldSubmit) setFormSubmittedFromSection(nextSectionId)
    else setFormSubmittedFromSection(undefined)

    if (!nextSection) {
      setCompletedSections((prevState) => prevState + 1)
    } else {
      const sectionsToSkip = (form?.sections ?? []).filter(
        (_section, index) => index < nextSectionIndex && index > currentSectionIndex
      )

      onSetFormValues?.({
        ...(formWithoutNotes ?? [])
          .filter(({ sectionId }) => sectionsToSkip.some((section) => section.id === sectionId))
          .filter(
            ({ sectionId, baseVariableId }) =>
              !(formWithoutNotes ?? []).some(
                (field) => field.sectionId !== sectionId && field.baseVariableId === baseVariableId
              )
          )
          .map(({ baseVariableId }) => ({
            baseVariableId,
          }))
          .reduce(
            (a, v) => ({
              ...a,
              [v.baseVariableId as string]:
                initialFormValues.find(
                  (initialValue) => v.baseVariableId === initialValue.baseVariableId
                )?.value ?? null,
            }),
            {}
          ),
      })
      setCheckListValue((prev) => ({
        ...prev,
        ...(form?.checkLists ?? [])
          .filter(({ sectionId }) => sectionsToSkip.some((section) => section.id === sectionId))
          .map((checkList) => checkList.checkItems ?? [])
          .reduce((acc, val) => acc.concat(val), [])
          .reduce((a, v) => ({ ...a, [v.id as string]: null }), {}),
      }))
      setCompletedSections(nextSectionIndex)
      setSkippedSections((prev) => [...prev, ...sectionsToSkip])
    }
  }

  const hasMissingRequiredField = useMemo(
    () =>
      (form?.fields ?? [])
        .filter(
          (f) =>
            f.required &&
            !skippedSections.some((skippedSection) => f.sectionId === skippedSection.id) &&
            !(form?.sections ?? [])
              .filter((_section, index) => {
                if (formSubmittedFromSection)
                  return (
                    index >
                    (form?.sections ?? []).findIndex(
                      (section) => section.id === formSubmittedFromSection
                    )
                  )
                return false
              })
              .some((section) => section.id === f.sectionId)
        )
        .reduce((result, requiredField) => {
          if (
            requiredField.baseVariableId &&
            (isNil(formValues[requiredField.baseVariableId]) ||
              formValues[requiredField.baseVariableId] === '')
          )
            result = true
          return result
        }, false),
    [formValues, form?.fields, form?.sections, formSubmittedFromSection, skippedSections]
  )

  const hasMissingChecks = useMemo(
    () =>
      (form?.checkLists ?? [])
        .filter(
          (checkList) =>
            !skippedSections.some((skippedSection) => checkList.sectionId === skippedSection.id) &&
            !(form?.sections ?? [])
              .filter(
                (_section, index) =>
                  index >
                  (form?.sections ?? []).findIndex(
                    (section) => section.id === formSubmittedFromSection
                  )
              )
              .some((section) => section.id === checkList.sectionId)
        )
        .map((checkList) => checkList.checkItems ?? [])
        .reduce((acc, val) => acc.concat(val), [])
        .filter((checkItem) => checkItem.isRequired)
        .reduce((result, checkItem) => {
          if (!checkListValue?.[checkItem.id]) result = true
          return result
        }, false),
    [checkListValue, form?.checkLists, form?.sections, formSubmittedFromSection, skippedSections]
  )

  const hasExceededHardLimit = useMemo(
    () =>
      (form?.fields ?? []).some((field) => {
        if (field.characterLimit && field.characterLimitType === 'hard') {
          const value = formValues[field.baseVariableId as string] as string
          return value && value.length > field.characterLimit
        }
        if (field.wordLimit && field.wordLimitType === 'hard') {
          const value = formValues[field.baseVariableId as string] as string
          const wordCount = value ? value.trim().split(/\s+/).filter(Boolean).length : 0
          return wordCount > field.wordLimit
        }
        return false
      }),
    [formValues, form?.fields]
  )

  useEffect(() => {
    onCanSubmit?.(!hasMissingChecks && !hasMissingRequiredField && !hasExceededHardLimit)
  }, [hasMissingChecks, hasMissingRequiredField, hasExceededHardLimit, onCanSubmit])

  return (
    <div>
      {form?.fields?.filter((field) => isEmpty(field.sectionId)).map(renderField)}
      {size(form?.sections) > 1 ? (
        <Card className='!min-h-0'>
          <Progress
            color='#4DBC25'
            progress={showReview ? 1 : completedSections / (form?.sections?.length ?? 1)}
            width='100%'
          />
        </Card>
      ) : null}
      {!isEmpty(form?.sections) ? (
        !showReview ? (
          <>
            {form?.sections
              ?.filter((_section, idx) => idx < completedSections)
              .map((section) => (
                <Section
                  key={section.id}
                  section={section}
                  isCompleted
                  goToSection={(nextSection) =>
                    setCompletedSections(
                      (form?.sections ?? []).findIndex((s) => s.id === nextSection)
                    )
                  }
                  isSkipped={skippedSections.some(({ id }) => section.id === id) ?? false}
                />
              ))}
            {form?.sections
              ?.filter((_section, idx) => idx === completedSections)
              .map((section) => (
                <Section
                  key={section.id}
                  section={section}
                  isOnlySection={size(form?.sections) === 1}
                  isCompleted={false}
                  checkLists={form?.checkLists?.filter(
                    (checkList) => checkList.sectionId === section.id
                  )}
                  checkListValue={checkListValue}
                  fields={form?.fields?.filter((field) => field.sectionId === section.id)}
                  formValues={formValues}
                  formSubmittedFromSection={formSubmittedFromSection}
                  baseVariableIdsInError={baseVariableIdsInError}
                  onNextSection={(nextSection, shouldSubmit) =>
                    handleNextSection(nextSection, shouldSubmit, section)
                  }
                  hasNextSection={completedSections + 1 < (form?.sections ?? []).length}
                  onReview={() => {
                    onShowReview?.(true)
                    setShowReview(true)
                  }}>
                  {form?.fields
                    ?.filter((field) => field.sectionId === section.id)
                    .map((field) => renderField(field))}
                  {form?.checkLists
                    ?.filter((checkList) => checkList.sectionId === section.id)
                    .map((checkList) => (
                      <CheckList
                        key={checkList.id}
                        checkList={checkList}
                        checkListStatus={checkListValue}
                        setCheckListValue={setCheckListValue}
                      />
                    ))}
                </Section>
              ))}
          </>
        ) : (
          <div>
            <Preview
              form={form ?? { sections: [], fields: [], checkLists: [] }}
              checkListValue={checkListValue}
              formSubmittedFromSection={formSubmittedFromSection}
              skippedSections={skippedSections}
              formValues={formValues}
            />
          </div>
        )
      ) : null}
    </div>
  )
}

export default ConditionalForm
