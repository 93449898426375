import { sendErrorToSentry } from '@invisible/errors'
import { logger } from '@invisible/logger/client'
import { useQuery } from '@invisible/trpc/client'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import axios from 'axios'
import { differenceInSeconds, startOfDay } from 'date-fns'
import { compact, isEmpty } from 'lodash/fp'
import Papa from 'papaparse'
import { ChangeEvent } from 'react'

export const safeJSONParse = (x: string) => {
  try {
    return JSON.parse(x)
  } catch (e) {
    sendErrorToSentry(e)
    return undefined
  }
}

export const processUploadedFile = ({
  e,
  setFormValues,
  label,
}: {
  e: ChangeEvent<HTMLInputElement>
  setFormValues: (p: any) => void
  label: string
}) => {
  const files = (e.target as HTMLInputElement).files
  if (isEmpty(files)) return

  const file = files?.[0] as File

  if (file.type === 'text/csv') {
    return Papa.parse(file, {
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: (json: Papa.ParseResult<any>) => {
        setFormValues((prev: any) => ({
          ...prev,
          [label]: json.data,
        }))
      },
    })
  }

  if (file.name.endsWith('.jsonl')) {
    const reader = new FileReader()
    reader.onload = (event) => {
      const content = event.target?.result as string
      const lines = content.trim().split('\n')
      const jsonArray = compact(lines.map((line) => safeJSONParse(line)))
      setFormValues((prev: Record<string, unknown>) => ({
        ...prev,
        [label]: jsonArray,
      }))
    }
    reader.readAsText(file)
    return reader
  }
}

export function findBaseVariableId(
  targetForm: WizardSchemas.WACConfig.TSchema['form'],
  label: string
) {
  return targetForm?.fields?.find((f) => f.label === label)?.baseVariableId ?? ''
}

export function getBaseRunVariableFromQueryData<T>(
  data?: ReturnType<typeof useQuery>['data'],
  targetId?: string
): T {
  if (!targetId || !data) {
    return null as unknown as T
  }
  return (
    (data as Array<any>).find(
      ({ baseVariableId }: { baseVariableId: string }) => baseVariableId === targetId
    )?.value ?? (null as unknown as T)
  )
}

export const blobToBase64 = (blob: Blob) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => {
      logger.error('blobToBase64 error', { error })
      resolve(undefined)
    }
  })

export const handleSetUserLifecycleStage = async ({
  stepId,
  userId,
  status,
}: {
  stepId: string
  userId: string
  status?: string
}) => {
  const onboardingSteps = {
    'a39f26e4-8eb4-49d4-a89b-efcafa12d3a1': 'IDENTITY_VERIFICATION',
    '03a23113-bba6-4190-8aa4-ad1e37e410af': 'VERIFICATION_OF_IDENTITY',
    '05ef2382-f40b-43a6-8b9e-be3a6a45ad56': 'ONBOARDING_TRAINING',
    '978fe39a-c25a-4ba3-a97c-85c38ccc5ff8': 'ONBOARDING_TRAINING',
    '3c5d1518-70d0-454b-be14-24a51db00277': 'ONBOARDING_TRAINING',
  } as const

  if (Object.keys(onboardingSteps).includes(stepId)) {
    await axios.post('/api/update-user-lifecycle-stage', {
      userId,
      stageData: {
        name: onboardingSteps[stepId as keyof typeof onboardingSteps],
        status: status ?? 'RUNNING',
      },
    })
  }
}

export const handleStepRunUpdatesOnLooopResourceStatus = async ({
  id,
  baseRunId,
  stepId,
  userId,
}: {
  id: string
  baseRunId: string
  stepId: string
  userId: string
}) => {
  const steps = {
    '05ef2382-f40b-43a6-8b9e-be3a6a45ad56': 'INVISIBLE_ONBOARDING',
    '978fe39a-c25a-4ba3-a97c-85c38ccc5ff8': 'INVISIBLE_ONBOARDING',
    '3c5d1518-70d0-454b-be14-24a51db00277': 'INVISIBLE_ONBOARDING',
    '337bb9d6-8d91-4e17-b52f-daf0c88e54ca': 'INVISIBLE_TRAINING',
  } as const

  if (Object.keys(steps).includes(stepId)) {
    await axios.post('/api/looop/looop-resource-steprun-completion', {
      id,
      baseRunId,
      stepId,
      userId,
    })
  }
}

export const getFormattedTimeString = (timeInSeconds: number, excludeHours = false): string => {
  const hours = Math.floor(timeInSeconds / 3600).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  const minutes = Math.floor((timeInSeconds % 3600) / 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  const seconds = Math.floor(timeInSeconds % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })

  if (excludeHours) {
    return `${minutes}:${seconds}`
  }

  return `${hours}:${minutes}:${seconds}`
}

export const getSecondsInCurrentDay = (): number => {
  const now = new Date()
  return differenceInSeconds(now, startOfDay(now))
}

export const getLastSavedText = (
  lastSaved: Date | null,
  diffInSeconds: number | null
): string[] => {
  if (!lastSaved || !diffInSeconds) return ['Saved just now']

  const timeSaved = lastSaved.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })

  const formats: [number, () => string[]][] = [
    [
      60 * 60 * 24 * 2,
      () => [
        'Saved on ',
        `${lastSaved.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })} at ${timeSaved}`,
      ],
    ],
    [getSecondsInCurrentDay(), () => ['Saved yesterday at ', `${timeSaved}`]],
    [60 * 60, () => ['Saved today at ', `${timeSaved}`]],
    [
      60,
      () => {
        const diffInMinutes = Math.floor(diffInSeconds / 60)
        return ['Saved ', `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`]
      },
    ],
    [10, () => ['Saved a few seconds ago']],
  ]

  for (const [threshold, format] of formats) {
    if (diffInSeconds >= threshold) {
      return format()
    }
  }

  return ['Saved just now']
}
