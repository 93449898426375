import 'katex/dist/katex.min.css'

import { BlockMath, InlineMath } from 'react-katex'
import sanitize from 'sanitize-html'

import { TTextDirection } from './types'

/**
 * Regular expressions for detecting LaTeX math delimiters
 */
const INLINE_MATH_REGEX = /(?<!\\)(?:\$(?!\$)[\s\S]*?(?<!\\)\$(?!\$)|\\\([\s\S]*?\\\))/
const BLOCK_MATH_REGEX = /(?<!\\)(?:\$\$[\s\S]*?(?<!\\)\$\$|\\\[[\s\S]*?\\\])/
const BLOCK_MATH_START = /^(\$\$|\\\[)/
const BLOCK_MATH_END = /(\$\$|\\\])$/
const INLINE_MATH_START = /^(\$|\\\()/
const INLINE_MATH_END = /(\$|\\\))$/

/**
 * Props for the KatexComponent
 * @interface IKatexComponentProps
 * @property {string} math - The mathematical expression to render
 * @property {boolean} [block] - Whether to render as block (display) math or inline math
 */
interface IKatexComponentProps {
  math: string
  block?: boolean
}

/**
 * Renders a mathematical expression using KaTeX
 * @param {IKatexComponentProps} props - Component props
 */
export const KatexComponent = ({ math, block = false }: IKatexComponentProps) =>
  block ? <BlockMath math={math} /> : <InlineMath math={math} />

/**
 * Splits content into math and non-math parts based on LaTeX delimiters
 * @param {string} content - Text content containing math expressions
 * @returns {string[]} Array of content parts
 */
export function getMathParts(content: string) {
  const parts = content.split(
    new RegExp(`(${BLOCK_MATH_REGEX.source}|${INLINE_MATH_REGEX.source})`)
  )
  return parts.map((part) => part || ' ')
}

/**
 * Props for the KatexRenderer component
 * @interface IKatexProps
 * @property {string} content - Text content containing math expressions
 * @property {string} [className] - CSS class name for text spans
 * @property {TTextDirection} [textDirection] - Text direction (rtl/ltr/auto)
 */
export interface IKatexProps {
  content: string
  className?: string
  textDirection?: TTextDirection
}

/**
 * Renders text content with mathematical expressions using KaTeX
 * Supports both inline and block math with LaTeX delimiters
 * @param {IKatexProps} props - Component props
 */
const KatexRenderer = ({ content, className, textDirection }: IKatexProps) => {
  /**
   * Processes the content to render math expressions using KaTeX
   * @param {string} content - Text content containing math expressions
   */
  const processContent = (content: string) => {
    const parts = getMathParts(content)

    const sanitizeOptions = {
      allowedTags: [...sanitize.defaults.allowedTags, 'span', 'math'],
      allowedClasses: {
        span: ['katex*', 'math*'],
      },
    }
    return parts.map((part, index) => {
      if (part.match(BLOCK_MATH_START) && part.match(BLOCK_MATH_END)) {
        // Render block math
        const math = part.replace(BLOCK_MATH_START, '').replace(BLOCK_MATH_END, '')
        return <KatexComponent key={index} math={math} block={true} />
      } else if (part.match(INLINE_MATH_START) && part.match(INLINE_MATH_END)) {
        // Render inline math
        const math = part.replace(INLINE_MATH_START, '').replace(INLINE_MATH_END, '')
        return <KatexComponent key={index} math={math} block={false} />
      } else {
        // Render non-math text
        return (
          <span
            key={index}
            className={className}
            dir={textDirection}
            dangerouslySetInnerHTML={{ __html: sanitize(part, sanitizeOptions) }}
          />
        )
      }
    })
  }

  return <>{processContent(content)}</>
}

export default KatexRenderer
export {
  BLOCK_MATH_END,
  BLOCK_MATH_REGEX,
  BLOCK_MATH_START,
  INLINE_MATH_END,
  INLINE_MATH_REGEX,
  INLINE_MATH_START,
}
