import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import {
  gridFilteredTopLevelRowCountSelector,
  GridFooterContainer,
  GridPagination,
  GridRowCount,
  GridSelectedRowCount,
  GridSlotsComponentsProps,
  gridTopLevelRowCountSelector,
  selectedGridRowsCountSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid'
import * as React from 'react'

export type TCustomFooterProps = GridSlotsComponentsProps['footer'] & {
  isAddingNewRow?: boolean
  addNewRow?: () => void
}

export function CustomFooter(props: TCustomFooterProps) {
  const rootProps = useGridRootProps()
  const apiRef = useGridApiContext()
  const selectedRowCount = useGridSelector(apiRef, selectedGridRowsCountSelector)
  const totalTopLevelRowCount = useGridSelector(apiRef, gridTopLevelRowCountSelector)
  const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector)
  const SelectedRowCountElement =
    !rootProps.hideFooterSelectedRowCount && selectedRowCount > 0 ? (
      <GridSelectedRowCount selectedRowCount={selectedRowCount} />
    ) : null
  const RowCountElement =
    !rootProps.hideFooterRowCount && !rootProps.pagination ? (
      <GridRowCount rowCount={totalTopLevelRowCount} visibleRowCount={visibleTopLevelRowCount} />
    ) : null
  const PaginationElement = !rootProps.hideFooterPagination ? <GridPagination /> : null

  return (
    <GridFooterContainer sx={{ display: 'flex' }}>
      {props?.addNewRow ? (
        <IconButton
          disabled={props?.isAddingNewRow}
          aria-label='add'
          title='Add new row'
          onClick={() => props?.addNewRow?.()}>
          <AddIcon />
        </IconButton>
      ) : (
        <div />
      )}
      <div className='flex  justify-end'>
        {SelectedRowCountElement}
        {RowCountElement}
        {PaginationElement}
      </div>
    </GridFooterContainer>
  )
}
