import { ConditionalForm } from '@invisible/common/components/conditional-form'
import { useBaseVariableByBaseId } from '@invisible/common/components/process-base'
import {
  TBaseRunVariable as TBaseRunVariableFromWizardState,
  useWizardState,
} from '@invisible/common/components/providers/active-wizard-provider'
import { sendErrorToSentry } from '@invisible/errors'
import { logger } from '@invisible/logger/client'
import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { isEmpty, keys, map, size } from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import { useBaseRunVariablesWizardUpdate } from '../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import { useSelectedEmbedLink } from '../providers/SelectedEmbedLinkProvider'

type TBaseRunVariables = NonNullable<inferQueryOutput<'baseRunVariable.findManyByBaseRunId'>>
type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

type IProps = {
  form: WizardSchemas.Form.TSchema
  wacName: string
  baseRunId: string
  baseRunVariables: TBaseRunVariables
  stepRun: TStepRun
}

// eslint-disable-next-line react/display-name
const MetadataForm = ({
  form,
  baseRunId,
  stepRun,
  wacName,
  baseRunVariables: initialBaseRunVariables,
}: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const reactQueryClient = useQueryClient()

  const reactQueryContext = useContext()
  const { addToast } = useToasts()
  const { state: embedState } = useSelectedEmbedLink()
  const { state: wizardState, dispatch } = useWizardState()
  const [showReview, setShowReview] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  let resetConditionalFormState: () => void

  const formWithoutNotes = form?.fields?.filter((field) => field.type !== 'note')
  const [formValues, setFormValues] = useState<
    Record<string, string | number | boolean | Date | null | undefined | string[]>
  >({})

  const initialWizardBRVs = useMemo(
    () =>
      (wizardState.wizardInitialBRVs as TBaseRunVariableFromWizardState[]).filter(
        (brv) => brv.baseRunId === baseRunId
      ),
    [wizardState.wizardInitialBRVs]
  )

  /**
   * Get base variables by baseId
   */
  const { data: baseVariables } = useBaseVariableByBaseId({
    baseId: form?.baseId ?? '',
    enabled: Boolean(form?.baseId),
  })

  // Set Metadata initial base run variables in the wizard state on first render
  useEffect(() => {
    if (initialBaseRunVariables.length === 0) {
      return
    }

    const initBrvIds = wizardState.wizardInitialBRVs.map((brv) => brv.id)
    if (!initBrvIds.includes(initialBaseRunVariables[0].id)) {
      dispatch({
        type: 'setInitialBrvs',
        wizardInitialBRVs: [
          ...(wizardState.wizardInitialBRVs as TBaseRunVariableFromWizardState[]).filter(
            (brv) => !initialBaseRunVariables.map((b) => b.id).includes(brv.id)
          ),
          ...(initialBaseRunVariables as TBaseRunVariableFromWizardState[]),
        ],
      })
    }
  }, [initialBaseRunVariables])

  useEffect(() => {
    setFormValues(
      formWithoutNotes?.reduce(
        (acc, field) => ({
          ...acc,
          [field.baseVariableId as string]:
            initialWizardBRVs?.find((v) => v.baseVariableId === field.baseVariableId)?.value ??
            (['date', 'dropdown', 'boolean'].includes(field.type) ? null : ''),
        }),
        {}
      ) ?? {}
    )
    resetConditionalFormState()
  }, [initialWizardBRVs])

  // This ensures that if the variables are not already filled in when the Wizard opens, then it blocks the Wizard Submit button.
  useEffect(() => {
    if (form && form?.type === 'update' && form.fields) {
      form.fields.forEach((field) => {
        if (field.required) {
          const fieldValue = initialWizardBRVs?.find(
            (v) => v.baseVariableId === field.baseVariableId
          )?.value

          if (fieldValue === null || fieldValue === '') {
            dispatch({
              type: 'setReadyForSubmit',
              key: `${wacName}-MetadataForm`,
              value: false,
            })
            return
          }
        }
      })
    }
  }, [initialWizardBRVs])

  // Remove Metadata initial base run variables in the wizard state on unmount
  useEffect(
    () => () => {
      dispatch({
        type: 'setInitialBrvs',
        wizardInitialBRVs: (
          wizardState.wizardInitialBRVs as TBaseRunVariableFromWizardState[]
        ).filter((brv) => brv.baseRunId !== baseRunId),
      })
    },
    []
  )

  const { mutateAsync: updateManyBrvs } = useBaseRunVariablesWizardUpdate({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('baseRun.findManyByParents')
      reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
    },
  })

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      const formVariables = map((baseVariableId: string) => {
        const value = formValues[baseVariableId]
        return {
          baseVariableId,
          baseRunId: baseRunId,
          value:
            typeof value === 'boolean' || typeof value === 'number' || value === null
              ? value
              : String(value),
        }
      })(keys(formValues))

      const baseRunVariables = [
        ...formVariables,
        ...(form?.embedUrlField
          ? [
              {
                baseRunId: baseRunId,
                baseVariableId: form.embedUrlField.baseVariableId,
                value: String(embedState?.currentEmbedLink?.[form.embedId as string]),
              },
            ]
          : []),
      ]

      logger.info('SUBMITTING METADATAFORM WIZARD ATOMIC COMPONENT', {
        processId: wizardState.processId,
        baseRunId: baseRunVariables,
        stepRunId: stepRun.id,
        baseRunVariables: baseRunVariables,
        file: __filename,
      })

      await updateManyBrvs({ stepRunId: stepRun.id, data: baseRunVariables })

      // At this point, it means all required fields were filled in & saved. So we can set the Wizard to ready for submit.
      dispatch({
        type: 'setReadyForSubmit',
        key: `${wacName}-MetadataForm`,
        value: true,
      })
    } catch (error) {
      sendErrorToSentry(error)
      addToast(`Something went wrong: ${(error as Error).message}`, {
        appearance: 'error',
      })
    }
    setIsSubmitting(false)
  }

  return (
    <div className='relative box-border flex h-full flex-col overflow-auto rounded-lg border border-gray-400 bg-white p-2.5 shadow-md'>
      <ConditionalForm
        initialFormValues={initialWizardBRVs}
        onShowReview={(state) => setShowReview(state)}
        onCanSubmit={(state) => setCanSubmit(state)}
        setResetState={(func) => (resetConditionalFormState = func)}
        form={form}
        formValues={formValues}
        formBaseVariables={baseVariables ?? []}
        baseRunId={baseRunId}
        stepRunId={stepRun.id}
        processId={wizardState.processId ?? ''}
        onSetFormValues={(value) => {
          setFormValues((prev) => ({ ...prev, ...value }))
        }}
      />

      <div className='mt-2 flex justify-end'>
        {isEmpty(form?.sections) ? (
          <Button loading={isSubmitting} variant='primary' size='lg' onClick={handleSubmit}>
            {form?.submitButtonText ?? 'Save'}
          </Button>
        ) : showReview || size(form?.sections) === 1 ? (
          <Button
            loading={isSubmitting}
            variant='primary'
            size='md'
            disabled={!canSubmit}
            onClick={handleSubmit}>
            {form?.submitButtonText ?? 'Save'}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export { MetadataForm }
