import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { createContext, ReactNode, useCallback, useState } from 'react'

type TVariant = 'success' | 'error' | 'info'

type SnackbarData = {
  title: string
  description: string
  variant: TVariant
  autoHide: boolean
  actions?: ReactNode
}

type SnackbarContextType = {
  showSnackbar: (args: SnackbarData) => void
}

type AppearanceStyle = {
  background: string
  icon?: React.ReactElement
  title: string
  fillColor: string
  border: string
}

export const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: () => {
    console.warn('showSnackbar was called without a SnackbarProvider')
  },
})

const appearanceStyles: Record<TVariant, AppearanceStyle> = {
  success: {
    background: '#E8F5E9',
    icon: <CheckCircleOutlineIcon style={{ color: '#2E7D32', width: 20, height: 20 }} />,
    title: 'Nice!',
    fillColor: '#2E7D32',
    border: '1px solid #388E3C',
  },
  info: {
    background: '#E3F2FD',
    title: 'Heads Up!',
    fillColor: '#0288D1',
    border: '1px solid #0288D1',
  },
  error: {
    background: '#FFEBEE',
    icon: <ErrorOutlineIcon style={{ color: '#D32F2F', width: 20, height: 20 }} />,
    title: 'Oops!',
    fillColor: '#D32F2F',
    border: '1px solid #D32F2F',
  },
}

const ProgressBar = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 10000,
  width: 4,
  borderRadius: theme.shape.borderRadius,
}))

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarData, setSnackbarData] = useState<SnackbarData>({
    title: '',
    description: '',
    variant: 'success',
    autoHide: true,
    actions: null,
  })

  const showSnackbar = useCallback(
    ({
      title,
      description,
      variant = 'success',
      autoHide = true,
      actions = null,
    }: SnackbarData) => {
      setSnackbarData({ title, description, variant, autoHide, actions })
      setSnackbarOpen(true)
    },
    []
  )

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway' && !snackbarData.autoHide) return
    setSnackbarOpen(false)
  }

  const { title, description, variant, autoHide, actions } = snackbarData
  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={autoHide ? 4000 : null}
        onClose={(_, reason) => handleClose(reason)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
          <Stack
            direction='row'
            sx={{
              position: 'relative',
              marginBottom: 1,
              padding: '9px 16px',
              boxShadow: 1,
              overflow: 'hidden',
              borderRadius: '0.375rem',
              minWidth: 350,
              backgroundColor: appearanceStyles[variant].background,
              border: appearanceStyles[variant].border,
            }}>
            <Stack direction='row' spacing={1.5} alignItems='flex-start' flexGrow={1}>
              {appearanceStyles[variant].icon && (
                <Box display='flex' alignItems='center' justifyContent='center' pt={0.75}>
                  {appearanceStyles[variant].icon}
                </Box>
              )}
              <Stack spacing={0.5} flexGrow={1} py={1}>
                <Typography fontSize={16} fontWeight={500}>
                  {title}
                </Typography>
                <Typography fontSize={14} fontWeight={400}>
                  {description}
                </Typography>
              </Stack>
              {actions && (
                <Stack py={0.5} height='100%' direction='column' spacing={1}>
                  {actions}
                </Stack>
              )}
            </Stack>
            <IconButton
              onClick={() => setSnackbarOpen(false)}
              sx={{
                width: 'fit-content',
                height: 'fit-content',
                padding: '5px',
                margin: '4px 0 0 12px',
              }}>
              <CloseIcon style={{ width: 20, height: 20 }} />
            </IconButton>
            {autoHide && (
              <ProgressBar
                sx={{
                  width: 6,
                  height: 0,
                  animation: 'shrink linear 4000ms',
                  backgroundColor: appearanceStyles[variant].fillColor,
                }}
              />
            )}
          </Stack>
        </Slide>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
