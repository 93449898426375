export const MUI_OPERATOR_TO_GQL_OPERATOR: Record<string, string> = {
  contains: 'iContains',
  equals: 'exact',
  not: 'nExact',
  startsWith: 'iStartsWith',
  endsWith: 'iEndsWith',
  isEmpty: 'isNull',
  isNotEmpty: 'nIsNull',
  isAnyOf: 'inList',
  after: 'gt',
  onOrAfter: 'gte',
  before: 'lt',
  onOrBefore: 'lte',
  is: 'exact',
  has: 'inList',
}
