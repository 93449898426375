import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box } from '@mui/material'
import Tab from '@mui/material/Tab'
import TextRender from 'libs/common/components/process-base/src/lib/wizardAtomicComponents/common/TextRender'
import { ChangeEvent, useState } from 'react'

import MultiLineString from './MultiLineString'

interface ITextInputPreviewProps {
  currentValue: string
  onchange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  id: string
  isReadOnly: boolean
  form?: WizardSchemas.Form.TSchema
  maxRows?: number
}

const TextInputPreview = ({
  currentValue,
  onchange,
  id,
  isReadOnly,
  form,
  maxRows,
}: ITextInputPreviewProps) => {
  const [tab, setTab] = useState('1')
  const fields = form?.fields?.find((field) => field.baseVariableId === id)
  const textRenderMode = fields?.style?.textRenderMode as
    | 'plaintext'
    | 'markdown'
    | 'latex'
    | 'markdown-latex'
  const textDirection = fields?.style?.textDirection as 'auto' | 'ltr' | 'rtl'

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  return (
    <TabContext value={tab}>
      <Box>
        <TabList onChange={handleTabChange} sx={{ marginBottom: '-2px' }}>
          <Tab label='Original' value='1' className='px-2' />
          <Tab label='Preview' value='2' className='px-2' />
        </TabList>
      </Box>
      <TabPanel value='1' sx={{ padding: '0px' }}>
        <MultiLineString
          currentValue={currentValue}
          onchange={onchange}
          id={id}
          isReadOnly={isReadOnly}
          form={form}
          maxRows={maxRows}
        />
      </TabPanel>
      <TabPanel value='2' sx={{ padding: '0px' }}>
        <div className='rounded-sm border border-solid border-[#D0CFD2] px-[12px]'>
          <TextRender
            text={currentValue}
            renderMode={textRenderMode}
            markdownProps={{
              dir: textDirection,
            }}
            latexProps={{
              textDirection,
            }}
            plaintextProps={{
              dir: textDirection,
              className: 'leading-[125%]',
            }}
            markdownWithLatexProps={{
              dir: textDirection,
            }}
          />
        </div>
      </TabPanel>
    </TabContext>
  )
}

export default TextInputPreview
