import { CheckCircleOutlineIcon, CloseCircleIcon, InfoFilledIcon } from '@invisible/ui/icons'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'

import { EmbedLoomVideo } from './EmbedLoomVideo'

type TCheckListValue = Record<string, boolean>

const Preview = ({
  form,
  formValues,
  checkListValue,
  skippedSections,
  formSubmittedFromSection,
}: {
  form: WizardSchemas.Form.TSchema
  formValues: Record<string, string | number | boolean | Date | string[] | null | undefined>
  checkListValue: TCheckListValue
  skippedSections: WizardSchemas.FormSection.TSchema[]
  formSubmittedFromSection?: string
}) => {
  if (!form.sections) return null

  const sectionsToSkip = [...skippedSections]

  if (formSubmittedFromSection) {
    sectionsToSkip.push(
      ...form.sections.filter(
        (_section, index) =>
          index >
          (form.sections ?? []).findIndex((section) => section.id === formSubmittedFromSection)
      )
    )
  }

  return (
    <>
      {form.sections
        .filter(
          (section) => !sectionsToSkip.some((skippedSection) => skippedSection.id === section.id)
        )
        .map((section) => (
          <div className='border-r-6 border-theme-weak-2 mb-2 mt-2 box-border cursor-pointer rounded-md border p-2 hover:border-solid'>
            <h2 className='whitespace-pre-line'>{section.label}</h2>
            {(form?.fields ?? [])
              .filter((field) => field.sectionId === section.id)
              .map((field) => (
                <div className='mb-4'>
                  {field.type === 'note' ? (
                    <div className='flex items-center gap-1 text-gray-400'>
                      <InfoFilledIcon /> <span>{field.note}</span>
                    </div>
                  ) : field.type === 'loomvideo' ? (
                    <div>
                      <span className='text-bold'>{field.label}</span>:
                      {
                        <EmbedLoomVideo
                          url={(formValues[field?.baseVariableId ?? ''] as string) ?? ''}
                        />
                      }
                    </div>
                  ) : (
                    <div className='whitespace-pre-line'>
                      <span className='text-bold'>{field.label}</span>:
                      {formValues[field?.baseVariableId ?? '']?.toString()}
                    </div>
                  )}
                </div>
              ))}
            {form?.checkLists
              ?.filter((val) => val.sectionId === section.id)
              .map((checkList) => (
                <div key={checkList.id} className='mb-5'>
                  <b>{checkList.label}</b>
                  <div className='mt-2'>
                    {checkList.checkItems?.map((checkItem) => (
                      <div className='mt-2 flex items-center gap-2' key={checkItem.id}>
                        {checkListValue[checkItem.id] ? (
                          <CheckCircleOutlineIcon width={14} height={14} />
                        ) : (
                          <CloseCircleIcon width={14} height={14} />
                        )}
                        <span>{checkItem.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        ))}
    </>
  )
}

export { Preview }
