import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { datadogRum } from '@datadog/browser-rum'
import {
  AddAvailabilityModal,
  useProcessById,
  useStepRunSnooze,
  useStepRunUnsnooze,
} from '@invisible/common/components/process-base'
import {
  TBaseRunVariable,
  useWizardState,
} from '@invisible/common/components/providers/active-wizard-provider'
import {
  Backdrop,
  RGLContainer,
  Topbar,
  WizardContainer,
  WizardTitle,
} from '@invisible/common/components/wizard/wizard-container'
import { getUUIDFromNamespace } from '@invisible/common/helpers'
import {
  IStepRunEventTypeEnum,
  toGlobalId,
  useBaseRunVariablesUpdateMutation,
} from '@invisible/concorde/gql-client'
import { useLocalStore } from '@invisible/hooks/use-local-store'
import { useLoggedInUser } from '@invisible/hooks/use-logged-in-user'
import { logger } from '@invisible/logger/client'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { useDisableRefetchOnFocus } from '@invisible/ui/hooks/use-disable-refetch-on-focus'
import {
  CloseIcon,
  DragHandleVerticalIcon,
  StarShineIcon,
  TaskTrainingIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { palette, theme } from '@invisible/ui/mui-theme-v2'
import { useToasts } from '@invisible/ui/toasts'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { ManualStepMeta, Wizard as WizardSchemas } from '@invisible/ultron/zod'
import TimerSharpIcon from '@mui/icons-material/TimerSharp'
import LoadingButton from '@mui/lab/LoadingButton'
import MuiBackdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { isWithinInterval } from 'date-fns'
import { addDays, endOfDay, startOfDay } from 'date-fns/fp'
import { LOCAL_STORAGE_KEYS } from 'libs/ui/src/lib/hooks/use-user-activity/constants'
import { isEqual } from 'lodash/fp'
import {
  forwardRef,
  memo,
  useContext as useReactContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactGridLayout from 'react-grid-layout'
import { useQueryClient } from 'react-query'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

import { TManualStep } from './common/types'
import { CustomWizardAtomicComponents } from './customWizardAtomicComponents'
import { usePasteEvent } from './hooks/usePasteEvent'
import { TOKEN_NAMES, useStepRunEventLogger } from './hooks/useStepRunEventLogger'
import { SnackbarContext, SnackbarProvider } from './providers/WizardSnackbarProvider'
import { ResetLayoutModal } from './ResetLayoutModal'
import { WizardAtomicComponents } from './wizardAtomicComponents'
import WizardConfirmationDialogBtn from './WizardConfirmationDialogBtn'
import WizardHeaderButton from './WizardHeaderButton'
import { WizardLastSaved } from './WizardLastSaved'
import { WizardTimer } from './WizardTimer'

type TFindAssignedToMeData = inferQueryOutput<'stepRun.findAssignedToMe'>

const allWACs = { ...WizardAtomicComponents, ...CustomWizardAtomicComponents }

const darkColorBtnStyles = {
  backgroundColor: palette.black.main,
  '&:hover': {
    backgroundColor: palette.black.main,
  },
}

const getSavedWacLayoutKey = (currentStepId = '') => `WAC Layout ${currentStepId}`

const WizardContent = () => {
  const { addToast } = useToasts()
  const { showSnackbar } = useReactContext(SnackbarContext)
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()

  const { state, dispatch } = useWizardState()
  const { stepRun, processId, isWizardOpen, baseRun, wizardData, autosaveConfig } = state
  const baseRunRef = useRef(baseRun)
  baseRunRef.current = baseRun

  const [currentUser] = useLoggedInUser()

  const [isQaConsentModalOpen, setIsQaConsentModalOpen] = useState(false)
  const [isOffline, setIsOffline] = useState(false)
  const [isAutoSnoozed, seIsAutoSnoozed] = useState(false)
  const [isSnoozeInfoModalOpen, setIsSnoozeInfoModalOpen] = useState(false)
  const [isDismissed, setIsDismissed] = useState(false)
  const [openAddAvailabilityModal, setOpenAddAvailabilityModal] = useState(false)
  const [wizardConfig, setWizardConfig] = useState<WizardSchemas.WizardConfig.TSchema | null>(null)
  const [isResetLayoutModalOpen, setIsResetLayoutModalOpen] = useState(false)
  const [savedWacLayout, setSavedWacLayout] = useLocalStore<ReactGridLayout.Layout[]>(
    getSavedWacLayoutKey(state?.stepRun?.stepId),
    []
  )
  const [lastSaved, setLastSaved] = useState<Date | null>(null)

  const isSnoozed = stepRun?.status === 'snoozed' || false

  const isWacSubmitting = Object.values(state.isWacSubmitting).some((value) => value === true)
  const { value: enableIdleSnoozeDesigns } = useGate('enable-idle-snooze-designs')
  const { value: enableAutoSaving } = useGate('enable-wizard-auto-saving')

  const { maybeLogStepRunEvent } = useStepRunEventLogger()

  const { mutateAsync: updateBaseRunVariable, isLoading: isUpdatingBaseRunVariable } =
    useBaseRunVariablesUpdateMutation({
      onSuccess: () => {
        reactQueryContext.invalidateQueries('baseRunVariable.findWizardDataForStepRun')
        reactQueryClient.invalidateQueries('get-base-runs')
        setLastSaved(new Date())
      },
    })

  const { data: schedules, isLoading: isAvailabilityLoading } = useQuery([
    'agentSchedule.findMany',
    {
      userEmail: currentUser?.email ?? '',
      startDate: startOfDay(addDays(-1, new Date())),
      endDate: endOfDay(addDays(1, new Date())),
    },
  ])

  useDisableRefetchOnFocus({ isDisabled: !state.isWizardOpen })
  const [qaStepMeasurement, setQaStepMeasurement] =
    useState<{ belowTheBar: string; atTheBar: string; aboveTheBar: string }>()
  const ref = useRef<HTMLElement>(null)

  const { mutateAsync: snoozeStepRun, isLoading: isSnoozing } = useStepRunSnooze({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
    },
    onSuccess: (stepRun) => {
      reactQueryContext.queryClient.setQueryData<TFindAssignedToMeData | undefined>(
        ['stepRun.findAssignedToMe'],
        (previousAssignedStepRunsData) => {
          if (!previousAssignedStepRunsData) return

          return previousAssignedStepRunsData.map((entry) =>
            entry.stepRuns.some((s) => s.id === stepRun.id)
              ? {
                  ...entry,
                  stepRuns: entry.stepRuns.map((s) =>
                    s.id === stepRun.id ? { ...s, status: 'snoozed' } : s
                  ),
                }
              : entry
          )
        }
      )
    },
  })

  const { mutateAsync: unSnoozeStepRun, isLoading: isUnsnoozing } = useStepRunUnsnooze({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      reactQueryClient.invalidateQueries(['StepRunWithTimeLogDuration'])
    },
    onError: (error) => {
      addToast(`Unsnooze failed: ${error?.message}`, {
        appearance: 'error',
      })
    },
    onSuccess: () => {
      reactQueryContext.queryClient.setQueryData<
        inferQueryOutput<'stepRun.findAssignedToMe'> | undefined
      >(['stepRun.findAssignedToMe'], (previousAssignedStepRunsData) => {
        if (!previousAssignedStepRunsData) return

        return previousAssignedStepRunsData.map((entry) => ({
          ...entry,
          stepRuns: entry.stepRuns.map((s) =>
            s.id === stepRun?.id ? { ...s, status: 'running' } : s
          ),
        }))
      })
    },
  })

  const { data: process } = useProcessById({
    id: processId ?? '',
    enabled: !!processId,
  })

  const currentStep = useMemo(
    () => process?.steps?.find((s) => s.id === stepRun?.stepId),
    [process, stepRun?.stepId]
  ) as TManualStep

  const onNavigateBackToWizardTab = () => {
    if (document.visibilityState === 'visible') {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', onNavigateBackToWizardTab)
    return () => document.removeEventListener('visibilitychange', onNavigateBackToWizardTab)
  }, [])

  useEffect(() => {
    if (enableIdleSnoozeDesigns) return
    // when the wizard is open but it has been snoozed in another tab (only in case of manual snooze, not auto-snooze)
    if (state.isWizardOpen && state.stepRun?.status === 'snoozed' && !isAutoSnoozed) {
      addToast(`Closed the wizard as it has been snoozed in a different tab.`, {
        appearance: 'info',
      })
      dispatch({ type: 'closeWizard' })
      reactQueryClient.invalidateQueries('get-base-runs')
    }
  }, [state.stepRun])

  // old snooze design
  useEffect(() => {
    if (!enableIdleSnoozeDesigns) return
    // when the wizard is open but it has been snoozed in another tab (only in case of manual snooze, not auto-snooze)
    if (!isWizardOpen && isSnoozed && !isAutoSnoozed) {
      reactQueryClient.invalidateQueries('get-base-runs')
    }
  }, [isWizardOpen])

  // new snooze design
  // we are making sure to close the snooze info modal when the wizard is closed
  useEffect(() => {
    if (!isWizardOpen && isSnoozeInfoModalOpen) {
      handleCloseSnoozeInfoModal()
    }
  }, [isWizardOpen])

  useEffect(() => {
    if (autosaveConfig?.useAutosave && autosaveConfig?.frequency && autosaveConfig?.frequencyUnit) {
      const frequencyValues = {
        seconds: 1000,
        minutes: 60 * 1000,
        hours: 3600 * 1000,
      }
      const frequencyMs = autosaveConfig.frequency * frequencyValues[autosaveConfig.frequencyUnit]

      const interval = setInterval(async () => {
        try {
          await handleSaveBRVs()
        } catch (e) {
          console.error('Error saving autosave', e)
        }
      }, frequencyMs)

      return () => clearInterval(interval)
    }
  }, [autosaveConfig])

  useEffect(() => {
    if (!lastSaved && baseRun?.baseRunVariables) {
      const lastSavedBrv = baseRun?.baseRunVariables.sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      )[0]

      setLastSaved(lastSavedBrv?.updatedAt ?? null)
    }

    if (state.typeMismatches.length > 0) {
      logger.error(
        `Type mismatch error in the following fields: ${state.typeMismatches
          .map(
            (mismatch) =>
              `${mismatch.label}: expected ${mismatch.expected}, but got ${mismatch.actual}`
          )
          .join(', ')}`,
        {
          processId,
          stepId: stepRun?.stepId ?? '',
          stepRunId: stepRun?.id ?? '',
          baseRunId: baseRun?.id ?? '',
        }
      )
      addToast(
        <div>
          <p>Type mismatch error in the following fields:</p>
          <ul>
            {state.typeMismatches.map((mismatch, index) => (
              <li key={index}>
                <strong>{mismatch.label}</strong>: expected <em>{mismatch.expected}</em>, but got{' '}
                <em>{mismatch.actual}</em>
              </li>
            ))}
          </ul>
        </div>,
        {
          appearance: 'error',
        }
      )
    }
  }, [baseRun?.id])

  useEffect(() => {
    if (state.stepRun && currentUser && currentStep) {
      const qaConfig = (currentStep?.meta as ManualStepMeta.TSchema)?.qaConfig

      if (qaConfig) {
        const matchingCondition = (qaConfig.conditionalMeasurementDescriptions ?? []).find(
          (conditionalMeasurementDescription) => {
            const matchingBaseVariable = (
              (state.wizardInitialBRVs as TBaseRunVariable[]) ?? []
            ).find(
              (brv) =>
                brv?.baseVariable.id === conditionalMeasurementDescription.baseVariableId &&
                brv.value === conditionalMeasurementDescription.value
            )

            return !!matchingBaseVariable
          }
        )
        if (matchingCondition)
          setQaStepMeasurement({
            belowTheBar: matchingCondition.belowTheBar ?? '',
            atTheBar: matchingCondition.atTheBar ?? '',
            aboveTheBar: matchingCondition.aboveTheBar ?? '',
          })
        else {
          const generalDescriptions = qaConfig.measurementDescriptions
          setQaStepMeasurement({
            belowTheBar: generalDescriptions?.belowTheBar ?? '',
            atTheBar: generalDescriptions?.atTheBar ?? '',
            aboveTheBar: generalDescriptions?.aboveTheBar ?? '',
          })
        }

        const alreadyDisplayedQaConsent = localStorage.getItem(
          `${currentUser?.id}-${currentStep?.id}-${currentStep?.updatedAt}`
        )

        if (!alreadyDisplayedQaConsent) {
          setIsQaConsentModalOpen(true)
          // clean up the old step run updates consent
          Object.keys(localStorage)
            .filter((x) => x.startsWith(`${currentUser?.id}-${currentStep?.id}-`))
            .forEach((x) => localStorage.removeItem(x))
        }
      } else {
        setQaStepMeasurement(undefined)
      }
    }
  }, [stepRun, currentUser, currentStep])

  const isSameLayout = (savedWacLayout: ReactGridLayout.Layout[]) =>
    wizardData.every(
      (data) => savedWacLayout.findIndex((layout) => layout.i === data.layout.i) !== -1
    )

  const flags = currentStep?.meta?.flags
  const [lastPasteBR, setLastPasteBR] = useState<string | null>(null)

  const handlePaste = (e: ClipboardEvent) => {
    const target = e.target as HTMLElement
    const isInput =
      target.tagName === 'INPUT' ||
      target.tagName === 'TEXTAREA' ||
      target.getAttribute('contenteditable') === 'true'

    if (!isInput) return

    updateBaseRunVariable({
      input: {
        updateData: [
          {
            baseRunId: toGlobalId('BaseRunType', baseRun?.id),
            baseVariableId: toGlobalId('BaseVariableType', flags?.hasPastedTextBaseVariableId),
            value: true,
          },
        ],
      },
    })
    setLastPasteBR(baseRun?.id)
  }

  usePasteEvent({
    onPaste: handlePaste,
    enabled:
      Boolean(flags?.hasPastedTextBaseVariableId) &&
      !isUpdatingBaseRunVariable &&
      lastPasteBR !== baseRun?.id,
  })

  // Below replaces value: { baseVariableId: [uuid], dynamic: true } by value: [actual value of the baseVariable on the baseRun]
  useEffect(() => {
    let tempWizardConfig = wizardData

    try {
      if (isSameLayout(savedWacLayout)) {
        tempWizardConfig = tempWizardConfig.map((data) => {
          const layout = savedWacLayout.find((l) => l.i === data.layout.i)

          if (layout) {
            return {
              ...data,
              layout,
            }
          }

          return data
        })
      }
    } catch (e) {
      console.log('Could not parse saved wac layout', e)
    }

    setWizardConfig(
      tempWizardConfig.map((wac) => {
        const value = wac.config.value
        if (WizardSchemas.WACConfig.hasDynamicValue(value)) {
          const baseRunVariable = (state.wizardInitialBRVs as TBaseRunVariable[]).find(
            (brv) => brv.baseVariableId === value.baseVariableId
          )

          return {
            ...wac,
            config: {
              ...wac.config,
              value: baseRunVariable?.value,
              baseRunVariableId: baseRunVariable?.id,
            },
          }
        }
        return wac
      }) as WizardSchemas.WizardConfig.TSchema
    )
  }, [state?.wizardInitialBRVs, state.wizardData, state?.stepRun?.stepId])

  const setWacLayout = (layout: ReactGridLayout.Layout[]) => {
    setWizardConfig(
      (config) =>
        config?.map((data) => {
          const l = layout?.find((l) => l.i === data.layout.i)

          if (l) {
            return {
              ...data,
              layout: l,
            }
          }

          return data
        }) as WizardSchemas.WizardConfig.TSchema
    )
  }

  const handleResetWacLayout = () => {
    if (wizardData.length > 0) {
      localStorage.removeItem(getSavedWacLayoutKey(state?.stepRun?.stepId))
      setWacLayout(wizardData.map((wac) => wac.layout))
    }
  }

  const handleResetLayoutBtnClick = () => {
    const resetLayoutDontAskAgain = localStorage.getItem('reset-layout-dont-ask-again')

    if (resetLayoutDontAskAgain) {
      handleResetWacLayout()
    } else {
      setIsResetLayoutModalOpen(true)
    }
  }

  const handleSaveBRVs = async () => {
    await updateBaseRunVariable({
      input: {
        updateData:
          baseRunRef.current?.baseRunVariables.map((brv) => ({
            baseRunId: toGlobalId('BaseRunType', baseRun?.id),
            baseVariableId: toGlobalId('BaseVariableType', brv.baseVariableId),
            value: brv.value,
          })) ?? [],
      },
    })
  }

  const handleSaveBRVsWithSnackbars = async () => {
    try {
      showSnackbar({
        title: 'Saving...',
        description: 'All changes are being saved before proceeding.',
        variant: 'info',
        autoHide: false,
        actions: (
          <Stack
            direction='column'
            height='100%'
            alignItems='center'
            pl={2}
            justifyContent='center'>
            <CircularProgress size={32} />
          </Stack>
        ),
      })
      await handleSaveBRVs()
      showSnackbar({
        title: 'Success!',
        description: 'Your progress is saved.',
        variant: 'success',
        autoHide: true,
      })
      return true
    } catch (e) {
      showSnackbar({
        title: 'Error!',
        description: 'Saving failed. Please try again.',
        variant: 'error',
        autoHide: false,
        actions: (
          <MuiButton
            variant='outlined'
            size='small'
            sx={{ color: '#5F2120', borderColor: 'black', fontWeight: 500 }}
            onClick={() => handleSaveBRVs()}>
            Retry save
          </MuiButton>
        ),
      })
      return false
    }
  }

  const handleSnooze = async (save = false) => {
    if (stepRun?.id) {
      maybeLogStepRunEvent({
        name: 'step_run_snooze_clicked',
        stepRunId: stepRun.id,
        spanId: getUUIDFromNamespace([stepRun.id, TOKEN_NAMES.SNOOZE_ACTIVITY]),
        spanType: 'SNOOZE_ACTIVITY',
        type: IStepRunEventTypeEnum.Span,
        timestamp: new Date(),
      })
      await snoozeStepRun({
        stepRunId: stepRun.id,
      })

      if (enableAutoSaving && autosaveConfig?.useAutosave && save) {
        await handleSaveBRVsWithSnackbars()
      }

      if (!enableIdleSnoozeDesigns) {
        dispatch({ type: 'closeWizard' })
      }
    }
  }

  const handleUnsnooze = async () => {
    if (stepRun?.status === 'snoozed') {
      const snoozeEvent = {
        name: 'step_run_unsnooze_clicked',
        spanId: getUUIDFromNamespace([stepRun.id as string, TOKEN_NAMES.SNOOZE_ACTIVITY]),
        stepRunId: stepRun.id as string,
        spanType: 'SNOOZE_ACTIVITY',
        type: IStepRunEventTypeEnum.Span,
        timestamp: new Date(),
      } as const

      await unSnoozeStepRun({ stepRunId: stepRun.id })
      maybeLogStepRunEvent(snoozeEvent)
    }
  }

  const handleCloseWizard = async (save = false) => {
    if (enableAutoSaving && autosaveConfig?.useAutosave && save) {
      const success = await handleSaveBRVsWithSnackbars()
      if (success) {
        dispatch({ type: 'closeWizard' })
      } else {
        return
      }
    }

    dispatch({ type: 'closeWizard' })
    if (enableIdleSnoozeDesigns) {
      handleCloseSnoozeInfoModal()
    }
    reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const offlineModalData = localStorage.getItem('offline-modal')
      const storedShowOfflineModal = offlineModalData ? JSON.parse(offlineModalData) : false
      setIsOffline(storedShowOfflineModal)

      const idleCheckData = localStorage.getItem('idle-check')
      const storedIdleCheck = idleCheckData ? JSON.parse(idleCheckData) : {}
      seIsAutoSnoozed(storedIdleCheck?.isAutoSnoozed ?? false)
    }

    handleStorageChange()
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const handleDismissChange = () => {
    setIsDismissed((prev) => !prev)
  }

  const handlePauseClick = () => {
    const isSnoozeInfoDismissedData = localStorage.getItem(
      LOCAL_STORAGE_KEYS.DISMISS_SNOOZE_INFO_MODAL
    )
    const isSnoozeInfoDismissed = isSnoozeInfoDismissedData
      ? JSON.parse(isSnoozeInfoDismissedData)
      : false

    if (!isSnoozeInfoDismissed) {
      setIsSnoozeInfoModalOpen(true)
    }

    handleSnooze()
  }

  const handleCloseSnoozeInfoModal = () => {
    setIsSnoozeInfoModalOpen(false)
  }

  const handleDismissClick = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.DISMISS_SNOOZE_INFO_MODAL, JSON.stringify(isDismissed))
    handleCloseSnoozeInfoModal()
  }

  const handleResumeClick = () => {
    const isUserAvailableNow = schedules?.some((schedule) =>
      isWithinInterval(new Date(), {
        start: new Date(schedule.startTime),
        end: new Date(schedule.endTime),
      })
    )
    if (!isUserAvailableNow) {
      setOpenAddAvailabilityModal(true)
      return
    }

    handleUnsnooze()
  }

  const handleAddAvailabilityModalClose = () => {
    setOpenAddAvailabilityModal(false)
  }
  const isResumeLoading = isUnsnoozing || isAvailabilityLoading

  const getPauseBtn = () => {
    if (enableAutoSaving && autosaveConfig?.useAutosave) {
      if (autosaveConfig?.showSaveButton) {
        return (
          <WizardHeaderButton
            title='Pause'
            disabled={isWacSubmitting}
            onClick={() => handleSnooze(true)}>
            Pause
          </WizardHeaderButton>
        )
      }

      return (
        <WizardConfirmationDialogBtn
          title='Save before pausing?'
          description={
            <p>
              You are about to pause this task. Do you want to save your progress before pausing?
              <br />
              Once saved, you won't be able to undo changes.
            </p>
          }
          buttonContent='Pause'
          agreeText='SAVE AND PAUSE'
          disagreeText='PAUSE WITHOUT SAVING'
          onAgree={() => handleSnooze(true)}
          onDisagree={() => handleSnooze()}
        />
      )
    }

    if (enableIdleSnoozeDesigns) {
      return getSnoozeStatusSwitchBtnV2()
    }

    return (
      <WizardHeaderButton title='Pause' disabled={isWacSubmitting} onClick={() => handleSnooze()}>
        Pause
      </WizardHeaderButton>
    )
  }

  const getSnoozeStatusSwitchBtnV2 = () => {
    if (isSnoozed) {
      return (
        <WizardHeaderButton onClick={handleResumeClick} loading={isResumeLoading}>
          Resume
        </WizardHeaderButton>
      )
    }
    return (
      <WizardHeaderButton
        onClick={handlePauseClick}
        disabled={isWacSubmitting}
        loading={isSnoozing}>
        Pause
      </WizardHeaderButton>
    )
  }

  if (!isWizardOpen) return null

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Backdrop>
          <WizardContainer>
            <Topbar minWidth={0}>
              <Box maxWidth='100%' minWidth={0} width='fit-content'>
                <Stack direction='row' spacing={1} alignItems='center' maxWidth='100%' minWidth={0}>
                  <WizardTitle>{state.stepName?.toUpperCase()}</WizardTitle>
                  <WizardLastSaved lastSaved={lastSaved} isSaving={isUpdatingBaseRunVariable} />
                </Stack>
              </Box>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  divider={
                    <Divider
                      orientation='vertical'
                      flexItem
                      sx={{ backgroundColor: 'white', opacity: 0.5 }}
                    />
                  }
                  ml={10}>
                  {state.trainingLink && (
                    <a
                      href={state.trainingLink}
                      title='Show Me How!'
                      target='_blank'
                      rel='noreferrer noopener'
                      style={{ height: '18px' }}>
                      <TaskTrainingIcon
                        color='white'
                        width={45}
                        height={18}
                        style={{ cursor: 'pointer' }}
                      />
                    </a>
                  )}
                  <Stack direction='row' spacing={'6px'} alignItems='center'>
                    <WizardTimer
                      isOffline={isOffline}
                      isAutoSnoozed={isAutoSnoozed}
                      isSnoozed={isSnoozed}
                    />
                    {qaStepMeasurement ? (
                      <StarShineIcon
                        onClick={() => setIsQaConsentModalOpen((prev) => !prev)}
                        className='cursor-pointer text-white'
                        width={20}
                        height={20}
                      />
                    ) : null}
                    {getPauseBtn()}
                  </Stack>
                  <WizardHeaderButton
                    title='Reset WAC Layout to default'
                    disabled={isEqual(
                      wizardConfig?.map((config) => {
                        const { x, y, h, w, i } = config.layout
                        return { x, y, h, w, i }
                      }),
                      wizardData?.map((data) => {
                        const { x, y, h, w, i } = data.layout
                        return { x, y, h, w, i }
                      })
                    )}
                    onClick={handleResetLayoutBtnClick}>
                    Reset Layout
                  </WizardHeaderButton>
                  {enableAutoSaving &&
                    autosaveConfig?.useAutosave &&
                    autosaveConfig?.showSaveButton && (
                      <WizardHeaderButton title='Save' onClick={handleSaveBRVsWithSnackbars}>
                        Save
                      </WizardHeaderButton>
                    )}
                </Stack>
                {enableAutoSaving && autosaveConfig?.useAutosave ? (
                  <WizardConfirmationDialogBtn
                    title='Close before pausing?'
                    description={
                      <p>
                        You are about to close this task. Do you want to save your progress before
                        closing?
                        <br />
                        Once saved, you won't be able to undo changes.
                      </p>
                    }
                    buttonContent={
                      <CloseIcon
                        color='white'
                        width={24}
                        height={24}
                        style={{ cursor: 'pointer', width: '24px' }}
                      />
                    }
                    agreeText='SAVE AND CLOSE'
                    disagreeText='CLOSE WITHOUT SAVING'
                    onAgree={() => handleCloseWizard(true)}
                    onDisagree={() => handleCloseWizard()}
                  />
                ) : (
                  <CloseIcon
                    color='white'
                    onClick={() => handleCloseWizard()}
                    width={24}
                    height={24}
                    style={{ cursor: 'pointer', width: '24px' }}
                  />
                )}
              </Stack>
            </Topbar>
            {enableIdleSnoozeDesigns && (
              <MuiBackdrop
                open={isSnoozed}
                sx={{ zIndex: 1, top: '90px', left: '10px' }}
                className='absolute h-[calc(100vh_-_100px)] w-[calc(100vw_-_20px)] rounded-b backdrop-blur'>
                <LoadingButton
                  sx={darkColorBtnStyles}
                  variant='contained'
                  size='medium'
                  color='primary'
                  loading={isResumeLoading}
                  startIcon={<TimerSharpIcon fontSize='small' />}
                  onClick={handleResumeClick}>
                  <Typography variant='button' sx={{ textTransform: 'none' }}>
                    Resume work
                  </Typography>
                </LoadingButton>
              </MuiBackdrop>
            )}
            <ResetLayoutModal
              isOpen={isResetLayoutModalOpen}
              processName={process?.name}
              onClose={() => setIsResetLayoutModalOpen(false)}
              onResetLayout={() => {
                handleResetWacLayout()
                setIsResetLayoutModalOpen(false)
              }}
              onDontAskAgain={() => {
                localStorage.setItem('reset-layout-dont-ask-again', 'true')
              }}
            />
            <RGLContainer
              margin={[10, 10]}
              rowHeight={30}
              containerPadding={[20, 10]}
              isDraggable={false}
              isResizable={false}
              draggableHandle='.react-grid-dragHandle-WAC'
              onLayoutChange={(layout) => {
                setSavedWacLayout(layout)
                setWacLayout(layout)
              }}
              layout={wizardConfig?.map((wac) => wac.layout)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={ref}>
              {wizardConfig?.map((wac) => (
                <div key={wac.layout.i}>
                  <div className='react-grid-dragHandle-WAC absolute z-50 cursor-grab'>
                    <DragHandleVerticalIcon height={12} width={12} />
                  </div>
                  <MemoizedWAC wac={wac} ref={ref} companyName={process?.company?.name ?? ''} />
                </div>
              ))}
            </RGLContainer>
          </WizardContainer>
          <Modal
            title='Acknowledge QA Scoring Updates'
            disableClose
            disableClickAway
            primaryButton={
              <Button
                onClick={() => {
                  localStorage.setItem(
                    `${currentUser?.id}-${currentStep?.id}-${currentStep?.updatedAt}`,
                    'true'
                  )
                  setIsQaConsentModalOpen(false)
                }}>
                Acknowledge Updates
              </Button>
            }
            onClose={() => setIsQaConsentModalOpen(false)}
            visible={isQaConsentModalOpen}>
            <div className='flex max-h-80 max-w-md flex-col gap-y-4 overflow-scroll'>
              <div>
                Changes were made to this Task's QA Scoring we want to make sure you are aware of
                before you continue working.
              </div>
              <div className='flex flex-col gap-y-2'>
                <div className='flex gap-x-1'>
                  <div className='text-red-main'>
                    <ThumbsDownIcon />
                  </div>
                  <div className='font-bold'>Below the Bar</div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(qaStepMeasurement?.belowTheBar ?? ''),
                  }}
                />
              </div>
              <div className='flex flex-col gap-y-2'>
                <div className='flex gap-x-1'>
                  <div className='text-main'>
                    <ThumbsUpIcon />
                  </div>
                  <div className='font-bold'>At the Bar</div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(qaStepMeasurement?.atTheBar ?? ''),
                  }}
                />
              </div>
              <div className='flex flex-col gap-y-2'>
                <div className='flex gap-x-1'>
                  <div className='text-success-main'>
                    <StarShineIcon />
                  </div>
                  <div className='font-bold'>Above the Bar</div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(qaStepMeasurement?.aboveTheBar ?? ''),
                  }}
                />
              </div>
            </div>
          </Modal>
          {enableIdleSnoozeDesigns && (
            <>
              <Dialog
                maxWidth='sm'
                onClose={handleCloseSnoozeInfoModal}
                open={isSnoozeInfoModalOpen}>
                <div className='flex items-center justify-between px-6 py-4'>
                  <Typography variant='h6'>Task paused</Typography>{' '}
                  <CloseIcon
                    color={palette.black.main}
                    width={18}
                    height={18}
                    onClick={handleCloseSnoozeInfoModal}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <DialogContent sx={{ paddingTop: 0 }}>
                  <div className='flex flex-col gap-6'>
                    <Typography variant='body2'>
                      The Interface will remain open but you will be unable to complete work for
                      this task while it is paused.
                    </Typography>
                    <FormControlLabel
                      className='pl-2'
                      control={
                        <Checkbox
                          checked={isDismissed}
                          onChange={handleDismissChange}
                          sx={{
                            '&.Mui-checked': {
                              color: `${palette.black.main}`,
                            },
                          }}
                        />
                      }
                      label={<Typography variant='body2'>Don't show this message again</Typography>}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <MuiButton
                    sx={darkColorBtnStyles}
                    variant='contained'
                    size='medium'
                    color='primary'
                    onClick={handleDismissClick}>
                    <Typography variant='button' sx={{ textTransform: 'none' }}>
                      Dismiss
                    </Typography>
                  </MuiButton>
                </DialogActions>
              </Dialog>
              <AddAvailabilityModal
                schedules={schedules ?? []}
                onAdded={handleUnsnooze}
                isOpen={openAddAvailabilityModal}
                onClose={handleAddAvailabilityModalClose}
              />
            </>
          )}
        </Backdrop>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

const MemoizedWAC = memo(
  forwardRef(
    (
      {
        wac,
        companyName,
      }: { wac: WizardSchemas.WizardConfig.TSchema[number]; companyName: string },
      ref
    ) => {
      const WAC = allWACs[wac.config.type]
      const {
        state: { stepRun, wizardInitialBRVs, baseRun },
      } = useWizardState()

      // Overriding default viewname behavior here to capture the Wizard modal in DD RUM
      useEffect(() => {
        if (wac.config.name !== undefined) {
          datadogRum.startView({ name: `Wizard: ${wac.config.name} - ${wac.config.type}` })
        }
      }, [wac.config])

      if (!WAC) return null

      return (
        <WAC
          {...wac.config}
          height={wac.layout.h}
          wizardInitialBRVs={wizardInitialBRVs as TBaseRunVariable[]}
          baseRun={baseRun}
          stepRun={stepRun}
          isReadOnly={false}
          companyName={companyName}
          ref={ref}
        />
      )
    }
  ),
  (oldProps, newProps) => isEqual(oldProps.wac, newProps.wac)
)

const Wizard = () => (
  <ThemeProvider theme={theme}>
    <SnackbarProvider>
      <WizardContent />
    </SnackbarProvider>
  </ThemeProvider>
)

export { Wizard }
