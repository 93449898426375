import { blue, grey, red } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'
import { gridClasses, GridColType } from '@mui/x-data-grid-pro'

const getColumnType = (type: string) =>
  (({
    enum: 'singleSelect',
    a_enum: 'multiSelect',
    boolean: 'boolean',
    number: 'number',
    datetime: 'dateTime',
    date: 'date',
  }[type] ?? 'string') as GridColType)

const parsColumnValue = (value: any, type: string) => {
  if (value === null || value === undefined) {
    return null
  }

  if (['datetime', 'date'].includes(type)) {
    return new Date(value)
  }
  return value
}

const isValidFieldValue = (value: any): boolean => {
  if ([null, undefined, ''].includes(typeof value === 'string' ? value.trim() : value)) {
    return false
  }
  return true
}

const calculateTextWidth = (text: string, font = 14) => (font * text.length) / 2

const themeStyleOverides = {
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: '#aea4d2 solid 2px',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: '#aea4d2 solid 10px',
  },
  '& .row--even': {
    backgroundColor: 'inherit',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(grey[200], 0.6),
    },
  },
  '& .row--odd': {
    backgroundColor: 'inherit',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(grey[200], 0.6),
    },
  },
  '& .MuiDataGrid-row:focus, & .MuiDataGrid-row:focus-within': {
    backgroundColor: alpha(blue[100], 0.1),
  },
  '& .Mui-error': {
    backgroundColor: alpha(red[100], 0.5),
  },
}

/**
 * Replace an object in an array by matching 'id', returning a brand new array.
 *
 * @param arr       - The original array of objects
 * @param idField   - The field for ID to match
 * @param newObject - The new object to insert
 * @returns A new array with the object replaced (if found), otherwise original
 */
function replaceObjectById(
  arr: Array<Record<string, any>>,
  idField: string | number,
  newObject: Record<string, any>
): Array<Record<string, any>> {
  const index = arr.findIndex((obj) => obj[idField] === newObject[idField])
  if (index !== -1) {
    // Create a shallow copy, then replace the item at the same position
    const newArr = [...arr]
    newArr[index] = newObject
    return newArr
  }

  // If not found, return the original array
  return arr
}

function parseAsArray<T = unknown>(value: unknown): T[] {
  // If 'value' is a string, attempt to parse it as JSON
  if (typeof value === 'string') {
    try {
      const parsed = JSON.parse(value)
      // Check if parsed result is an array
      if (Array.isArray(parsed)) {
        return parsed
      }
      throw new Error('Not an array')
    } catch (error) {
      // Either invalid JSON or not an array
      throw new Error('Invalid JSON or not an array')
    }
  }

  // If it's not a string, it should already be an array
  if (Array.isArray(value)) {
    return value
  }

  // Otherwise, it's neither a string we can parse nor an array
  throw new Error('Not an array')
}

/**
 * Finds and removes the first item in the array whose `__id` matches `idValue`.
 *
 * @param arr      An array of objects that each have a `__id` property.
 * @param idValue  The value of the `__id` to look for.
 * @returns        The modified array (with the matching item removed if found).
 */
function removeById<T extends { __id: string }>(arr: T[], idValue: string): T[] {
  const index = arr.findIndex((item) => item.__id === idValue)
  if (index !== -1) {
    arr.splice(index, 1) // Remove the item at 'index'
  }
  return arr // Return the updated array
}

/**
 * Basic typed interface to describe the result of a pagination operation.
 */
interface PaginatedResult<T> {
  page: number
  pageSize: number
  totalItems: number
  totalPages: number
  items: T[]
}

/**
 * Paginates an array of data and returns paginated items plus metadata.
 * @param data       The full array of items (generic type `T`)
 * @param page       The 1-based page index
 * @param pageSize   The number of items per page
 * @returns          An object of type `PaginatedResult<T>` containing paginated items & metadata
 */
function paginateWithMeta<T>(data: T[], page: number, pageSize: number): PaginatedResult<T> {
  // Ensure page is at least 1
  const validPage = Math.max(page, 1)

  // Calculate total items and total pages
  const totalItems = data.length
  const totalPages = Math.ceil(totalItems / pageSize)

  // Calculate start and end indexes
  const startIndex = (validPage - 1) * pageSize
  const endIndex = startIndex + pageSize

  // Slice the data to retrieve the items for this page
  const items = data.slice(startIndex, endIndex)

  return {
    page: validPage,
    pageSize,
    totalItems,
    totalPages,
    items,
  }
}

export {
  calculateTextWidth,
  getColumnType,
  isValidFieldValue,
  paginateWithMeta,
  parsColumnValue,
  parseAsArray,
  removeById,
  replaceObjectById,
  themeStyleOverides,
}
