import { TBaseVariable } from '@invisible/common/components/process-base'
import { SnackbarContext } from '@invisible/common/providers'
import { logger } from '@invisible/logger/client'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { useContext, useEffect } from 'react'

const MULTISELECT_FIELD_VALID_TYPES = ['a_number', 'a_string', 'a_boolean', 'a_enum']

interface Mismatch {
  field: string
  actual: string
}

export const useMultiselectFieldTypeValidation = (
  formFields: WizardSchemas.FormField.TSchema[] | undefined,
  formBaseVariables: TBaseVariable[] | undefined,
  processId?: string,
  stepRunId?: string,
  baseRunId?: string
) => {
  const { showSnackbar } = useContext(SnackbarContext)

  useEffect(() => {
    if (
      !formFields ||
      formFields.length === 0 ||
      !formBaseVariables ||
      formBaseVariables.length === 0
    ) {
      return
    }

    const mismatches: Mismatch[] = formFields
      .filter((field) => field.type === 'multiselect')
      .flatMap((field) => {
        const baseVariable = formBaseVariables.find(
          (variable) => variable.id === field.baseVariableId
        )

        if (!baseVariable || !MULTISELECT_FIELD_VALID_TYPES.includes(baseVariable.type)) {
          return [
            {
              field: baseVariable?.name || 'unknown',
              actual: baseVariable?.type || 'unknown',
            },
          ]
        }

        return []
      })

    if (mismatches.length > 0) {
      showSnackbar({
        message: (
          <>
            <p>
              <strong>Multiselect Field Type Mismatch Detected:</strong>
            </p>
            <p>
              The following multiselect fields have incompatible base variable types. Please ensure
              they are set to one of: <em>a_number, a_string, a_boolean, a_enum</em>.
            </p>
            <ul>
              {mismatches.map((mismatch, index) => (
                <li key={index}>
                  <strong>{mismatch.field}</strong>: got <em>{mismatch.actual}</em>
                </li>
              ))}
            </ul>
          </>
        ),
        variant: 'error',
      })

      logger.error(
        `Type mismatch detected for multiselect fields. The following fields have incompatible base variable types: ${mismatches
          .map((m) => `${m.field} (got: ${m.actual})`)
          .join(', ')}. Expected types: a_number, a_string, a_boolean, a_enum.`,
        {
          processId,
          stepRunId,
          baseRunId,
          mismatches,
        }
      )
    }
  }, [JSON.stringify(formBaseVariables), JSON.stringify(formFields)])
}
