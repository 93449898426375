import { Components } from 'react-markdown'

import KatexRenderer from './Katex'
import Markdown from './Markdown'
import MarkdownWithLatex from './MarkdownWithLatex'
import { TTextDirection } from './types'

/**
 * Interface for TextRender component props
 * @interface ITextRender
 * @property {string} text - The text content to render
 * @property {'plaintext' | 'markdown' | 'latex'} renderMode - The mode to render the text in
 * @property {Object} [markdownProps] - Props for markdown rendering
 * @property {string} [markdownProps.className] - CSS class for markdown container
 * @property {TTextDirection} [markdownProps.dir] - Text direction for markdown
 * @property {Components} [markdownProps.components] - Custom components for markdown rendering
 * @property {Object} [latexProps] - Props for LaTeX rendering
 * @property {string} [latexProps.containerClassName] - CSS class for LaTeX outer container
 * @property {string} [latexProps.className] - CSS class for LaTeX inner container
 * @property {TTextDirection} [latexProps.textDirection] - Text direction for LaTeX
 * @property {Object} [plaintextProps] - Props for plaintext rendering
 * @property {string} [plaintextProps.className] - CSS class for plaintext container
 */
interface ITextRender {
  text: string
  renderMode: 'plaintext' | 'markdown' | 'latex' | 'markdown-latex'
  markdownProps?: {
    className?: string
    dir?: TTextDirection
    components?: Components
  }
  latexProps?: {
    containerClassName?: string
    className?: string
    textDirection?: TTextDirection
  }
  plaintextProps?: {
    className?: string
    dir?: TTextDirection
  }
  markdownWithLatexProps?: {
    className?: string
    dir?: TTextDirection
  }
}

/**
 * Component that renders text content in different modes (plaintext, markdown, or LaTeX)
 * @param {ITextRender} props - Component props
 * @param {string} props.text - The text content to render
 * @param {'plaintext' | 'markdown' | 'latex'} props.renderMode - The mode to render the text in
 * @param {Object} [props.markdownProps] - Props for markdown rendering
 * @param {Object} [props.latexProps] - Props for LaTeX rendering
 * @param {Object} [props.plaintextProps] - Props for plaintext rendering
 */
const TextRender = ({
  text,
  renderMode,
  markdownProps,
  latexProps,
  plaintextProps,
  markdownWithLatexProps,
}: ITextRender) => {
  if (renderMode === 'markdown') {
    return <Markdown {...markdownProps}>{text}</Markdown>
  }
  if (renderMode === 'latex') {
    return (
      <div className={latexProps?.containerClassName} dir={latexProps?.textDirection ?? 'auto'}>
        <KatexRenderer
          className={latexProps?.className}
          content={text}
          textDirection={latexProps?.textDirection}
        />
      </div>
    )
  }
  if (renderMode === 'markdown-latex') {
    return <MarkdownWithLatex content={text} {...markdownWithLatexProps} />
  }
  return (
    <p {...plaintextProps} dir={plaintextProps?.dir ?? 'auto'}>
      {text}
    </p>
  )
}

export default TextRender
