import { z } from '@invisible/zod'

const schema = z.object({
  useAutosave: z.boolean().optional(),
  showSaveButton: z.boolean().optional(),
  frequency: z.number().optional(),
  frequencyUnit: z.enum(['seconds', 'minutes', 'hours']).optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
