import { logger } from '@invisible/logger/client'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'

type Props = {
  interval: number
  timeout: number
  updateVariables: () => void
}

const usePollWizardValidationTask = ({ interval, timeout, updateVariables }: Props) => {
  const startPolling = (taskId: string) =>
    new Promise<void>((resolve, reject) => {
      const startTime = Date.now()
      const poll = async () => {
        const elapsedTime = Date.now() - startTime

        if (elapsedTime >= timeout) {
          logger.error('Request timed out: wizard validation task')
          reject('Wizard validation request timed out')
          return
        }

        try {
          const resp = await fetch(
            `${NEXT_PUBLIC_CONCORDE_URL}/api/wizard/validate/task-status/${taskId}`,
            {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          const { status, message } = await resp.json()
          if (status === 'SUCCESS') {
            updateVariables()
            resolve(message)
            return
          }
          if (status === 'FAILURE') {
            logger.error('Failed wizard validation task.', {
              error: message,
              taskId: taskId,
            })
            reject(message)
            return
          }
        } catch (err) {
          logger.error('Failed to validate wizard.', {
            error: err,
            taskId: taskId,
          })
          reject(err)
          return
        }
        setTimeout(poll, interval) // Set the next poll
      }
      poll()
    })
  return startPolling
}

export { usePollWizardValidationTask }
