import { getErrorMessage } from '@invisible/concorde/gql-client'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { ManualStepMeta } from '@invisible/ultron/zod'
import axios from 'axios'
import { useWizardState } from 'libs/common/components/providers/active-wizard-provider/src/lib/ActiveWizardProvider'
import React, { useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'
import useBaseRunVariableFindManyByBaseRunId from '../hooks/useBaseRunVariableFindManyByBaseRunId'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import { usePollWizardValidationTask } from '../hooks/usePollWizardValidationTask'
type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

type IProps = {
  name: string
  stepRun: TStepRun
}

const ValidateButtonWAC = ({ name, stepRun }: IProps) => {
  const reactQueryContext = useContext()
  const { addToast } = useToasts()
  const [isLoading, setIsLoading] = React.useState(false)
  const { dispatch } = useWizardState()

  const baseRunVariables = useBaseRunVariableFindManyByBaseRunId({
    baseRunIds: [stepRun.baseRunId],
  })
  const updateVariables = useCallback(() => {
    const outputBaseVariableIds =
      (stepRun?.step?.meta as ManualStepMeta.TSchema)?.validationsMeta?.outputBaseVariableIds ?? []
    // Update the wizard state with the validation result base run variables
    outputBaseVariableIds.forEach((outputBaseVariableId) => {
      const matchingBaseRunVariable = baseRunVariables?.find(
        (baseRunVariable) => baseRunVariable.baseVariableId === outputBaseVariableId
      )

      if (matchingBaseRunVariable) {
        dispatch({
          type: 'setBaseRunVariableValue',
          baseRunVariableId: matchingBaseRunVariable.id,
          value: matchingBaseRunVariable.value,
        })
      }
    })
  }, [stepRun, baseRunVariables])

  const startPolling = usePollWizardValidationTask({
    interval: 5000, // 5 seconds
    timeout: 300000, // 5 minutes
    updateVariables,
  })

  const handleClick = async () => {
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/wizard/validate/`,
        {
          step_run_id: stepRun.id as string,
        },
        {
          withCredentials: true,
        }
      )

      startPolling(response.data.task_id)
        .then(() => {
          reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
        })
        .catch((err) => {
          sendErrorToSentry(err)
          const message = getErrorMessage(err)
          addToast(`Something went wrong: ${message}`, {
            appearance: 'error',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } catch (error) {
      sendErrorToSentry(error)
      const message = getErrorMessage(error)
      addToast(`Something went wrong: ${message}`, {
        appearance: 'error',
      })
    }
  }

  return (
    <div className='mt-2 flex justify-end'>
      <Button variant='primary' size='md' onClick={handleClick} disabled={isLoading}>
        {name}
      </Button>
    </div>
  )
}

export { ValidateButtonWAC }
