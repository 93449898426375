import { TBaseRunVariable } from '@invisible/common/components/providers/active-wizard-provider'
import { Button } from '@invisible/ui/button'
import { Dropdown } from '@invisible/ui/dropdown'
import { copyToClipboard } from '@invisible/ui/helpers'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { Tooltip } from '@invisible/ui/tooltip'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import Portal from '@mui/material/Portal'
import classNames from 'classnames'
import { keys, omit } from 'lodash/fp'
import { FC, useCallback, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import { useSelectedEmbedLink } from '../providers/SelectedEmbedLinkProvider'
import { EmbedWindow } from './EmbedWindow'

type TBaseRun = TBaseRunQueryData['items'][number]
type IProps = WizardSchemas.WACConfig.TSchema & {
  height: number
  baseRun: TBaseRun
  wizardInitialBRVs: TBaseRunVariable[]
}

const Container = styled.div`
  border-radius: 8px;
  height: 100%;
  background-color: white;
  border: 1px solid ${gray(4)};
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
  position: relative;
  display: flex;
  flex-direction: column;
`

// eslint-disable-next-line @typescript-eslint/ban-types
export const EmbedWAC: FC<IProps> = ({
  value,
  wizardInitialBRVs,
  showName,
  name,
  id,
  embed,
  height,
}) => {
  const isInDesktopContext = (window as any).IS_IN_DESKTOP_CONTEXT as boolean
  const { dispatch } = useSelectedEmbedLink()
  const [activeTab, setActiveTab] = useState('default')
  const [iframeTabs, setIframeTabs] = useState<Record<string, { url: string; tabName: string }>>({})

  const [lastNewTabUrl, setLastNewTabUrl] = useState('')
  const [isFullscreen, setIsFullscreen] = useState(false)

  const newTabHandler = useCallback((e) => {
    const tabId = uuid()
    setIframeTabs((prev) => ({
      ...prev,
      [tabId]: { url: e.detail.newTabUrl, tabName: 'Tab ' + (Object.keys(prev).length + 1) },
    }))
    setLastNewTabUrl(e.detail.newTabUrl)
  }, [])

  const navigationHandler = useCallback(
    (e) => {
      // Link was opened in a new tab and we do not want to update the currently active url
      if (e.detail.currentUrl === lastNewTabUrl) return
      setIframeTabs((prev) => ({
        ...prev,
        [activeTab]: { ...prev[activeTab], url: e.detail.currentUrl },
      }))
      dispatch({
        type: 'changeCurrentEmbedLink',
        payload: {
          embedId: id,
          link: e.detail.currentUrl,
        },
      })
    },
    [dispatch, id, lastNewTabUrl, activeTab]
  )

  useEffect(() => {
    const tabs = {} as Record<string, { url: string; tabName: string }>
    const tabsCurrentUrl = {} as Record<string, string>
    if (typeof value === 'string') {
      tabs.default = { url: value, tabName: 'Tab 1' }
    } else if (Array.isArray(value)) {
      value.forEach((v: string, i) => {
        const tabName = i === 0 ? 'default' : `tab${i}`
        tabs[tabName] = { url: v, tabName: `Tab ${i + 1}` }
        tabsCurrentUrl[tabName] = v
      })
    }
    setActiveTab('default')
    setIframeTabs(tabs)
  }, [value])

  // `newTabEvent` is dispatched from the extension on clicking the`Open in Invisi-tab` button
  useEffect(() => {
    document.addEventListener('newTabEvent', newTabHandler)

    return () => {
      document.removeEventListener('newTabEvent', newTabHandler)
    }
  }, [newTabHandler])

  // `iframeNavigationEvent` is dispatched from the extension on every navigation in the iframe
  useEffect(() => {
    document.addEventListener('iframeNavigationEvent', navigationHandler)

    return () => {
      document.removeEventListener('iframeNavigationEvent', navigationHandler)
    }
  }, [navigationHandler])

  useEffect(() => {
    if (iframeTabs[activeTab]) {
      dispatch({
        type: 'changeCurrentEmbedLink',
        payload: {
          embedId: id,
          link: iframeTabs[activeTab].url,
        },
      })
    }
  }, [iframeTabs, activeTab])

  return (
    <Container>
      {showName ? (
        <Text fontWeight='bold' mb='10px'>
          {name}
        </Text>
      ) : null}

      <div className='w-full'>
        <Tabs
          value={activeTab}
          onChange={(tab) => {
            if (tab !== activeTab) setActiveTab(tab as string)
          }}
          onTabDelete={
            keys(iframeTabs).length > 1
              ? (tab) => {
                  const tabNames = keys(iframeTabs)
                  const deletedTabIndex = tabNames.indexOf(tab as string)

                  if (tab === activeTab)
                    setActiveTab(
                      deletedTabIndex === 0 ? tabNames[1] : tabNames[deletedTabIndex - 1]
                    )

                  setIframeTabs((prev) => omit(tab, prev) as any)
                }
              : undefined
          }
          onReorder={(newOrder) =>
            setIframeTabs((prev) =>
              keys(prev)
                .sort((a, b) => newOrder.indexOf(a) - newOrder.indexOf(b))
                .reduce<Record<string, { url: string; tabName: string }>>((acc, key) => {
                  acc[key] = prev[key]
                  return acc
                }, {})
            )
          }
          buttons
          draggable
          size='small'>
          {keys(iframeTabs).map((tab) => {
            const protocol = iframeTabs[tab].url.startsWith('http') ? '' : 'https://'

            return (
              <Tab name={iframeTabs[tab].tabName} value={tab} key={tab} preserveWhenInactive>
                {isInDesktopContext ? (
                  <EmbedWindow
                    height={height * 30 + 40}
                    initialURL={iframeTabs[tab].url}
                    protocol={protocol}
                    tabId={tab}
                  />
                ) : (
                  <Portal disablePortal={!isFullscreen}>
                    <div
                      className={classNames(
                        isFullscreen ? 'fixed top-0 z-[100] w-full transition' : ''
                      )}>
                      <div
                        className={classNames(
                          'border-theme-weak-3 flex items-center justify-between border border-solid p-2',
                          isFullscreen ? 'bg-white' : 'rounded-md'
                        )}
                        title={iframeTabs[activeTab].url}>
                        <div className='flex items-center'>
                          <div className='truncate'>{iframeTabs[tab].url}</div>
                          <div>
                            <Tooltip content='Copy to clipboard'>
                              <Button
                                variant='subtle'
                                size='md'
                                shape='square'
                                icon='CopyOutlineIcon'
                                onClick={() => copyToClipboard(iframeTabs[tab].url)}
                              />
                            </Tooltip>
                          </div>
                        </div>

                        <div>
                          <Button
                            variant='subtle'
                            size='md'
                            shape='square'
                            icon='FullScreenIcon'
                            onClick={() => setIsFullscreen((prev) => !prev)}
                          />
                        </div>
                      </div>
                      <div className='h-full'>
                        <iframe
                          title={iframeTabs[tab].url}
                          src={`${protocol}${iframeTabs[tab].url}`}
                          className={classNames(
                            'w-full',
                            isFullscreen
                              ? 'h-[calc(100vh-50px)] rounded-none'
                              : `rounded-md border border-solid border-gray-200`
                          )}
                          style={isFullscreen ? {} : { height: `${height * 30 + 40}px` }}
                        />
                      </div>
                    </div>
                  </Portal>
                )}
              </Tab>
            )
          })}
        </Tabs>
        {embed?.options?.length ? (
          <div className='absolute top-2.5 right-2.5 z-10'>
            <Dropdown
              width='250px'
              name='Options'
              placeholder='Options'
              maxHeight='250px'
              options={embed?.options?.map((option) => ({
                value: option.name,
                key:
                  (option.baseVariableId
                    ? (wizardInitialBRVs?.find((bv) => bv.baseVariableId === option.baseVariableId)
                        ?.value as string)
                    : option.url) ?? '',
              }))}
              onChange={({ key, value }) => {
                const tabId = uuid()
                setIframeTabs((prev) => ({
                  ...prev,
                  [tabId]: { url: key, tabName: value as string },
                }))
              }}
            />
          </div>
        ) : null}
      </div>
    </Container>
  )
}
