import { datadogRum } from '@datadog/browser-rum'
import { useSession } from '@invisible/authentication/client'
import { getUUIDFromNamespace } from '@invisible/common/helpers'
import { TIdleStatus, useStore } from '@invisible/common/stores/process-store'
import {
  fromGlobalId,
  toGlobalId,
  useCheckIdleTimeoutOnReloadMutation,
  useIdleCheckMutation,
  useStepRunSnoozeOnReconnectMutation,
  useStepRunSnoozeV2Mutation,
} from '@invisible/concorde/gql-client'
import { IStepRunEventTypeEnum } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useContext } from '@invisible/trpc/client'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useGate } from 'statsig-react'
import { validate as isValidUUID } from 'uuid'
import shallow from 'zustand/shallow'

import {
  TOKEN_NAMES,
  useStepRunEventLogger,
} from '../../../../../common/components/process-base/src/lib/hooks/useStepRunEventLogger'
import { useActiveProcessIds } from '../use-active-process-ids'
import { useOnlineStatus } from '../use-online-status'
import {
  AUTO_SNOOZE_STOP_REASON,
  FORCE_AUTO_SNOOZE_TIMER,
  IDLE_CHECK_INTERVAL,
  IDLE_CHECK_TRIGGER_TIMER,
  LOCAL_STORAGE_KEYS,
  OFFLINE_TIMER,
} from './constants'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

const resetUserActivityStorage = () => {
  if (!window || typeof window === 'undefined') return
  logger.info('useUserActivity: Resetting user activity state storage')
  localStorage.setItem(LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY, JSON.stringify(null))
  localStorage.setItem(LOCAL_STORAGE_KEYS.OFFLINE_MODAL, JSON.stringify(false))
  resetIdleCheckStateStorage()
}

const resetIdleCheckStateStorage = () => {
  if (!window || typeof window === 'undefined') return
  logger.info('useUserActivity: Resetting idle check state storage')
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.IDLE_CHECK,
    JSON.stringify({
      isModalOpen: false,
      isAutoSnoozed: false,
      stepRunId: null,
      stepName: null,
      timeoutTimestamp: null,
      isNotWorkingOnConfiguredStep: false,
    })
  )
}

const logUserActivityLocalStorageKeys = (source: string) => {
  try {
    const storedIdleCheck = localStorage.getItem(LOCAL_STORAGE_KEYS.IDLE_CHECK)

    const localStorageObject = {
      [LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY]: localStorage.getItem(
        LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY
      ),
      [LOCAL_STORAGE_KEYS.IDLE_CHECK]: storedIdleCheck
        ? (JSON.parse(storedIdleCheck) as TIdleStatus)
        : null,
      [LOCAL_STORAGE_KEYS.OFFLINE_MODAL]: localStorage.getItem(LOCAL_STORAGE_KEYS.OFFLINE_MODAL),
      [LOCAL_STORAGE_KEYS.LEADER_HEARTBEAT]: localStorage.getItem(
        LOCAL_STORAGE_KEYS.LEADER_HEARTBEAT
      ),
      [LOCAL_STORAGE_KEYS.LAST_ONLINE]: localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_ONLINE),
    }
    logger.info(`Logging from: ${source} - User Activity Local Storage: `, localStorageObject)
  } catch (error) {
    logger.error('Unable to log user activity local storage keys', { error })
  }
}

const useUserActivity = () => {
  const { data: session, status } = useSession()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { value: enableOnlineStatus } = useGate('enable-online-status-check')
  const { isOnline } = useOnlineStatus()

  const { maybeLogStepRunEvent } = useStepRunEventLogger()

  const { mutateAsync: concordeStepRunSnoozeOnReconnect } = useStepRunSnoozeOnReconnectMutation({
    onSuccess: (data) => {
      if (data.stepRunSnoozeOnReconnect?.__typename === 'GraphQLErrorType') {
        logger.warn('Auto-snooze: Failed to snooze step run on reconnect')
        return
      }

      logger.info(`Auto-snooze: useStepRunSnoozeOnReconnectMutation success`, data)

      if (data.stepRunSnoozeOnReconnect?.id) {
        datadogRum.addAction('Step run snoozed on reconnect', {
          stepRunId: fromGlobalId(data.stepRunSnoozeOnReconnect.id),
        })

        const body = {
          isModalOpen: true,
          isAutoSnoozed: true,
          stepRunId: fromGlobalId(data.stepRunSnoozeOnReconnect.id),
          processId: fromGlobalId(data.stepRunSnoozeOnReconnect.step?.process?.id),
          stepName: data.stepRunSnoozeOnReconnect.step?.name,
          timeoutTimestamp: null,
          isNotWorkingOnConfiguredStep: true,
          isSnoozedOnReconnect: true,
        }
        localStorage.setItem(LOCAL_STORAGE_KEYS.IDLE_CHECK, JSON.stringify(body))
        window.dispatchEvent(new Event('storage'))
      }
    },
    onError: (error: any) =>
      logger.error(`Auto-snooze: useStepRunSnoozeOnReconnectMutation error`, error),
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const { mutateAsync: concordeStepRunSnoozeV2 } = useStepRunSnoozeV2Mutation({
    onSuccess: (data) => {
      if (data.stepRunSnoozeV2?.__typename === 'GraphQLErrorType') {
        logger.warn('Auto-snooze: Failed to snooze step run')
        return
      }

      logger.info(`Auto-snooze: useStepRunSnoozeV2Mutation success`, data)

      datadogRum.addAction('Step run auto-snoozed', {
        stepRunId: fromGlobalId(data.stepRunSnoozeV2?.id),
      })
    },
    onError: (error: any) => logger.error(`Auto-snooze: useStepRunSnoozeV2Mutation error`, error),
    onSettled: () => {
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryClient.invalidateQueries('StepRunWithTimeLogDuration')
    },
  })

  const { mutateAsync: concordeIdleCheckOnReload } = useCheckIdleTimeoutOnReloadMutation({
    onSuccess: (data) => {
      if (data.checkIdleTimeoutOnReload?.__typename === 'GraphQLErrorType') {
        logger.warn('Auto-snooze: Failed to check user idle status on reload')
        return
      }

      logger.info(`Auto-snooze: useCheckIdleTimeoutOnReloadMutation success`, data)

      if (data.checkIdleTimeoutOnReload?.id) {
        datadogRum.addAction('Step run snoozed on idle timeout reload', {
          stepRunId: fromGlobalId(data.checkIdleTimeoutOnReload.id),
        })

        const body = {
          isModalOpen: true,
          isAutoSnoozed: true,
          stepRunId: fromGlobalId(data.checkIdleTimeoutOnReload.id),
          processId: fromGlobalId(data.checkIdleTimeoutOnReload.step?.process?.id),
          stepName: data.checkIdleTimeoutOnReload.step?.name,
          timeoutTimestamp: null,
          isNotWorkingOnConfiguredStep: true,
          isSnoozedOnReconnect: false,
        }
        localStorage.setItem(LOCAL_STORAGE_KEYS.IDLE_CHECK, JSON.stringify(body))
        window.dispatchEvent(new Event('storage'))
      }
    },
    onError: (error: any) =>
      logger.error(`Auto-snooze: useCheckIdleTimeoutOnReloadMutation error`, error),
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryClient.invalidateQueries('UserActiveWorkingProcessIDs')
    },
  })

  const { mutateAsync: concordeIdleCheck } = useIdleCheckMutation({
    onSuccess: (data) => {
      if (data.checkIdleTimeout?.__typename === 'GraphQLErrorType') {
        logger.warn('Auto-snooze: Failed to check user idle status')
        return
      }

      logger.info(`Auto-snooze: useIdleCheckMutation success`, data)

      datadogRum.addAction('Step run idle timeout fetched', {
        stepRunId: fromGlobalId(data.checkIdleTimeout?.stepRun.id),
      })

      const body = {
        isModalOpen: false,
        isAutoSnoozed: false,
        stepRunId: data?.checkIdleTimeout ? fromGlobalId(data.checkIdleTimeout.stepRun?.id) : null,
        processId: data?.checkIdleTimeout
          ? fromGlobalId(data.checkIdleTimeout.stepRun?.step?.process?.id)
          : null,
        stepName: data.checkIdleTimeout ? data.checkIdleTimeout.stepRun?.step?.name : null,
        timeoutTimestamp: data.checkIdleTimeout ? data?.checkIdleTimeout.timeoutTimestamp : null,
        isNotWorkingOnConfiguredStep: true,
        isSnoozeRequestInProgress: false,
      }
      // idleCheck.timeoutTimestamp defines when the idle warning modal should be displayed
      localStorage.setItem(LOCAL_STORAGE_KEYS.IDLE_CHECK, JSON.stringify(body))
      window.dispatchEvent(new Event('storage'))
    },
    onError: (error: any) => logger.error(`Auto-snooze: useIdleCheckMutation error`, error),
  })

  const { data: userData } = useActiveProcessIds()

  const activeProcessIDs = useMemo(
    () => userData?.me?.activeWorkingProcessIds?.map((id) => fromGlobalId(id)) ?? [],
    [userData]
  )

  const [lastActivityDate, setLastActivityDate] = useState(new Date())
  const [isNewTab, setIsNewTab] = useState(true)
  const { setIdleStatus } = useStore(
    useCallback(
      (state) => ({
        setIdleStatus: state.setIdleStatus,
      }),
      []
    ),
    shallow
  )

  const getStorageIdleCheck = (): TIdleStatus | null => {
    const storedIdleCheck = localStorage.getItem(LOCAL_STORAGE_KEYS.IDLE_CHECK)

    try {
      return storedIdleCheck ? (JSON.parse(storedIdleCheck) as TIdleStatus) : null
    } catch {
      return null
    }
  }

  const updateStorageIdleCheck = (body: TIdleStatus) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.IDLE_CHECK, JSON.stringify(body))
    window.dispatchEvent(new Event('storage'))
    setIdleStatus(body)
  }

  const getStorageOfflineStatus = () => {
    const storedOfflineModal = localStorage.getItem(LOCAL_STORAGE_KEYS.OFFLINE_MODAL)
    const storedLastOnline = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_ONLINE)

    return {
      offlineModal: storedOfflineModal ? (JSON.parse(storedOfflineModal) as boolean) : null,
      lastOnline: storedLastOnline ? new Date(storedLastOnline) : null,
    }
  }

  const getStorageBrowserActivity = (): string | null => {
    const storedBrowserActivity = localStorage.getItem(LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY)

    if (!storedBrowserActivity) return null

    try {
      // If it's a valid date string, return it as-is (used during migration phase)
      if (!isNaN(Date.parse(storedBrowserActivity))) {
        return storedBrowserActivity
      }

      // Attempt to parse as JSON and check if it's a valid date
      const parsedActivity = JSON.parse(storedBrowserActivity)
      return parsedActivity && !isNaN(Date.parse(parsedActivity)) ? parsedActivity : null
    } catch {
      return null
    }
  }

  const checkIfOnline = () => (enableOnlineStatus ? isOnline : navigator.onLine)

  const handleUserBackOnline = () => {
    const idleCheck = getStorageIdleCheck()

    if (!idleCheck?.isModalOpen)
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY,
        JSON.stringify(lastActivityDate.toISOString())
      )

    const { offlineModal, lastOnline } = getStorageOfflineStatus()
    let userLastOnline = new Date()

    if (lastOnline) {
      // Remove lastOnline so it resets the offline time
      localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_ONLINE)
      userLastOnline = new Date(lastOnline)
    }

    // If offline modal was being displayed, need to run snooze on reconnect
    // This will ensure any active configured step run gets auto-snooze at the time of disconnect
    if (offlineModal) {
      logUserActivityLocalStorageKeys('concordeStepRunSnoozeOnReconnect')
      concordeStepRunSnoozeOnReconnect({ lastOnlineDate: userLastOnline })

      localStorage.setItem(LOCAL_STORAGE_KEYS.OFFLINE_MODAL, JSON.stringify(false))
      window.dispatchEvent(new Event('storage'))
    }
  }

  const isInApp =
    window.location.href.includes('app.inv.tech') || window.location.href.includes('app-sta.invsta')

  useInterval(async () => {
    if (!isInApp) return

    // This is to prevent unauthenticated users from making the idle check request
    if (!session) {
      logger.info('useUserActivity: No user session found', { status })
      return
    }

    const idleCheck = getStorageIdleCheck()
    if (!idleCheck) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.IDLE_CHECK,
        JSON.stringify({
          isModalOpen: false,
          isAutoSnoozed: false,
          stepRunId: null,
          stepName: null,
          timeoutTimestamp: null,
          isNotWorkingOnConfiguredStep: false,
          isSnoozedOnReconnect: false,
        })
      )
      return
    }

    // Reset idle check local storage state if the current combination of auto-snooze, modal state,
    // and stepRunId validity requires reinitialization.
    if (
      (idleCheck.isAutoSnoozed &&
        idleCheck.isModalOpen &&
        !isValidUUID(idleCheck.stepRunId as string)) ||
      (idleCheck.isAutoSnoozed &&
        !idleCheck.isModalOpen &&
        isValidUUID(idleCheck.stepRunId as string)) ||
      (idleCheck.isAutoSnoozed &&
        !idleCheck.isModalOpen &&
        !isValidUUID(idleCheck.stepRunId as string)) ||
      (!idleCheck.isAutoSnoozed &&
        idleCheck.isModalOpen &&
        !isValidUUID(idleCheck.stepRunId as string))
    ) {
      resetIdleCheckStateStorage()
      return
    }

    if (idleCheck?.isAutoSnoozed) return

    const storedBrowserActivity = getStorageBrowserActivity()
    if (!storedBrowserActivity) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY,
        JSON.stringify(new Date().toISOString())
      )
      return
    }

    const browserActivity = new Date(storedBrowserActivity)

    // Run once per tab on load
    // If user is coming back to a running task, they'll be auto snoozed
    if (isNewTab && checkIfOnline()) {
      setIsNewTab(false)
      logUserActivityLocalStorageKeys('concordeIdleCheckOnReload')
      concordeIdleCheckOnReload({ lastActivityTime: browserActivity })
    }

    // If the leader heartbeat is not updated, the leader is dead

    const storedLeaderHeartbeat = localStorage.getItem(LOCAL_STORAGE_KEYS.LEADER_HEARTBEAT)
    if (storedLeaderHeartbeat) {
      const leaderHeartbeat = new Date(JSON.parse(storedLeaderHeartbeat))

      if (leaderHeartbeat.getTime() + IDLE_CHECK_INTERVAL * 10 < new Date().getTime()) {
        const storedBrowserActivity = getStorageBrowserActivity()
        if (!storedBrowserActivity) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.BROWSER_ACTIVITY,
            JSON.stringify(new Date().toISOString())
          )
          return
        }

        const browserActivity = new Date(storedBrowserActivity)
        // If the leader is dead, the user is now the leader
        // Set the current tab activity time as the leader heartbeat
        setLastActivityDate(browserActivity)
      }
    }

    if (lastActivityDate.getTime() < browserActivity.getTime()) return

    // Leader sets a heartbeat on local storage
    // If the heartbeat is not updated, the leader is dead
    localStorage.setItem(LOCAL_STORAGE_KEYS.LEADER_HEARTBEAT, JSON.stringify(new Date().getTime()))

    if (!checkIfOnline()) {
      // Offline handler
      const { offlineModal, lastOnline } = getStorageOfflineStatus()

      if (offlineModal) return

      // If last online has not been set yet
      // This is the first interval the user has been offline
      if (!lastOnline) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ONLINE, new Date().toISOString())
        window.dispatchEvent(new Event('storage'))
        return
      }

      // Show the offline modal if user has been offline for more than OFFLINE_TIMER and has active tasks
      if (
        new Date().getTime() > lastOnline.getTime() + OFFLINE_TIMER &&
        activeProcessIDs.length > 0
      ) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.OFFLINE_MODAL, JSON.stringify(true))
        window.dispatchEvent(new Event('storage'))
        return
      }
    } else {
      // Online handler
      handleUserBackOnline()
    }

    // Force-snooze and idle warning modals
    if (idleCheck?.timeoutTimestamp && idleCheck?.stepRunId) {
      const warningTimeout = new Date(idleCheck.timeoutTimestamp * 1000)
      // Force-snooze
      // 2 minutes (FORCE_AUTO_SNOOZE_TIMER) after showing the first modal, should force-snooze tasks
      if (
        idleCheck?.timeoutTimestamp &&
        warningTimeout.getTime() + FORCE_AUTO_SNOOZE_TIMER < new Date().getTime()
      ) {
        if (!isValidUUID(idleCheck?.stepRunId) || idleCheck?.isSnoozeRequestInProgress) return
        updateStorageIdleCheck({
          ...idleCheck,
          isSnoozeRequestInProgress: true,
        })
        const secondsSinceLastActivity = Math.floor((Date.now() - browserActivity.getTime()) / 1000)

        maybeLogStepRunEvent({
          name: 'auto_snoozing_step_run',
          spanId: getUUIDFromNamespace([idleCheck?.stepRunId, TOKEN_NAMES.SNOOZE_ACTIVITY]),
          stepRunId: idleCheck?.stepRunId,
          spanType: 'SNOOZE_ACTIVITY',
          type: IStepRunEventTypeEnum.Span,
          timestamp: browserActivity,
        })

        logUserActivityLocalStorageKeys('concordeStepRunSnoozeV2')

        concordeStepRunSnoozeV2({
          id: toGlobalId('StepRun', idleCheck?.stepRunId),
          stopReason: AUTO_SNOOZE_STOP_REASON,
          secondsSinceLastActivity,
        })
          .then((data) => {
            if (data.stepRunSnoozeV2?.__typename === 'GraphQLErrorType') {
              logger.warn('Auto-snooze: Failed to force-snooze step run')
              return
            }
            updateStorageIdleCheck({
              isModalOpen: true,
              isAutoSnoozed: true,
              stepRunId: idleCheck?.stepRunId,
              stepName: idleCheck?.stepName,
              processId: idleCheck?.processId,
              timeoutTimestamp: idleCheck?.timeoutTimestamp,
              isNotWorkingOnConfiguredStep: idleCheck?.isNotWorkingOnConfiguredStep ?? false,
              isSnoozeRequestInProgress: false,
              isSnoozedOnReconnect: idleCheck?.isSnoozedOnReconnect ?? false,
            })
          })
          .catch(() => {
            updateStorageIdleCheck({
              ...idleCheck,
              isSnoozeRequestInProgress: false,
            })
          })

        return
      }

      // Idle warning modal
      if (idleCheck?.timeoutTimestamp && warningTimeout.getTime() < new Date().getTime()) {
        updateStorageIdleCheck({
          isModalOpen: true,
          isAutoSnoozed: false,
          stepRunId: idleCheck?.stepRunId ?? null,
          processId: idleCheck?.processId ?? null,
          stepName: idleCheck?.stepName ?? null,
          timeoutTimestamp: idleCheck?.timeoutTimestamp ?? null,
          isNotWorkingOnConfiguredStep: idleCheck?.isNotWorkingOnConfiguredStep ?? false,
          isSnoozeRequestInProgress: false,
        })
      }
    }

    // If modal is open, idle timeout is already set
    if (idleCheck?.isModalOpen) return

    // Activity happened before the idle check trigger timer
    // Reset the idle status and return
    if (lastActivityDate.getTime() + IDLE_CHECK_TRIGGER_TIMER > new Date().getTime()) {
      updateStorageIdleCheck({
        isModalOpen: false,
        isAutoSnoozed: false,
        stepRunId: null,
        stepName: null,
        timeoutTimestamp: null,
        isNotWorkingOnConfiguredStep: false,
        isSnoozedOnReconnect: false,
      })
      return
    }

    // Getting here means this is the lead tab and the user is afk
    if (idleCheck?.isNotWorkingOnConfiguredStep) return

    // It will now request the server to check if the user is working on a step with idle check configured

    logUserActivityLocalStorageKeys('concordeIdleCheck')

    // Concorde returns the timestamp for when the idle warning modal should be displayed
    concordeIdleCheck({ lastActivityTime: lastActivityDate }).catch((err) => {
      logger.error('Auto-snooze: Failed to check timeout', { err })
    })
  }, IDLE_CHECK_INTERVAL)

  // Runs once on component mount so idle modal state is consistent
  useEffect(() => {
    logUserActivityLocalStorageKeys('useUserActivity component mount')

    const storedIdleCheck = localStorage.getItem('idle-check')
    const idleCheck = storedIdleCheck ? JSON.parse(storedIdleCheck) : {}

    if (!idleCheck) return

    setIdleStatus({
      isModalOpen: idleCheck?.isModalOpen ?? false,
      isAutoSnoozed: idleCheck?.isAutoSnoozed ?? false,
      stepRunId: idleCheck?.stepRunId ?? null,
      stepName: idleCheck?.stepName ?? null,
      timeoutTimestamp: idleCheck?.timeoutTimestamp ?? null,
      isNotWorkingOnConfiguredStep: idleCheck?.timeoutTimestamp ? true : false,
      isSnoozedOnReconnect: idleCheck?.isSnoozedOnReconnect ?? false,
    })
  }, [])

  // log local storage objects if navigator.onLine changes
  const handleOnlineStatusChange = () => {
    logUserActivityLocalStorageKeys(
      `navigator.onLine onChange (current value is: ${navigator.onLine})`
    )
  }

  const handleUserActivityDate = () => {
    setLastActivityDate(new Date())
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleUserActivityDate)
    document.addEventListener('scroll', handleUserActivityDate)
    document.addEventListener('click', handleUserActivityDate)
    document.addEventListener('keydown', handleUserActivityDate)

    // Add event listeners for online and offline changes
    window.addEventListener('online', handleOnlineStatusChange)
    window.addEventListener('offline', handleOnlineStatusChange)

    return () => {
      document.removeEventListener('mousemove', handleUserActivityDate)
      document.removeEventListener('scroll', handleUserActivityDate)
      document.removeEventListener('click', handleUserActivityDate)
      document.removeEventListener('keydown', handleUserActivityDate)
      window.removeEventListener('online', handleOnlineStatusChange)
      window.removeEventListener('offline', handleOnlineStatusChange)
    }
  }, [])

  return { handleUserBackOnline }
}

export { resetUserActivityStorage, useUserActivity }
