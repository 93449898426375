import {
  fromGlobalId,
  IAccountsUserStatusEnum,
  ILoggedInUserQuery,
  ITermsOfServiceType,
  useLoggedInUserQuery,
} from '@invisible/concorde/gql-client'
import { useMemo } from 'react'
import { UseQueryOptions } from 'react-query'

const DEFAULT_OPTIONS = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchInterval: 15 * 1000 * 60, // 15min
  keepPreviousData: true,
} as const

interface StandardizedResult {
  id: string
  email: string
  name: string | null
  image: string | null
  preferredName: string | null
  legalName: string | null
  timeZone: string | null
  status: IAccountsUserStatusEnum
  isIdentityVerified: boolean
  isCancelledTos: boolean
  tosTicketSubmitted: boolean
  birthday: string | null
  physicalAddress: {
    isVerified: boolean
    country: string
    state: string | null
    addressLineOne: string | null
    addressLineTwo: string | null
    city: string | null
    postalCode: string | null
  }
  agent: {
    legalName: string
    agreementType: string
    type: string
    taxStatus?: string
  }
  verificationStatuses: {
    isIdVerified: boolean
    isIdVerificationComplete: boolean
    isAddressVerified: boolean
    isAddressVerificationComplete: boolean
    addressVerificationStatus?: string
    idVerificationStatus?: string
    idVerificationFailReason?: string
    addressVerificationFailReason?: string
  }
  tosToAcknowledge?: ITermsOfServiceType
  hasAcknowledgedTosEarlier: boolean
  acknowledgedTos?: ITermsOfServiceType | null
  taxIdPbKey?: string | null
  isTaxIdObtained?: boolean
}

const standardizeGraphQLResult = (data?: ILoggedInUserQuery) => {
  if (!data) return
  return {
    id: fromGlobalId(data.me.id),
    email: data.me.email,
    name: data.me.name ?? null,
    image: data.me.image ?? null,
    preferredName: data.me.preferredName ?? null,
    birthday: data.me.birthday ?? null,
    timeZone: data.me.timezone ?? null,
    status: data.me.status,
    tosToAcknowledge: data.me.tosToAcknowledge,
    acknowledgedTos: data.me.acknowledgedTos,
    physicalAddress: {
      isVerified: data.me.physicalAddress?.isVerified ?? false,
      country: data.me.physicalAddress?.country,
      state: data.me.physicalAddress?.state ?? null,
      addressLineOne: data.me.physicalAddress?.addressLineOne ?? null,
      addressLineTwo: data.me.physicalAddress?.addressLineTwo ?? null,
      city: data.me.physicalAddress?.city ?? null,
      postalCode: data.me.physicalAddress?.postalCode ?? null,
    },
    agent: {
      legalName: data.me.agent?.legalName ?? '',
      agreementType: data.me.agent?.agreementType ?? '',
      type: data.me.agent?.type ?? '',
      taxStatus: data.me.agent?.taxStatus ?? '',
    },
    verificationStatuses: {
      isIdVerified: data.me.verificationStatuses?.isIdVerified ?? null,
      isIdVerificationComplete: data.me.verificationStatuses?.isIdVerificationComplete ?? null,
      isAddressVerified: data.me.verificationStatuses?.isAddressVerified ?? null,
      isAddressVerificationComplete:
        data.me.verificationStatuses?.isAddressVerificationComplete ?? null,
      addressVerificationStatus: data.me.verificationStatuses?.addressVerificationStatus ?? null,
      idVerificationStatus: data.me.verificationStatuses?.idVerificationStatus ?? null,
      idVerificationFailReason: data.me.verificationStatuses?.idVerificationFailReason ?? null,
      addressVerificationFailReason:
        data.me.verificationStatuses?.addressVerificationFailReason ?? null,
    },
    isIdentityVerified: data.me.isIdentityVerified,
    isCancelledTos: data.me.isTosCancelled,
    tosTicketSubmitted: data.me.tosTicketSubmitted,
    hasAcknowledgedTosEarlier: data.me.hasAcknowledgedTosEarlier,
    taxIdPbKey: data.me.taxIdPbKey ?? null,
    isTaxIdObtained: data.me.isTaxIdObtained ?? false,
  } as StandardizedResult
}

export type TLoggedInUser = ReturnType<typeof standardizeGraphQLResult>

export const useLoggedInUser = (options?: UseQueryOptions<ILoggedInUserQuery>) => {
  const { data: graphqlResult, isLoading: isLoadingGraphQL } = useLoggedInUserQuery(
    {},
    {
      ...DEFAULT_OPTIONS,
      ...options,
    }
  )

  const [loggedInUser, isLoading] = useMemo(() => {
    const loggedInUser = standardizeGraphQLResult(graphqlResult)

    return [loggedInUser, isLoadingGraphQL] as const
  }, [isLoadingGraphQL, graphqlResult])

  return [loggedInUser, isLoading] as const
}
