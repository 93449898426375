import { NextPage } from 'next'
import { NextAdapter } from 'next-query-params'
import { memo, ReactNode } from 'react'
import { decodeJson, encodeJson, QueryParamProvider as ContextProvider } from 'use-query-params'

// Custom Provider for next.js support. see: https://github.com/pbeshai/use-query-params/issues/13
const QueryParamProviderComponent = (props: { children?: ReactNode }) => (
  // enableBatching: true fixes the following issue: https://github.com/pbeshai/use-query-params/issues/233

  <ContextProvider adapter={NextAdapter} options={{ enableBatching: true }}>
    {props.children}
  </ContextProvider>
)

const JSONArrayParam = {
  encode: (array: unknown[]) => encodeJson(array),
  decode: (arrayStr: string | (string | null)[] | null | undefined) => decodeJson(arrayStr),
}

const CustomQueryParamProvider = memo(QueryParamProviderComponent)

const withQueryParamProvider = (Page: NextPage) =>
  function withQueryParamProvider(props: any) {
    return (
      <QueryParamProviderComponent>
        <Page {...props} />
      </QueryParamProviderComponent>
    )
  }

export {
  CustomQueryParamProvider,
  JSONArrayParam,
  QueryParamProviderComponent,
  withQueryParamProvider,
}
