import React from 'react'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import { PluggableList } from 'unified'

import {
  BLOCK_MATH_END,
  BLOCK_MATH_START,
  getMathParts,
  INLINE_MATH_END,
  INLINE_MATH_START,
} from './Katex'
import CustomMarkdown from './Markdown'

interface IMarkdownWithLatexProps {
  content: string
  dir?: string
  className?: string
}

const MarkdownWithLatex = ({ content, dir = 'auto', className }: IMarkdownWithLatexProps) => {
  // Split content by block math expressions
  const parts = getMathParts(content)

  // Reprocess parts to replace math expressions with LaTeX delimiters
  const reprocessedParts = parts.map((part) => {
    if (part.match(BLOCK_MATH_START) && part.match(BLOCK_MATH_END)) {
      // Replace block math with $$
      const math = part.replace(BLOCK_MATH_START, '$$').replace(BLOCK_MATH_END, '$$')
      return math
    } else if (part.match(INLINE_MATH_START) && part.match(INLINE_MATH_END)) {
      // Replace inline math with $
      const math = part.replace(INLINE_MATH_START, '$').replace(INLINE_MATH_END, '$')
      return math
    } else {
      return part
    }
  })

  return (
    <div dir={dir} className={className}>
      <CustomMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex] as PluggableList}>
        {reprocessedParts.join('')}
      </CustomMarkdown>
    </div>
  )
}

export default MarkdownWithLatex
