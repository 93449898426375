import { IUserActivityEnum, useUserActivityCreateMutation } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { differenceInMilliseconds } from 'date-fns/fp'
import { debounce } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useInterval } from 'react-use'

import { ACTIVITY_STATUS_TIMEOUT, ONLINE_CHECK_INTERVAL } from './constants'

function useUserActive() {
  const [isActive, setIsActive] = useState(true)
  const [lastActivityDate, setLastActivityDate] = useState(new Date())

  const { mutateAsync: concordeCreateUserActivity } = useUserActivityCreateMutation({
    onError: (error: { message: string }) => {
      logger.error(`Error creating user activity: ${error.message}`)
    },
  })

  const handleUserActivity = debounce(100, () => {
    setIsActive(true)
    setLastActivityDate(new Date())
  })

  useEffect(() => {
    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('scroll', handleUserActivity)
    document.addEventListener('click', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)

    return () => {
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('scroll', handleUserActivity)
      document.removeEventListener('click', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
    }
  }, [])

  useEffect(() => {
    concordeCreateUserActivity({
      activityType: isActive ? IUserActivityEnum.Online : IUserActivityEnum.Offline,
    })
  }, [isActive])

  // Interval request to determine if user should display Active status in platform
  useInterval(() => {
    const isStillActive =
      differenceInMilliseconds(lastActivityDate, new Date()) < ACTIVITY_STATUS_TIMEOUT
    setIsActive(() => isStillActive)
  }, ACTIVITY_STATUS_TIMEOUT)

  // Check-in to the server every 5 minutes if the user is online
  useInterval(() => {
    if (isActive) {
      concordeCreateUserActivity({
        activityType: IUserActivityEnum.Online,
      })
    }
  }, ONLINE_CHECK_INTERVAL)

  return { isActive }
}

export { useUserActive }
