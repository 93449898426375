import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import * as React from 'react'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

type EditMultiSelectProps = {
  options: string[]
  readOnly: boolean
} & GridRenderEditCellParams<any, string>

export const EditMultiSelect = ({ options, readOnly, ...props }: EditMultiSelectProps) => {
  const { id, field, value } = props
  const [valueState, setValueState] = React.useState<string[]>((value ?? []) as string[])
  const apiRef = useGridApiContext()
  const handleChange = (value: string[]) => {
    setValueState(value)
    apiRef.current.setEditCellValue({ id, field, value, debounceMs: 200 })
  }

  return (
    <Autocomplete
      fullWidth
      multiple
      disableCloseOnSelect
      openOnFocus={true}
      value={valueState}
      options={options ?? []}
      onChange={(_, newValue) => !readOnly && handleChange(newValue)}
      renderOption={(props, option, { selected }) => {
        const { ...optionProps } = props
        return (
          <li key={option} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard' // Keeps the input compact
          autoFocus
          disabled={readOnly}
          sx={{
            '& .MuiInputBase-root': {
              maxHeight: '50px',
              overflowY: 'auto',
            },
            '& .Mui-focused': {
              '& .MuiAutocomplete-endAdornment': {
                alignItems: 'center',
                justifyContent: 'center',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
              },
            },
          }}
        />
      )}
      disabled={readOnly}
    />
  )
}
