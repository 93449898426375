import { classNames } from '@invisible/common/helpers'
import { InfoFilledIcon } from '@invisible/ui/icons'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'

import {
  BooleanField,
  CodeField,
  DatePicker,
  Dropdown,
  FileInput,
  InputField,
  LoomVideo,
  MultiSelect,
  NumericField,
  RadioButton,
  RichText,
  TextArea,
  TextField,
  URLField,
} from './form-fields'

// let's make this a Map()
const formFields = {
  boolean: BooleanField,
  code: CodeField,
  date: DatePicker,
  datetime: DatePicker,
  dropdown: Dropdown,
  file: FileInput,
  input: InputField,
  loomvideo: LoomVideo,
  multilinestring: TextArea,
  multiselect: MultiSelect,
  number: NumericField,
  radio: RadioButton,
  richtext: RichText,
  scale: TextArea,
  yesno: TextArea,
  string: TextField,
  url: URLField,
  note: null,
}

type FormValues = Record<string, string | number | boolean | string[] | Date | null | undefined>

// type FormFieldProps = {
//   field: WizardSchemas.FormField.TSchema & {
//     type: keyof typeof formFields
//     baseVariableId: string
//   }
//   formValues: FormValues
// }

type FormFieldProps = {
  field: WizardSchemas.FormField.TSchema
}

const FormField = ({ field }: FormFieldProps) => {
  const FieldComponent = formFields[field.type as keyof typeof formFields] as React.ComponentType<{
    field: typeof field
  }>

  if (!FieldComponent || !field.baseVariableId) {
    return null
  }

  return (
    <div className='mb-5 flex items-center'>
      {field.type === 'note' ? (
        <div className='flex items-center gap-1 text-gray-400'>
          <InfoFilledIcon />
          <span>{field.note}</span>
        </div>
      ) : (
        <div
          className={classNames(
            'w-full',
            field.type === 'boolean' ? 'flex flex-row-reverse items-center justify-end gap-3' : ''
          )}>
          <div className='shrink-0'>
            <FieldComponent field={field} />
          </div>
        </div>
      )}
    </div>
  )
}

export { FormField }
