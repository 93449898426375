import { datadogRum } from '@datadog/browser-rum'
import { ConditionalForm, TFormValues } from '@invisible/common/components/conditional-form'
import { DynamicForm } from '@invisible/common/components/manual-steps'
import { useProcessById } from '@invisible/common/components/process-base'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { useTosSowState } from '@invisible/common/providers'
import { useStore } from '@invisible/common/stores/process-store'
import {
  fromGlobalId,
  getErrorMessage,
  IStepRunType,
  toGlobalId,
  useGetOrCreateStatementOfWorkMutation,
  useStepRunStartV2Mutation,
} from '@invisible/concorde/gql-client'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext } from '@invisible/trpc/client'
import { SmallCheckbox } from '@invisible/ui/form'
import {
  BASE_ID_ARGS,
  BASE_VIEW_ID_ARGS,
  useQueryParam,
} from '@invisible/ui/hooks/use-query-params'
import { resetUserActivityStorage } from '@invisible/ui/hooks/use-user-activity'
import { InfoOutlineIcon } from '@invisible/ui/icons'
import { theme } from '@invisible/ui/mui-theme-v2'
import { Text } from '@invisible/ui/text'
import { Tooltip } from '@invisible/ui/tooltip'
import type { PrismaAll } from '@invisible/ultron/prisma'
import { ATTENDED_MAP_STEP_TEMPLATE_ID } from '@invisible/ultron/shared'
import type { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'
import { differenceInSeconds } from 'date-fns'
import { compact, isEmpty, isEqual, keys, map, size } from 'lodash/fp'
import React, {
  forwardRef,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { useGate } from 'statsig-react'
import shallow from 'zustand/shallow'

import { DEFAULT_ITEMS_PER_PAGE } from '../common/constants'
import {
  handleSetUserLifecycleStage,
  handleStepRunUpdatesOnLooopResourceStatus,
} from '../common/helpers'
import { validateForm } from '../common/tempValidators/uglyDeleteMe'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import usePollAutoAssignment from '../hooks/usePollAutoAssignment'
import { useSubmitWizardForm } from '../hooks/useSubmitWizardForm'
import { useSelectedEmbedLink } from '../providers/SelectedEmbedLinkProvider'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TStep = TStepRun['step']
type TBaseRunVariable = TBaseRun['baseRunVariables'][number]
type TFindAssignedToMeData = inferQueryOutput<'stepRun.findAssignedToMe'>

type IProps = WizardSchemas.WACConfig.TSchema & {
  baseRun: TBaseRun
  stepRun: TStepRun
  closeWizard: () => void
  isReadOnly: boolean
}

// eslint-disable-next-line react/display-name
const FormWAC = forwardRef(
  ({ form, showName, name, baseRun, stepRun, id, isReadOnly }: IProps, wizardContainerRef) => {
    const { value: isFormFieldsFixRequired } = useGate('enable-empty-required-form-fields-fix')
    const { value: enableFormValidation } = useGate('enable-form-wac-validation')

    const dispatchKey = 'FormWAC - ' + id

    const [baseId] = useQueryParam(...BASE_ID_ARGS)
    const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)
    // Used for individual base run time-tracking in create forms
    const startedAt = useRef<Date | null>(null)

    const [isSubmitting, setIsSubmitting] = useState(false)

    const reactQueryClient = useQueryClient()
    const reactQueryContext = useContext()
    const { addToast } = useToasts()
    const { dispatch: dispatchSowState } = useTosSowState()
    const { state: embedState } = useSelectedEmbedLink()
    const { state: wizardState, dispatch } = useWizardState()
    const { itemsPerPage, filterOption, sortOption, searchTerm, currentPage } = useStore(
      useCallback(
        (state) => ({
          itemsPerPage: state.itemsPerPage,
          filterOption: state.filterOption,
          sortOption: state.sortOption,
          searchTerm: state.searchTerm,
          currentPage: state.currentPage,
        }),
        []
      ),
      shallow
    )
    const [skipAutoAssign, setSkipAutoAssign] = useState(false)
    const [showReview, setShowReview] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const resetConditionalFormState = useRef<() => void>(() => void 0)

    /**
     * Determine if the step has a next step strategy that can auto assign.
     */
    const hasNextStepStrategy = useMemo(
      () =>
        (stepRun.step.meta as Record<string, any> | undefined)?.canHaveAutoAssignOnNextStep ||
        ['same_step', 'sla'].includes(
          (stepRun.step.meta as Record<string, any> | undefined)?.autoAssignStrategy?.type
        ),
      [stepRun.step.meta]
    )
    const ref = useRef<HTMLDivElement>(null)
    const formWithoutNotes = form?.fields?.filter((field) => field.type !== 'note')
    const [assignStepRunOnCompletionChecked, _setAssignStepRunOnCompletionChecked] = useState(false)

    const [formValues, setFormValues] = useState<
      Record<string, string | number | boolean | Date | null | undefined | string[]>
    >({})

    /**
     * Set the form values based on the form fields.
     * This is used to populate the form fields with the initial values from the wizardInitialBRVs.
     * If the form is a create form, then we use the default values from the form fields.
     * If the form is an update form, then we use the initial values from the wizardInitialBRVs.
     */
    useEffect(() => {
      setFormValues(
        formWithoutNotes?.reduce(
          (acc, field) => ({
            ...acc,
            [field.baseVariableId as string]:
              form?.type === 'create'
                ? field.type === 'date' || field.type === 'dropdown' || field.type === 'boolean'
                  ? null
                  : ''
                : (wizardState.wizardInitialBRVs as TBaseRunVariable[])?.find(
                    (v) => v.baseVariableId === field.baseVariableId
                  )?.value ?? (['date', 'dropdown', 'boolean'].includes(field.type) ? null : ''),
          }),
          {}
        ) ?? {}
      )
      resetConditionalFormState.current()
    }, [stepRun.id])

    /**
     * If the form is an update form, then we check if the required fields are filled in.
     * If any of the required fields are not filled in, then we block the Wizard Submit button.
     */
    useEffect(() => {
      if (form && form?.type === 'update' && form.fields) {
        form.fields.forEach((field) => {
          if (field.required) {
            const fieldValue = (wizardState.wizardInitialBRVs as TBaseRunVariable[])?.find(
              (v) => v.baseVariableId === field.baseVariableId
            )?.value

            if (fieldValue === null || fieldValue === '') {
              dispatch({
                type: 'setReadyForSubmit',
                key: dispatchKey,
                value: false,
              })
              return
            }
          }
        })
      }
    }, [wizardState.wizardInitialBRVs])

    /**
     * This ensures that we do not enable the Wizard submit button before the form is submitted.
     */
    useEffect(() => {
      // Besides this flag, this condition ensures that we do not enable the submit button before the form is submitted.
      if (!isFormFieldsFixRequired || canSubmit) return
      // Creating a new key that is unique to this form, despite the possibility that a step may have multiple forms.
      dispatch({
        type: 'setReadyForSubmit',
        key: dispatchKey,
        value: canSubmit,
      })
      return
    }, [canSubmit])

    /**
     * Get the process by id.
     */
    const { data: process } = useProcessById({
      id: wizardState.processId as string,
    })

    /**
     * Submit the form.
     */
    const { mutateAsync: submitWizard } = useSubmitWizardForm({
      onSettled: () => {
        reactQueryClient.invalidateQueries('get-base-runs')
        reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
        reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
        reactQueryContext.invalidateQueries('stepRun.findCompletedAssignedToMe')
        reactQueryContext.invalidateQueries('baseRunVariable.findWizardDataForStepRun')
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
      onSuccess: () => {
        /**
         * If the form is a create form, then we clear out the form values.
         */
        addToast(`Progress on “${name}” has been saved.`, {
          appearance: 'success',
        })

        if (form?.type === 'create') {
          setFormValues(
            formWithoutNotes?.reduce(
              (acc, field) => ({
                ...acc,
                [field.baseVariableId as string]:
                  field.type === 'date' || field.type === 'dropdown' || field.type === 'boolean'
                    ? null
                    : '',
              }),
              {}
            ) ?? {}
          )
          /**
           * Reset the conditional form state.
           */
          resetConditionalFormState.current()
          /**
           * Reset the started at time.
           */
          startedAt.current = null
          /**
           * Update the base run with the new child count.
           */
          dispatch({
            type: 'setBaseRun',
            baseRun: {
              ...baseRun,
              childCounts: {
                ...baseRun.childCounts,
                [form?.baseId as string]: (baseRun.childCounts?.[form?.baseId as string] ?? 0) + 1,
              },
            },
          })
        }

        /**
         * If the form is an update form, then we update the base run with the new child count.
         */
        if (form?.completeStepRunOnSubmit) {
          datadogRum.addAction('Step run completed', {
            stepRunId: stepRun.id,
            stepId: stepRun.step.id,
            assigneeId: stepRun.assignee?.id,
            baseRunId: stepRun.baseRunId,
          })

          // Update "my tasks" query cache
          reactQueryContext.queryClient.setQueryData<TFindAssignedToMeData | undefined>(
            ['stepRun.findAssignedToMe'],
            (prevData) => {
              if (!prevData) return

              return prevData.map((entry) =>
                entry.stepRuns.some((s) => s.id === stepRun.id)
                  ? {
                      ...entry,
                      stepRuns: entry.stepRuns.filter((s) => s.id !== stepRun.id),
                    }
                  : entry
              )
            }
          )

          /**
           * Update the "base page" query cache.
           */
          reactQueryClient.setQueryData<TBaseRunQueryData | undefined>(
            [
              'get-base-runs',
              {
                baseId,
                baseViewId,
                take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
                filters: filterOption ?? [],
                sort: sortOption ?? {},
                search: searchTerm ?? '',
                page: currentPage ?? 1,
              },
            ],
            (prevData) => {
              if (!prevData) return prevData

              const updatedBaseRunData = prevData.items.map((baseRun) => {
                if (baseRun.id === stepRun.baseRunId) {
                  return {
                    ...baseRun,
                    stepRuns: baseRun.stepRuns.map((sr) =>
                      sr.id !== stepRun.id
                        ? sr
                        : { ...sr, status: 'done' as PrismaAll.StepRunStatus }
                    ),
                  }
                }
                return baseRun
              })
              return {
                ...prevData,
                baseRuns: updatedBaseRunData,
              }
            }
          )
        }
      },
    })

    /**
     * Get or create the statement of work.
     */
    const { mutateAsync: getOrCreateSow } = useGetOrCreateStatementOfWorkMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        addToast(`Get or create SoW failed: ${errorMessage}`, {
          appearance: 'error',
        })
      },
    })

    /**
     * Start the step run.
     */
    const { mutateAsync: startStepRun } = useStepRunStartV2Mutation({
      onSuccess: (response) => {
        if (response.stepRunStartV2.__typename !== 'GraphQLErrorType') {
          const stepRunStartData = response.stepRunStartV2 as IStepRunType
          const transformedData = {
            id: fromGlobalId(stepRunStartData.id),
            stepId: fromGlobalId(stepRunStartData.step.id),
            assigneeId: fromGlobalId(stepRunStartData.assignee?.id),
            baseRunId: fromGlobalId(stepRunStartData.baseRun.id),
          }

          datadogRum.addAction('Step run started', {
            stepRunId: transformedData.id,
            stepId: transformedData.stepId,
            assigneeId: transformedData.assigneeId,
            baseRunId: transformedData.baseRunId,
          })

          reactQueryContext.queryClient.setQueryData<
            inferQueryOutput<'stepRun.findAssignedToMe'> | undefined
          >(['stepRun.findAssignedToMe'], (prevData) => {
            if (!prevData) return

            return prevData.map((p) => ({
              ...p,
              stepRuns: p.stepRuns.map((s) =>
                s.id === fromGlobalId(stepRunStartData.id) ? { ...s, status: 'running' } : s
              ),
            }))
          })
          handleSetUserLifecycleStage({
            stepId: transformedData.stepId,
            userId: transformedData.assigneeId,
          })
          handleStepRunUpdatesOnLooopResourceStatus({
            id: transformedData.id,
            stepId: transformedData.stepId,
            userId: transformedData.assigneeId,
            baseRunId: transformedData.baseRunId,
          })
        }
      },
      onSettled: () => {
        reactQueryClient.invalidateQueries('get-base-runs')
        reactQueryClient.invalidateQueries('UserActiveWorkingProcessIDs')
        reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
        resetUserActivityStorage()
      },
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        addToast(`Start failed: ${errorMessage}`, {
          appearance: 'error',
        })
      },
    })

    /**
     * Poll for auto assignment.
     */
    const autoAssignmentPolling = usePollAutoAssignment(stepRun, skipAutoAssign)

    /**
     * Handle the form submission.
     */
    const handleSubmit = async () => {
      setIsSubmitting(true)
      try {
        const validationResult: { result: boolean; message?: string } = validateForm(
          stepRun.step.id,
          formValues,
          form?.fields ?? [],
          baseRun.baseRunVariables as TBaseRunVariable[]
        )
        if (!validationResult.result) {
          throw new Error(validationResult.message)
        }

        if (form?.type === 'create') {
          if (
            form?.maxNumberOfBaseRuns &&
            (baseRun.childCounts?.[form?.baseId ?? ''] ?? 0) >= form.maxNumberOfBaseRuns
          ) {
            throw new Error(
              `Please ensure a minimum of ${form.minNumberOfBaseRuns} and a maximum of ${form.maxNumberOfBaseRuns} entries are added in ${name} before submitting.`
            )
          }
        }

        if (form?.type === 'update' && form?.completeStepRunOnSubmit) {
          const createFormsInTheWizard = wizardState?.wizardData?.filter(
            (wd) => wd.config?.form?.type === 'create'
          )
          for (const createForm of createFormsInTheWizard ?? []) {
            const { baseId, minNumberOfBaseRuns, maxNumberOfBaseRuns } =
              createForm.config?.form ?? {}
            if (
              minNumberOfBaseRuns &&
              (baseRun.childCounts?.[baseId ?? ''] ?? 0) < minNumberOfBaseRuns
            ) {
              throw new Error(
                `Please ensure a minimum of ${minNumberOfBaseRuns} and a maximum of ${maxNumberOfBaseRuns} entries are added in ${createForm.config?.name} before submitting.`
              )
            }
          }
        }

        const formVariables = map((baseVariableId: string) => {
          const value = formValues[baseVariableId]
          return {
            baseVariableId,
            baseRunId: baseRun.id,
            value:
              typeof value === 'boolean' ||
              typeof value === 'number' ||
              value === null ||
              Array.isArray(value)
                ? value
                : String(value),
          }
        })(keys(formValues))

        const baseRunVariables = [
          ...formVariables,
          ...(form?.defaults
            ? compact(
                form.defaults.map(
                  (defaultVariable: { baseVariableId?: string | undefined; value: any }) =>
                    // Only add default variables that are not already in the formVariables
                    !formVariables.find(
                      (formVariable) =>
                        formVariable.baseVariableId === defaultVariable.baseVariableId
                    )
                      ? {
                          baseRunId: baseRun.id,
                          baseVariableId: defaultVariable.baseVariableId as string,
                          value: String(defaultVariable.value),
                        }
                      : null
                )
              )
            : []),
          ...(form?.embedUrlField
            ? [
                {
                  baseRunId: baseRun.id,
                  baseVariableId: form.embedUrlField.baseVariableId,
                  value: String(embedState?.currentEmbedLink?.[form.embedId as string]),
                },
              ]
            : []),
        ]

        let assignedStepRun:
          | undefined
          | void
          | (TStepRun & {
              baseRun: TBaseRun
              step: TStep
              wizardInitialBaseRunVariables: TBaseRunVariable[]
            })
          | Record<string, any>

        assignedStepRun = await submitWizard({
          formConfig: form as WizardSchemas.Form.TSchema,
          processId: wizardState.processId as string,
          stepRunId: stepRun.id,
          assignNextTaskV1: assignStepRunOnCompletionChecked,
          assignNextTaskV2: !skipAutoAssign,
          baseRunVariables,
          startedAt: startedAt.current ?? new Date(),
          durationInSeconds: differenceInSeconds(new Date(), startedAt.current ?? new Date()),
          baseRunId: stepRun.baseRunId,
          // We only want to handle "create" forms in attended map steps
          isAttendedMap:
            stepRun.step?.stepTemplateId === ATTENDED_MAP_STEP_TEMPLATE_ID &&
            form?.type === 'create',
        })

        // At this point, it means all required fields were filled in & saved. So we can set the Wizard to ready for submit.
        dispatch({
          type: 'setReadyForSubmit',
          key: dispatchKey,
          value: true,
        })

        if (form?.completeStepRunOnSubmit && hasNextStepStrategy) {
          assignedStepRun = await autoAssignmentPolling()
        }

        if (assignedStepRun && wizardState.processId) {
          const { wizardConfig, trainingLink } = (assignedStepRun?.step?.meta ?? {}) as Record<
            string,
            any
          >

          const openWizard = () => {
            dispatch({
              type: 'openWizard',
              stepRun: assignedStepRun as unknown as TStepRun,
              baseRun: assignedStepRun?.baseRun,
              stepName: assignedStepRun?.step.name,
              wizardInitialBRVs: assignedStepRun?.wizardInitialBaseRunVariables,
              wizardData: wizardConfig,
              trainingLink: trainingLink as string,
              processId: wizardState.processId as string,
            })
            // Scroll wizard container to top
            ;(wizardContainerRef as RefObject<any>)?.current?.elementRef?.current?.scrollTo?.(0, 0)
          }

          if (assignedStepRun.status === 'running') {
            openWizard()
          }

          if (assignedStepRun.status === 'pending') {
            const stepRunStartData = await startStepRun({
              id: toGlobalId('StepRunType', assignedStepRun.id),
            })
            if (stepRunStartData.stepRunStartV2.__typename === 'GraphQLErrorType') {
              const { message, code } = stepRunStartData.stepRunStartV2

              if (message === 'User has not acknowledged the latest statement of work') {
                try {
                  const request = await getOrCreateSow({
                    stepRunId: toGlobalId('StepRunType', assignedStepRun.id),
                  })

                  if (request?.getOrCreateStatementOfWork?.__typename !== 'GraphQLErrorType') {
                    dispatchSowState({
                      type: 'setSowToAcknowledge',
                      showSowModal: true,
                      sowToAcknowledge: request.getOrCreateStatementOfWork,
                      // When user acknowledges the SoW we open the wizard, else we just close the SoW modal
                      openWizard,
                      stepRunId: assignedStepRun.id,
                    })
                  }
                } catch (err: unknown) {
                  addToast(
                    `Fetch Statement of Work failed: ${(err as Error | undefined)?.message}`,
                    {
                      appearance: 'error',
                    }
                  )
                }
              } else {
                addToast(`${code}: ${message}`, {
                  appearance: 'error',
                })
              }
            } else {
              // Start didn't fail, open the wizard
              openWizard()
            }
          }
        }
        // Only close the wizard if completeStepRunOnSubmit is true and no step run was auto-assigned
        else if (form?.completeStepRunOnSubmit && !assignedStepRun) {
          dispatch({ type: 'closeWizard' })
        }
      } catch (error) {
        sendErrorToSentry(error)
        addToast(`Something went wrong: ${(error as Error).message}`, {
          appearance: 'error',
        })
      }
      setIsSubmitting(false)
    }

    const handleFormValueChange = (value: TFormValues) => {
      const baseVariableId = Object.keys(value)[0]

      //combining this because baseRun might not have all variables yet
      const brvs = [
        ...(wizardState.baseRun?.baseRunVariables ?? []),
        ...(wizardState.wizardInitialBRVs ?? []),
      ] as TBaseRunVariable[]
      const baseRunVariableId = brvs.find((brv) => brv.baseVariable?.id === baseVariableId)?.id
      const newValue = value[baseVariableId]

      // Update local form values state
      setFormValues((prev) => ({ ...prev, ...value }))

      // Update wizard context state
      dispatch({
        type: 'setBaseRunVariableValue',
        baseRunVariableId: baseRunVariableId as string,
        value: newValue,
      })
    }

    return (
      <ThemeProvider theme={theme}>
        <div
          className='relative box-border flex h-full flex-col rounded-lg border border-gray-400 bg-white p-2.5 shadow-md'
          ref={ref}>
          <div className='flex-grow overflow-auto'>
            {showName ? (
              <Text mb='10px' fontWeight='bold'>
                {name}
              </Text>
            ) : null}
            {form?.type === 'create' ? (
              <div className='flex items-center justify-between'>
                <div className='text-paragraphs'>
                  {baseRun.childCounts?.[form.baseId ?? ''] ? (
                    <>
                      You have created{' '}
                      <span className='font-bold'>
                        {baseRun.childCounts?.[form.baseId ?? ''] ?? ''}
                      </span>{' '}
                      row
                      {baseRun.childCounts?.[form.baseId ?? ''] ?? 0 > 1 ? 's' : ''} for{' '}
                      <span className='font-bold'>
                        {process?.bases.find((base) => base.id === form?.baseId)?.name}
                      </span>
                    </>
                  ) : null}
                </div>
                <Timer
                  ref={startedAt}
                  childBaseName={
                    process?.bases.find((base) => base.id === form?.baseId)?.name ?? ''
                  }
                />
              </div>
            ) : null}
            {!enableFormValidation ? (
              <ConditionalForm
                formReference={stepRun.id}
                initialFormValues={wizardState.wizardInitialBRVs as TBaseRunVariable[]}
                onShowReview={(state) => setShowReview(state)}
                onCanSubmit={(state) => setCanSubmit(state)}
                setResetState={(func) => {
                  resetConditionalFormState.current = func
                }}
                formBaseVariables={
                  process?.bases.find(
                    (base) => base.id === (form?.type === 'create' ? form?.baseId : baseRun?.baseId)
                  )?.baseVariables ?? []
                }
                form={form}
                formValues={formValues}
                isReadOnly={isReadOnly}
                baseRunId={baseRun.id}
                stepRunId={stepRun.id}
                processId={wizardState.processId ?? ''}
                onSetFormValues={(value) => {
                  if (!isEqual(value, {})) {
                    // Only set startedAt if it hasn't been started yet
                    startedAt.current = !startedAt.current ? new Date() : startedAt.current
                  }

                  handleFormValueChange(value)
                }}
              />
            ) : (
              <DynamicForm
                form={form}
                formValues={formValues}
                initialFormValues={wizardState.wizardInitialBRVs as TBaseRunVariable[]}
                isReadOnly={isReadOnly}
                onSetFormValues={(value) => {
                  if (!isEqual(value, {})) {
                    // Only set startedAt if it hasn't been started yet
                    startedAt.current = !startedAt.current ? new Date() : startedAt.current
                  }

                  handleFormValueChange(value)
                }}
                onShowReview={/* to be removed */ (state) => setShowReview(state)}
                onCanSubmit={/* to be removed */ (state) => setCanSubmit(state)}
                setResetState={
                  /* to be removed */ (func) => {
                    resetConditionalFormState.current = func
                  }
                }
              />
            )}

            {hasNextStepStrategy && form?.completeStepRunOnSubmit ? (
              <SmallCheckbox checked={skipAutoAssign} onClick={(e) => setSkipAutoAssign(e)}>
                Don't assign a new task after submission
              </SmallCheckbox>
            ) : null}
          </div>
          <div className='mt-2 flex justify-end'>
            {isEmpty(form?.sections) ? (
              <Button
                variant='outlined'
                color='error'
                onClick={handleSubmit}
                disabled={isSubmitting || isReadOnly}>
                {form?.submitButtonText ?? 'Submit'}
              </Button>
            ) : showReview || size(form?.sections) === 1 ? (
              <Button
                variant='outlined'
                color='primary'
                disabled={isSubmitting || !canSubmit || isReadOnly}
                onClick={handleSubmit}>
                {isSubmitting ? (
                  <CircularProgress color='inherit' size={20} />
                ) : (
                  form?.submitButtonText ?? 'Save'
                )}
              </Button>
            ) : null}
          </div>
        </div>
      </ThemeProvider>
    )
  }
)

const Timer = forwardRef(({ childBaseName }: { childBaseName: string }, ref) => {
  // hh:mm:ss
  const [activeTime, setActiveTime] = useState('00:00:00')
  const startedAt = ref as MutableRefObject<Date | null>

  // increments activeTime each second if startedAt is set
  useEffect(() => {
    const interval = setInterval(() => {
      if (!startedAt?.current) {
        setActiveTime('00:00:00')
        return
      }

      const difference = differenceInSeconds(new Date(), startedAt?.current as Date)
      const hours = Math.floor(difference / 3600).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })

      const minutes = Math.floor((difference % 3600) / 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })

      const seconds = (difference % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })

      setActiveTime(`${hours}:${minutes}:${seconds}`)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className='flex items-center gap-0.5'>
      <div>{activeTime}</div>
      <Tooltip
        content={
          <>
            <div>
              Time spent creating child base run in{' '}
              <span className='font-bold'>{childBaseName}</span>
            </div>
            <div>NOTE: Tracking only begins upon interaction with the form</div>
          </>
        }
        side='left'>
        <InfoOutlineIcon width={10} height={10} className='cursor-pointer text-black' />
      </Tooltip>
    </div>
  )
})

export { FormWAC }
