import * as z from 'zod'

const ProcessStatus = {
  draft: 'draft',
  testing: 'testing',
  active: 'active',
  inactive: 'inactive',
  archived: 'archived',
} as const

export const VariableType = {
  any: 'any',
  boolean: 'boolean',
  date: 'date',
  datetime: 'datetime',
  duration: 'duration',
  email: 'email',
  enum: 'enum',
  html: 'html',
  number: 'number',
  string: 'string',
  url: 'url',
  object: 'object',
  money: 'money',
  base_id: 'base_id',
  base_run_id: 'base_run_id',
  process_id: 'process_id',
  step_id: 'step_id',
  step_run_id: 'step_run_id',
  base_variable_id: 'base_variable_id',
  base_run_variable_id: 'base_run_variable_id',
  a_any: 'a_any',
  a_boolean: 'a_boolean',
  a_date: 'a_date',
  a_datetime: 'a_datetime',
  a_duration: 'a_duration',
  a_email: 'a_email',
  a_enum: 'a_enum',
  a_html: 'a_html',
  a_number: 'a_number',
  a_object: 'a_object',
  a_string: 'a_string',
  a_url: 'a_url',
  a_a_any: 'a_a_any',
  a_a_object: 'a_a_object',
  a_a_string: 'a_a_string',
} as const

const VariableStage = {
  build: 'build',
  scope: 'scope',
  operate: 'operate',
} as const

const VariableDirection = {
  input: 'input',
  output: 'output',
}

const StepTemplateType = {
  control_flow: 'control_flow',
  full_auto: 'full_auto',
  semi_auto: 'semi_auto',
  manual: 'manual',
  trigger: 'trigger',
} as const

const StepTemplateSubtype = {
  branch: 'branch',
  map: 'map',
  end_map: 'end_map',
  start_process: 'start_process',
  end_process: 'end_process',
  and: 'and',
  end_base_run: 'end_base_run',
  pause: 'pause',
  attended_map: 'attended_map',
  end_attended_map: 'end_attended_map',
  wizardless: 'wizardless',
  audit: 'audit',
  qa: 'qa',
} as const

const StepTemplateSource = {
  internal: 'internal',
  process_automation: 'process_automation',
  ace: 'ace',
} as const

const CompanyStatus = {
  prospect: 'prospect',
  piloting: 'piloting',
  onboarding: 'onboarding',
  active: 'active',
  inactive: 'inactive',
  offboarding: 'offboarding',
  churned: 'churned',
} as const

const ReportingDatabase = {
  warehouse: 'warehouse',
  ultron: 'ultron',
} as const

export const ReportingPlatform = {
  portal: 'portal',
  manticore: 'manticore',
} as const

export const ReportingScope = {
  process: 'process',
  company: 'company',
} as const

const ReportingVisualization = {
  BarChart: 'BarChart',
  PieChart: 'PieChart',
  Table: 'Table',
  Choropleth: 'Choropleth',
  Linear: 'Linear',
  Tile: 'Tile',
  Grid: 'Grid',
  LineChart: 'LineChart',
} as const

const SourceType = {
  table: 'table',
  function: 'function',
}

// Helper schema for JSON fields
type Literal = boolean | number | string | null
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
)

export const BaseModel = z.object({
  id: z.string(),
  name: z.string(),
  processId: z.string(),
  parentId: z.string().nullish(),
  /**
   * sla in seconds, defaults to 86400 seconds or 24 hours
   */
  sla: z.number().int(),
  /**
   * quality for the process
   */
  qualityKpi: z.string().nullish(),
  /**
   * deadline in seconds, defaults to 86400 seconds or 24 hours
   */
  deadline: z.number().int().nullish(),
  /**
   * The actual UI display info for each column
   */
  columnConfig: jsonSchema,
  /**
   * Used to identicate if the base is an active audit base
   * when this is =true, the base is an active audit base
   * when this is =false, the base is a is still an audit base but the audit is not active
   * when this is =null, the base is not an audit base.
   * we need this mapping since a process can have only one active audit base
   */
  isActiveAuditBase: z.boolean().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const StepModel = z.object({
  id: z.string(),
  stepTemplateId: z.string(),
  /**
   * Usually these will come from the step template, but they can be overridden at build time
   */
  name: z.string(),
  description: z.string().nullish(),
  /**
   * Steps belong to a base
   */
  baseId: z.string(),
  leadTracking: z.boolean(),
  /**
   * Steps also belong to a process, but this is more just for denormalization
   */
  processId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
  position: z.number().int(),
  /**
   * Can have at most one lifecycleStageId
   */
  lifecycleStageId: z.string().nullish(),
  /**
   * ms
   */
  benchmark: z.number().int().nullish(),
  allowSelfUnassignmentUntil: z.bigint().nullish(),
  /**
   * Step Expiration (in hours)
   */
  expiresIn: z.number().int().nullish(),
  /**
   * Pause Step Duration (in seconds)
   */
  pauseFor: z.number().int().nullish(),
  /**
   * Arbitrary metadata, for example for wizards
   */
  meta: jsonSchema,
  /**
   * Optional JSON schema, generated from package zod-to-json-schema
   * it will be based on the set of variable templates in the step template
   * The schema can be used to validate variables for this particular step
   */
  variableSchema: jsonSchema,
  metaSchema: jsonSchema,
  clientCredentialId: z.string().nullish(),
})

export const ProcessModel = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  version: z.number(),
  companyId: z.string().nullish(),
  /**
   * Signifies if the process is a template or not
   */
  template: z.boolean(),
  /**
   * The original process version upon which this version is based (could be equal to id if only one version)
   */
  originProcessId: z.string().nullish(),
  duplicatedFromId: z.string().nullish(),
  /**
   * The template from which this process was created
   */
  createdFromTemplateId: z.string().nullish(),
  /**
   * A Process must have exactly one base that is the "root" base.
   * Since a Base can only be created after a Process, this is nullable,
   * but the Process will not be publishable without setting a root base
   */
  rootBaseId: z.string().nullish(),
  createdByUserId: z.string().nullish(),
  leaderUserId: z.string().nullish(),
  chatId: z.string().nullish(),
  status: z.nativeEnum(ProcessStatus),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
  /**
   * Flag to run the process on concorde process engine
   */
  projectId: z.string().nullish(),
  useConcorde: z.boolean().nullish(),
})

export const ProcessModelSelect = z
  .object({
    id: z.boolean().optional(),
    name: z.boolean().optional(),
    description: z.boolean().optional(),
    version: z.boolean().optional(),
    companyId: z.boolean().optional(),
    template: z.boolean().optional(),
    originProcessId: z.boolean().optional(),
    duplicatedFromId: z.boolean().optional(),
    createdFromTemplateId: z.boolean().optional(),
    rootBaseId: z.boolean().optional(),
    createdByUserId: z.boolean().optional(),
    leaderUserId: z.boolean().optional(),
    chatId: z.boolean().optional(),
    status: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    deletedAt: z.boolean().optional(),
    useConcorde: z.boolean().optional(),
    // Not setting relation select because it may have nesting too deep and cause TS performance issues
    projectId: z.boolean().optional(),
    company: z.union([z.boolean().optional(), z.any()]),
    createdByUser: z.union([z.boolean().optional(), z.any()]),
    leaderUser: z.union([z.boolean().optional(), z.any()]),
    originProcess: z.union([z.boolean().optional(), z.any()]),
    duplicatedFrom: z.union([z.boolean().optional(), z.any()]),
    createdFromTemplate: z.union([z.boolean().optional(), z.any()]),
    duplicatedTo: z.union([z.boolean().optional(), z.any()]),
    stepGoTos: z.union([z.boolean().optional(), z.any()]),
    baseRuns: z.union([z.boolean().optional(), z.any()]),
    pricingConfig: z.union([z.boolean().optional(), z.any()]),
    paymentConfig: z.union([z.boolean().optional(), z.any()]),
    processToSkills: z.union([z.boolean().optional(), z.any()]),
    processToTools: z.union([z.boolean().optional(), z.any()]),
    descendantProcesses: z.union([z.boolean().optional(), z.any()]),
    derivedProcesses: z.union([z.boolean().optional(), z.any()]),
    bases: z.union([z.boolean().optional(), z.any()]),
    rootBase: z.union([z.boolean().optional(), z.any()]),
    steps: z.union([z.boolean().optional(), z.any()]),
    baseViews: z.union([z.boolean().optional(), z.any()]),
    lifecycleStages: z.union([z.boolean().optional(), z.any()]),
    SuccessStory: z.union([z.boolean().optional(), z.any()]),
    ProcessToUseCase: z.union([z.boolean().optional(), z.any()]),
    financeEvent: z.union([z.boolean().optional(), z.any()]),
    billEvents: z.union([z.boolean().optional(), z.any()]),
    stepHistories: z.union([z.boolean().optional(), z.any()]),
    stepGoToHistories: z.union([z.boolean().optional(), z.any()]),
    baseHistories: z.union([z.boolean().optional(), z.any()]),
    baseVariableHistories: z.union([z.boolean().optional(), z.any()]),
    processHistories: z.union([z.boolean().optional(), z.any()]),
    ProcessBadge: z.union([z.boolean().optional(), z.any()]),
    invoiceEvent: z.union([z.boolean().optional(), z.any()]),
    processQuestionnaireRuns: z.union([z.boolean().optional(), z.any()]),
    BadgeToProcess: z.union([z.boolean().optional(), z.any()]),
    processLayouts: z.union([z.boolean().optional(), z.any()]),
    _count: z.union([z.boolean().optional(), z.any()]),
  })
  .optional()

export const BaseViewModel = z.object({
  id: z.string(),
  name: z.string().nullish(),
  processId: z.string(),
  baseId: z.string(),
  /**
   * userId is used for private baseView. on public baseViews it is null
   */
  userId: z.string().nullish(),
  createdByUserId: z.string().nullish(),
  lifecycleStageId: z.string().nullish(),
  config: jsonSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const StepTemplateVariableModel = z.object({
  id: z.string(),
  stepTemplateId: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  type: z.nativeEnum(VariableType),
  direction: z.nativeEnum(VariableDirection),
  stage: z.nativeEnum(VariableStage),
  defaultValue: jsonSchema,
  /**
   * The options for an enum variable
   */
  options: z.string().array(),
  /**
   * Used for the sort order of the variable, within a step template
   */
  position: z.number().int(),
  required: z.boolean(),
  /**
   * Must be unique within a step template
   */
  key: z.string(),
  /**
   * Optional, JSON schema to describe the structure of the variable in the case of an object or array
   */
  schema: jsonSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const StepVariableReferenceModel = z.object({
  id: z.string(),
  stepId: z.string(),
  /**
   * The step template variable that this maps to
   */
  stepTemplateVariableId: z.string(),
  /**
   * This is nullable because step variable references can be "orphaned" which means
   * that they temporarily don't have a base variable to reference.
   */
  baseVariableId: z.string().nullish(),
  /**
   * Used for the sort order of the variable reference, within a step
   */
  position: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export const ConditionalSkillRequirementModel = z.object({
  id: z.string(),
  rank: z.number().int(),
  value: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
  stepId: z.string(),
  baseId: z.string(),
  baseVariableId: z.string(),
  skillId: z.string(),
})

export const ConditionalSkillRequirementModelSelect = z.object({
  id: z.boolean().optional(),
  rank: z.boolean().optional(),
  value: z.boolean().optional(),
  createdAt: z.boolean().optional(),
  updatedAt: z.boolean().optional(),
  deletedAt: z.boolean().optional(),
  stepId: z.boolean().optional(),
  baseId: z.boolean().optional(),
  baseVariableId: z.boolean().optional(),
  skillId: z.boolean().optional(),
  // Not setting relation select because it may have nesting too deep and cause TS performance issues
  step: z.union([z.boolean().optional(), z.any()]),
  base: z.union([z.boolean().optional(), z.any()]),
  baseVariable: z.union([z.boolean().optional(), z.any()]),
  skill: z.union([z.boolean().optional(), z.any()]),
})

export const SkillRequirementModelSelect = z
  .object({
    id: z.boolean().optional(),
    rank: z.boolean().optional(),
    stepId: z.boolean().optional(),
    skillId: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    // Not setting relation select because it may have nesting too deep and cause TS performance issues
    step: z.union([z.boolean().optional(), z.any()]),
    skill: z.union([z.boolean().optional(), z.any()]),
  })
  .optional()

export const StepPriorityModelSelect = z
  .object({
    id: z.boolean().optional(),
    priority: z.boolean().optional(),
    value: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    deletedAt: z.boolean().optional(),
    stepId: z.boolean().optional(),
    baseId: z.boolean().optional(),
    baseVariableId: z.boolean().optional(),
    // Not setting relation select because it may have nesting too deep and cause TS performance issues
    step: z.union([z.boolean().optional(), z.any()]),
    base: z.union([z.boolean().optional(), z.any()]),
    baseVariable: z.union([z.boolean().optional(), z.any()]),
  })
  .optional()

export const StepRunGoToModel = z.object({
  baseRunId: z.string(),
  goFromStepRunId: z.string(),
  goToStepRunId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export const StepGoToModel = z.object({
  processId: z.string(),
  goFromStepId: z.string(),
  goToStepId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
})

export const StepTemplateModel = z.object({
  id: z.string(),
  name: z.string(),
  path: z.string().nullish(),
  description: z.string().nullish(),
  duplicatedFromId: z.string().nullish(),
  type: z.nativeEnum(StepTemplateType),
  subtype: z.nativeEnum(StepTemplateSubtype).nullish(),
  icon: jsonSchema,
  version: z.number(),
  /**
   * The original step template version upon which this version is based (could be equal to id if only one version)
   */
  originStepTemplateId: z.string().nullish(),
  suggested: z.boolean(),
  stepTemplateCategoryId: z.string().nullish(),
  companyId: z.string().nullish(),
  serviceId: z.string().nullish(),
  credentialTypeId: z.string().nullish(),
  maxRetries: z.number().int(),
  /**
   * Replaces the older "external" column. 'internal' for Internal Automations, Manual & Control Flow Step Templates.
   */
  source: z.nativeEnum(StepTemplateSource),
  /**
   * Arbitrary metadata, for example for wizards
   */
  meta: jsonSchema,
  iconName: z.string().nullish(),
  /**
   * JSON schema, generated from package zod-to-json-schema
   * it will be based on the set of variables
   * This schema can be used to validate the specific set of variables for this step template
   */
  variableSchema: jsonSchema,
  /**
   * Used to validate the metadata if it exists
   */
  metaSchema: jsonSchema,
  /**
   * used to hide a step template from the UI
   */
  hidden: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const BaseVariableModel = z.object({
  id: z.string(),
  baseId: z.string(),
  /**
   * Optional, the first step template variable that added this base variable to the base
   */
  stepTemplateVariableId: z.string().nullish(),
  /**
   * Friendly name, within the base. Inherits from the step template variable, but can be overridden.
   */
  name: z.string(),
  description: z.string().nullish(),
  type: z.nativeEnum(VariableType),
  stage: z.nativeEnum(VariableStage),
  defaultValue: jsonSchema,
  required: z.boolean(),
  /**
   * hidden is used to prevent sending huge payload data to base page, if the basevariable is hidden, it never shows in the base table
   */
  hidden: z.boolean().nullish(),
  /**
   * Optional JSON schema for validating the shape of the variable if it is an object or array
   */
  schema: jsonSchema,
  /**
   * Used for the sort order of the variable, within a base
   */
  position: z.number().int(),
  /**
   * The options for an enum variable
   */
  options: z.string().array(),
  /**
   * Needed so that we can map payloads to the correct base variable
   * Must be unique per base
   */
  key: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const CompanyModel = z.object({
  id: z.string(),
  name: z.string(),
  sendPendingInvoiceReminder: z.boolean(),
  iconName: z.string().nullish(),
  website: z.string().nullish(),
  industry: z.string().nullish(),
  headquarters: z.string().nullish(),
  address: z.string().nullish(),
  yearFounded: z.number().int().nullish(),
  employeeSize: z.string().nullish(),
  public: z.boolean().nullish(),
  totalRaised: z.number().int().nullish(),
  amountLastRaised: z.number().int().nullish(),
  lastFundingRound: z.string().nullish(),
  lastFundingDate: z.date().nullish(),
  status: z.nativeEnum(CompanyStatus),
  statusChangeReason: jsonSchema,
  businessUnit: z.string().nullish(),
  businessLine: z.string().nullish(),
  businessVertical: z.string().nullish(),
  salesforceId: z.string().nullish(),
  stripeCustomerId: z.string().nullish(),
  type: z.string().nullish(),
  churnDate: z.date().nullish(),
  xeroContactId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
  ownerId: z.string().nullish(),
  operationsDirectorId: z.string().nullish(),
  accountDirectorId: z.string().nullish(),
})

export const LifecycleStageModel = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  position: z.number().int(),
  processId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const ReportModel = z.object({
  id: z.string(),
  description: z.string().nullish(),
  databaseName: z.nativeEnum(ReportingDatabase),
  reportName: z.string(),
  reportTitle: z.string(),
  schemaName: z.string(),
  sourceName: z.string(),
  subtitle: z.string(),
  companyId: z.number().int().nullish(),
  sourceType: z.nativeEnum(SourceType),
  scope: z.nativeEnum(ReportingScope),
  platform: z.nativeEnum(ReportingPlatform),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const ReportVisualizationModel = z.object({
  id: z.string(),
  type: z.nativeEnum(ReportingVisualization),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const StoryModel = z.object({
  id: z.string().uuid(),
  name: z.string(),
  title: z.string(),
  subtitle: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  platform: z.nativeEnum(ReportingPlatform),
  scope: z.nativeEnum(ReportingScope),
  order: z.number().optional(), // Has default value
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date().nullish(),
})

export const BaseRunModelSelect = z
  .object({
    id: z.boolean().optional(),
    priority: z.boolean().optional(),
    value: z.boolean().optional(),
    createdAt: z.boolean().optional(),
    updatedAt: z.boolean().optional(),
    deletedAt: z.boolean().optional(),
    stepId: z.boolean().optional(),
    baseId: z.boolean().optional(),
    baseVariableId: z.boolean().optional(),
    // Not setting relation select because it may have nesting too deep and cause TS performance issues
    step: z.union([z.boolean().optional(), z.any()]),
    base: z.union([z.boolean().optional(), z.any()]),
    baseVariable: z.union([z.boolean().optional(), z.any()]),
    process: z.union([z.boolean().optional(), z.any()]),
  })
  .optional()
