import { classNames } from '@invisible/common/helpers'
import { fromGlobalId } from '@invisible/concorde/gql-client'
import { sendErrorToSentry } from '@invisible/errors'
import { logger } from '@invisible/logger/client'
import { Button } from '@invisible/ui/button'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
import { Dropdown } from '@invisible/ui/dropdown'
import { Input } from '@invisible/ui/input'
import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import { NullSwitch } from '@invisible/ui/null-switch'
import { SingleDatePicker } from '@invisible/ui/single-date-picker'
import { TagInput } from '@invisible/ui/tag-input'
import { gray, styled } from '@invisible/ui/themes'
import { useToasts } from '@invisible/ui/toasts'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { startCase } from 'lodash/fp'
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import sanitize from 'sanitize-html'
import { useGate } from 'statsig-react'

import { processUploadedFile } from '../common/helpers'
import { useExecuteManualTrigger } from '../hooks/useExecuteManualTrigger'
import DatasetDialog from './fields/DatasetDialog'
import { DatasetField } from './fields/DatasetField'
import { FieldComponent } from './fields/FieldComponent'
import { getAbstractTypeFileType } from './helpers'
import { TFieldType, TParsedProperties, TParsedProperty } from './types'

export interface ITriggerFormProps {
  parsedProperties: TParsedProperties
  triggerStepId: string
  requiredFields: string[]
  closeWizard: () => void
  description: string
  onTriggerExecute?: () => void
  companyId: string | null
}

const Container = styled.div`
  border-radius: 8px;
  height: 100%;
  background-color: white;
  border: 1px solid ${gray(4)};
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
`

const muiStyles = {
  rawPayloadSelect: {
    height: '40px',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '4px',
    },
  },
  selectPlaceholder: {
    color: 'gray',
    fontStyle: 'normal',
  },
  button: {
    fontWeight: 'normal',
    textTransform: 'none',
  },
}

const TriggerFormWAC = ({
  parsedProperties,
  triggerStepId,
  requiredFields,
  closeWizard,
  description,
  onTriggerExecute,
  companyId,
}: ITriggerFormProps) => {
  const { value: enableNewManualTriggerInterface } = useGate('enable-new-manual-trigger-interface')
  const { addToast } = useToasts()
  const ref = useRef<HTMLDivElement>(null)
  const reactQueryClient = useQueryClient()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [activeDataSetField, setActiveDataSetField] = useState<string | null>(null)
  const [currentFileTypeToAdd, setCurrentFileTypeToAdd] = useState<string | null>(null)

  const [isDatasetDialogOpen, setIsDatasetDialogOpen] = useState(false)

  const handleAddFileClick = (label: string, fileType: string) => {
    setActiveDataSetField(label)
    setCurrentFileTypeToAdd(fileType)
    setIsDatasetDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setActiveDataSetField(null)
    setCurrentFileTypeToAdd(null)
    setIsDatasetDialogOpen(false)
  }

  const csvUploadInputRefs = useRef<HTMLInputElement[]>([] as HTMLInputElement[])
  const csvUploadInputRefMap = parsedProperties
    .filter((p) => p.type === 'array' && p.items?.type === 'object')
    .reduce(
      (acc, p, idx) => ({
        ...acc,
        [p.label]: idx,
      }),
      {} as Record<string, number>
    )

  const [formValues, setFormValues] = useState<Record<string, any>>({})
  const {
    isLoading: isExecuteConcordeManualTriggerLoading,
    mutateAsync: executeConcordeManualTrigger,
  } = useExecuteManualTrigger({
    onSuccess: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const onConfirmDataset = (label: string, id: string, name: string) => {
    setFormValues((prev) => ({
      ...prev,
      [label]: {
        company_file_id: fromGlobalId(id),
        file_name: name,
      },
    }))
    handleCloseDialog()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, label: string) => {
    event.stopPropagation()
    event.preventDefault()
    csvUploadInputRefs.current[csvUploadInputRefMap[label]]?.click()
  }

  const handleFileUpload = (label: string) => (e: ChangeEvent<HTMLInputElement>) => {
    processUploadedFile({ e, setFormValues, label })
  }

  useEffect(() => {
    // Adding default values on load
    const defaultFormValues = parsedProperties.reduce((acc, property) => {
      if (property.default !== undefined) {
        return { ...acc, [property.label]: property.default }
      }
      return acc
    }, {})
    setFormValues(defaultFormValues)
  }, [parsedProperties])

  // Todo: Remove once enableNewManualTriggerInterface is fully rolled out
  const fieldComponents: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [key in TFieldType]?: FC<TParsedProperty & { formValues: Record<string, any> }>
  } = useMemo(
    () => ({
      string: ({ label, formValues, format }) => {
        if (format === 'date-time') {
          return (
            <DateTimePicker
              views={['year', 'month']}
              value={formValues[label]}
              hideTime={true}
              onChange={(value: Date | null) =>
                setFormValues((prev) => ({
                  ...prev,
                  [label]: value?.toISOString(),
                }))
              }
            />
          )
        }
        return (
          <Input
            width='100%'
            value={formValues[label]}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormValues((prev) => ({
                ...prev,
                [label]: e.target.value,
              }))
            }
          />
        )
      },
      number: ({ label, formValues }) => (
        <Input
          width='100%'
          type='number'
          value={formValues[label]}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: Number(e.target.value),
            }))
          }
        />
      ),
      date: ({ label }) => (
        <SingleDatePicker
          wFull
          onChange={(date) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: date,
            }))
          }
        />
      ),
      datetime: ({ label }) => (
        <DateTimePicker
          value={formValues[label]}
          fullWidth
          hideTime={false}
          inputReadonly
          onChange={(value: Date | null) => {
            if (!value) return

            setFormValues((prev) => ({
              ...prev,
              [label]: value?.toISOString(),
            }))
          }}
        />
      ),
      url: ({ label, formValues }) => (
        <Input
          width='100%'
          type='url'
          value={formValues[label]}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: e.target.value,
            }))
          }
        />
      ),
      email: ({ label, formValues }) => (
        <Input
          width='100%'
          type='email'
          value={formValues[label]}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: e.target.value,
            }))
          }
        />
      ),
      dropdown: ({ label, ...property }) => (
        <Dropdown
          width='100%'
          selectedKey={(formValues[label] as string) ?? null}
          maxHeight='300px'
          options={property.enum?.map((option) => ({ key: option, value: option })) ?? []}
          onChange={({ key }) =>
            setFormValues((prev) => ({
              ...prev,
              [label]: key,
            }))
          }
          name={label}
        />
      ),
      boolean: ({ label, formValues }) => (
        <NullSwitch
          isOn={formValues[label]}
          onToggle={(value) => setFormValues((prev) => ({ ...prev, [label]: value }))}
        />
      ),
      array: ({
        label,
        items,
        formValues,
      }: {
        label: string
        items?: { type: TFieldType }
        formValues: Record<string, any>
      }) => {
        if (items?.type === 'object') {
          return (
            <>
              <Button variant='primary' size='md' onClick={(e) => handleClick(e, label)}>
                {formValues[label] ? 'Uploaded' : 'Upload CSV or JSONL'}
              </Button>
              <input
                ref={(el) => {
                  if (csvUploadInputRefs && el) {
                    csvUploadInputRefs.current[csvUploadInputRefMap[label]] = el
                  }
                }}
                type='file'
                accept='.csv, .jsonl'
                onChange={handleFileUpload(label)}
                hidden
              />
            </>
          )
        } else if (items?.type === 'string') {
          return (
            <TagInput
              onChange={(value: string[]) =>
                setFormValues((prev) => ({
                  ...prev,
                  [label]: value,
                }))
              }
            />
          )
        } else {
          logger.warn(`array type not supported by TriggerFormWAC: ${items?.type}`)
          return null
        }
      },
    }),
    [formValues, handleClick]
  )

  const missingRequiredValues = requiredFields?.reduce(
    (acc, requiredField) => (formValues[requiredField] === undefined ? true : acc),
    false
  )

  const isDisabled = missingRequiredValues || isSubmitting || isExecuteConcordeManualTriggerLoading

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      await executeConcordeManualTrigger({ payload: formValues, triggerStepId })
      closeWizard()
      onTriggerExecute?.()
    } catch (error) {
      sendErrorToSentry(error)
      addToast(`Something went wrong: ${(error as Error).message}`, {
        appearance: 'error',
      })
      setIsSubmitting(false)
    }
  }

  // Don't enable new interface if the manual trigger contains an array field
  if (enableNewManualTriggerInterface && !parsedProperties.some((p) => p.fieldType === 'array'))
    return (
      <MUIThemeProvider>
        {description ? (
          <Box mb={5} dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
        ) : null}
        <Stack direction='column' spacing={3} mt={3} flexGrow={1}>
          {parsedProperties.map((field) => (
            <Box
              key={field.label}
              mb={2}
              sx={{
                display: field.type === 'boolean' ? 'flex' : 'block',
                flexDirection: field.type === 'boolean' ? 'row-reverse' : 'column',
                alignItems: field.type === 'boolean' ? 'center' : 'flex-start',
                justifyContent: field.type === 'boolean' ? 'flex-end' : 'flex-start',
                gap: field.type === 'boolean' ? 1 : 0,
              }}>
              {field.fieldType === 'date' ||
              field.fieldType === 'datetime' ||
              field.abstractType ? (
                <Typography mb={field.type === 'boolean' ? 0 : 1}>
                  {startCase(field.label)}
                </Typography>
              ) : null}
              <Box sx={{ flexShrink: 0 }}>
                {field.abstractType ? (
                  <DatasetField
                    value={formValues[field.label]}
                    label={field.label}
                    fileType={getAbstractTypeFileType(field.abstractType)}
                    handleAddFileClick={handleAddFileClick}
                    handleFileRemoval={() => setFormValues((p) => ({ ...p, [field.label]: null }))}
                  />
                ) : (
                  <FieldComponent
                    formValues={formValues}
                    handleClick={handleClick}
                    handleCsvUpload={handleFileUpload}
                    csvUploadInputRefMap={csvUploadInputRefMap}
                    csvUploadInputRefs={csvUploadInputRefs}
                    field={field}
                    setFormValues={setFormValues}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Stack>
        <Box display='flex' justifyContent='flex-end' mt={6}>
          <MuiButton variant='outlined' onClick={closeWizard} sx={{ ...muiStyles.button, mr: 2 }}>
            Cancel
          </MuiButton>
          <LoadingButton
            variant='contained'
            color='primary'
            disabled={isDisabled}
            onClick={handleSubmit}
            sx={{ ...muiStyles.button }}
            loading={isSubmitting || isExecuteConcordeManualTriggerLoading}>
            Submit
          </LoadingButton>
        </Box>

        <DatasetDialog
          isOpen={isDatasetDialogOpen}
          onClose={handleCloseDialog}
          activeDataSetField={activeDataSetField}
          fileType={currentFileTypeToAdd}
          onConfirmDataset={onConfirmDataset}
          companyId={companyId}
        />
      </MUIThemeProvider>
    )

  return (
    <Container ref={ref}>
      {description ? (
        <div className='mb-5' dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
      ) : null}
      {parsedProperties?.map((field) => (
        <div
          key={field.label}
          className={classNames(
            'mb-5',
            field.type === 'boolean' ? 'flex flex-row-reverse items-center justify-end gap-3' : ''
          )}>
          <div className={field.type === 'boolean' ? '' : 'mb-2'}>{startCase(field.label)}</div>
          <div className='shrink-0'>
            {fieldComponents[field.fieldType]?.({ ...field, formValues })}
          </div>
        </div>
      ))}
      <div className='mt-2 flex justify-center'>
        <Button
          variant='primary'
          size='md'
          disabled={isDisabled}
          onClick={handleSubmit}
          loading={isSubmitting || isExecuteConcordeManualTriggerLoading}>
          Submit
        </Button>
      </div>
    </Container>
  )
}

export { TriggerFormWAC }
