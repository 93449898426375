import DeleteIcon from '@mui/icons-material/Delete'
import { GridRow, GridSlotsComponentsProps } from '@mui/x-data-grid'
import * as React from 'react'

import { ContextMenu } from '../../common/ContextMenu'

type TProps = GridSlotsComponentsProps['row'] & {
  rowId: string | number
  deleteRow?: (rowId: string | number) => void
}

export function CustomGridRow(props: TProps) {
  return (
    <ContextMenu
      menuItems={[
        ...(props?.deleteRow
          ? [
              {
                title: 'Delete',
                handleClick: () => props?.deleteRow?.(props.rowId),
                icon: <DeleteIcon />,
              },
            ]
          : []),
      ]}>
      <GridRow disabled {...props} />
    </ContextMenu>
  )
}
