import { Wizard as WizardSchemas } from '@invisible/ultron/zod'

const getNumberHelperText = (min: number | undefined, max: number | undefined) => {
  if (Number.isInteger(min) && Number.isInteger(max)) {
    return `Value must be in range [${min}-${max}].`
  } else if (Number.isInteger(min) && !Number.isInteger(max)) {
    return `Value must be greater than or equal to ${min}.`
  } else if (!Number.isInteger(min) && Number.isInteger(max)) {
    return `Value must be less than or equal to ${max}.`
  } else {
    return ''
  }
}

const getSingleLimitText = (
  limit: number,
  type: string | undefined,
  unit: 'characters' | 'words'
) =>
  type === 'hard'
    ? `Your response must remain under ${limit} ${unit}.`
    : `We recommend keeping your response under ${limit} ${unit}.`

const getLimitText = (
  characterLimit: number | undefined,
  characterLimitType: string | undefined,
  wordLimit: number | undefined,
  wordLimitType: string | undefined
) => {
  if (!characterLimit && !wordLimit) return null

  if (characterLimit && wordLimit) {
    const hardLimit =
      characterLimitType === 'hard' ? 'characters' : wordLimitType === 'hard' ? 'words' : null
    if (hardLimit) {
      const limit = hardLimit === 'characters' ? characterLimit : wordLimit
      const softLimit = hardLimit === 'characters' ? wordLimit : characterLimit
      const softUnit = hardLimit === 'characters' ? 'words' : 'characters'
      return `We recommend keeping your response under ${softLimit} ${softUnit}, but it must remain within ${limit} ${hardLimit}.`
    } else {
      return `We recommend keeping your response under ${characterLimit} characters and under ${wordLimit} words.`
    }
  }

  if (characterLimit) {
    return getSingleLimitText(characterLimit, characterLimitType, 'characters')
  }

  if (wordLimit) {
    return getSingleLimitText(wordLimit, wordLimitType, 'words')
  }

  return null
}

const getHelperColor = (isAtLimit: boolean, isNearLimit: boolean): string => {
  if (isAtLimit) return '#D32F2F'
  if (isNearLimit) return '#EF6C00'
  return '#00000099'
}

const getCharacterAndWordLimitInfo = (
  id: string,
  value: string,
  form?: WizardSchemas.Form.TSchema
) => {
  const fields = form?.fields?.find((field) => field.baseVariableId === id)
  const characterLimit = fields?.characterLimit
  const characterLimitType = fields?.characterLimitType
  const wordLimit = fields?.wordLimit
  const wordLimitType = fields?.wordLimitType

  const currentValue = value

  const currentLength = typeof currentValue === 'string' ? currentValue.length : 0
  const currentWordCount =
    typeof currentValue === 'string' && currentValue
      ? currentValue.trim().split(/\s+/).filter(Boolean).length
      : 0

  const isNearLimit = characterLimit ? currentLength >= characterLimit - 10 : false
  const isAtCharLimit = characterLimit ? currentLength > characterLimit : false
  const reachedCharLimit = characterLimit ? currentLength === characterLimit : false

  const isNearWordLimit = wordLimit ? currentWordCount >= wordLimit - 5 : false
  const isAtWordLimit = wordLimit ? currentWordCount > wordLimit : false
  const reachedWordLimit = wordLimit ? currentWordCount === wordLimit : false

  const characterHelperText = characterLimit ? `${currentLength}/${characterLimit}` : undefined
  const wordHelperText = wordLimit ? `${currentWordCount}/${wordLimit}` : undefined

  const helperColor = getHelperColor(isAtCharLimit, isNearLimit)

  const wordHelperColor = getHelperColor(isAtWordLimit, isNearWordLimit)

  return {
    characterHelperText,
    wordHelperText,
    helperColor,
    characterLimit,
    characterLimitType,
    wordLimit,
    wordLimitType,
    isAtCharLimit,
    isAtWordLimit,
    wordHelperColor,
    isNearLimit,
    isNearWordLimit,
    reachedCharLimit,
    reachedWordLimit,
  }
}
const getText = (
  limit: number | undefined,
  type: string | undefined,
  mensuredElement: 'word' | 'character',
  isNearLimit: boolean,
  isLimitReached: boolean
) => {
  const requirementText = type === 'soft' ? 'recommended' : 'mandatory'
  const reachedLimitText = isLimitReached ? 'You’ve reached' : 'You’re approaching'
  if (isNearLimit) {
    return `. ${reachedLimitText} the ${requirementText} ${limit}-${mensuredElement} limit.`
  } else {
    return `. Please shorten your text to meet the ${requirementText} ${limit}-${mensuredElement} limit.`
  }
}

export { getCharacterAndWordLimitInfo, getHelperColor, getLimitText, getNumberHelperText, getText }
