import { TextArea } from '@invisible/ui/form'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { ChangeEvent } from 'react'

import { getCharacterAndWordLimitInfo, getLimitText, getText } from '../utils/string-helpers'

interface IMultiLineStringProps {
  currentValue: string
  onchange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  id: string
  isReadOnly: boolean
  form?: WizardSchemas.Form.TSchema
  maxRows?: number
}

const MultiLineString = ({
  currentValue,
  onchange,
  id,
  isReadOnly,
  form,
  maxRows,
}: IMultiLineStringProps) => {
  const {
    isAtCharLimit,
    isAtWordLimit,
    characterHelperText,
    wordHelperText,
    helperColor,
    characterLimitType,
    wordLimitType,
    characterLimit,
    wordLimit,
    wordHelperColor,
    isNearLimit,
    isNearWordLimit,
    reachedCharLimit,
    reachedWordLimit,
  } = getCharacterAndWordLimitInfo(id, currentValue, form)

  const isHardLimitReached =
    (isAtCharLimit && characterLimitType === 'hard') || (isAtWordLimit && wordLimitType === 'hard')

  const limitText = getLimitText(characterLimit, characterLimitType, wordLimit, wordLimitType)

  return (
    <div>
      {limitText && <div className='mb-1 text-sm italic text-gray-600'>{limitText}</div>}
      <TextArea
        width='100%'
        value={currentValue}
        readOnly={isReadOnly}
        autoresize={true}
        maxRows={maxRows}
        height='80px !important'
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onchange(e)}
        borderColor={isHardLimitReached ? '#D32F2F' : '#D0CFD2'}
      />
      <div className='mt-1 flex flex-col gap-1'>
        {characterHelperText && (
          <div
            style={{
              color: currentValue.length === 0 ? '#00000099' : helperColor,
              fontSize: '0.75rem',
            }}>
            {`${characterHelperText} characters`}
            {isNearLimit &&
              !isAtCharLimit &&
              getText(characterLimit, characterLimitType, 'character', true, reachedCharLimit)}
            {isAtCharLimit &&
              getText(characterLimit, characterLimitType, 'character', false, reachedCharLimit)}
          </div>
        )}
        {wordHelperText && (
          <div
            style={{
              color: currentValue.length === 0 ? '#00000099' : wordHelperColor,
              fontSize: '0.75rem',
            }}>
            {`${wordHelperText} words`}
            {isNearWordLimit &&
              !isAtWordLimit &&
              getText(wordLimit, wordLimitType, 'word', true, reachedWordLimit)}
            {isAtWordLimit && getText(wordLimit, wordLimitType, 'word', false, reachedWordLimit)}
          </div>
        )}
      </div>
    </div>
  )
}

export default MultiLineString
