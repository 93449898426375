import type { TProcessWithBases } from '@invisible/common/components/process-base'
import { useStore } from '@invisible/common/stores/process-store'
import { isWizard, isWizardless } from '@invisible/common/types'
import { RobotHeadIcon, SumIcon } from '@invisible/ui/icons'
import { DurationCell } from '@invisible/ui/react-table'
import { createColumnHelper, Table } from '@invisible/ui/table'
import { filter, flatten, flow, map } from 'lodash/fp'
import { FC, memo, useCallback, useEffect, useMemo } from 'react'
import shallow from 'zustand/shallow'

import { HeaderCellCustomSort } from './baseViewComponents/HeaderCell'
import { MemoizedBaseViewTable } from './BaseViewTable'
import { AssigneeCell } from './customCells/AssigneeCell'
import { AutomatedStepStatusCell } from './customCells/AutomatedStepStatusCell'
import { BaseRunStatusCell } from './customCells/BaseRunStatusCell'
import { DeadlineCell } from './customCells/DeadlineCell'
import { getCustomCell } from './customCells/GetCustomCell'
import { TBaseRunQueryData } from './hooks/useGetBaseRuns'
import { StepRunControls } from './StepRunControls'
import { WizardButton } from './WizardButton'

type TBaseRun = TBaseRunQueryData['items'][number]
type TBase = TProcessWithBases['bases'][number]
export type TStep = TBase['steps'][number]
export type TAssigneeUser = TBaseRun['stepRuns'][number]['assignee']
type TBaseView = TBase['baseViews'][number]

export const calculateTextWidth = (text: string, font = 14) => (font * text.length) / 2

const columnHelper = createColumnHelper<Record<string, any>>()

const DurationCellMemoized = memo(DurationCell)
const DeadlineCellMemoized = memo(DeadlineCell)
const AutomatedStepStatusCellMemoized = memo(AutomatedStepStatusCell)
const AssigneeCellMemoized = memo(AssigneeCell)
const WizardButtonMemoized = memo(WizardButton)
const BaseRunStatusCellMemoized = memo(BaseRunStatusCell)
const StepRunControlsMemoized = memo(StepRunControls)

export interface IBaseViewProps {
  data: Record<string, any>[]
  base: TBase
  baseView: TBaseView
  height?: string
  baseDataLoading: boolean
  itemCount: number
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const BaseView = ({
  base,
  data,
  baseView,
  height,
  baseDataLoading,
  itemCount,
}: IBaseViewProps) => {
  const {
    createdAtTZ,
    deliveredAtTZ,
    columnOrder,
    hiddenColumns,
    setIsPrivate,
    updateCreatedAtTz,
    updateDeliveredAtTZ,
  } = useStore(
    useCallback(
      (state) => ({
        createdAtTZ: state.createdAtTZ,
        deliveredAtTZ: state.deliveredAtTZ,
        columnOrder: state.columnOrder,
        hiddenColumns: state.hiddenColumns,
        setIsPrivate: state.setIsPrivate,
        updateCreatedAtTz: state.setCreatedAtTZ,
        updateDeliveredAtTZ: state.setDeliveredAtTZ,
      }),
      []
    ),
    shallow
  )

  const formattedVariables = useMemo(
    () =>
      map((variable) => {
        const width = Math.max(calculateTextWidth(variable.name) + 12, 300)
        return columnHelper.accessor(variable.id, {
          enableColumnFilter: false,
          enableSorting: false,
          header: (props) => (
            <HeaderCellCustomSort
              name={variable.name}
              headerContext={props}
              sortId={variable.id}
              filter='normal'
              style={{ width: `${width}px` }} // we use inline style to override default width fit-content
            />
          ),
          cell: (info) => (
            <Table.Cell itemsCenter>{getCustomCell(variable.type, width).Cell(info)}</Table.Cell>
          ),
        })
      }, base.baseVariables),
    [base.baseVariables]
  )

  useEffect(() => {
    setIsPrivate(Boolean(baseView.userId))
  }, [baseView.userId, setIsPrivate])

  const formattedWizardSteps = useMemo(
    () =>
      flow(
        filter((step: TStep) => isWizard(step)),
        map((step) => [
          columnHelper.accessor(step.name, {
            id: step.name,
            enableColumnFilter: false,
            enableSorting: false,
            header: (props) => {
              // Column should always be wide enough to contain the status button
              const width = Math.max(180, calculateTextWidth(step.name) + 100)
              return (
                <HeaderCellCustomSort
                  name={step.name}
                  stepRunIds={props.table.options.data
                    .map((data) => data[step.id])
                    .map((data) => data.stepRun?.id)}
                  headerContext={props}
                  isStepStatus={true}
                  style={{ width: `${width}px` }}
                />
              )
            },
            cell: (info) => (
              <Table.Cell itemsCenter>
                <WizardButtonMemoized
                  stepMeta={info.getValue().actualValue}
                  stepRun={info.getValue().stepRun}
                  wizardInitialBRVs={info.getValue().wizardInitialBRVs}
                  baseRun={info.getValue().baseRun}
                  stepName={step.name}
                  processId={info.getValue().processId}
                />
              </Table.Cell>
            ),
          }),
          columnHelper.accessor(step.id, {
            enableColumnFilter: false,
            enableSorting: false,
            header: (props) => {
              const width = calculateTextWidth(`${step.name}: Assignee`) + 120
              return (
                <HeaderCellCustomSort
                  name={`${step.name}: Assignee`}
                  headerContext={props}
                  isStepAssignee={true}
                  style={{ width: `${width}px` }}
                />
              )
            },
            cell: (info) => (
              <Table.Cell itemsCenter>
                <AssigneeCellMemoized
                  assignee={info.getValue().actualValue as TAssigneeUser}
                  stepRun={info.getValue().stepRun}
                  baseId={base.id}
                  baseRunId={info.getValue().baseRunId}
                />
              </Table.Cell>
            ),
          }),
        ]),
        flatten
      )(base.steps),
    [base.id, base.steps, baseView.id, data]
  )

  const formattedWizardlessSteps = useMemo(
    () =>
      flow(
        filter((step: TStep) => isWizardless(step)),
        map((step) => [
          columnHelper.accessor(step.name, {
            id: step.name,
            header: (props) => {
              // Column should always be wide enough to contain the status button
              const width = Math.max(180, calculateTextWidth(step.name) + 100)
              return (
                <HeaderCellCustomSort
                  name={step.name}
                  headerContext={props}
                  isStepStatus={true}
                  style={{ width: `${width}px` }}
                />
              )
            },
            cell: (info) => (
              <Table.Cell itemsCenter>
                <StepRunControlsMemoized stepRun={info.getValue().actualValue} />,
              </Table.Cell>
            ),
          }),
          columnHelper.accessor(step.id, {
            header: (props) => {
              const width = calculateTextWidth(`${step.name}: Assignee`) + 120
              return (
                <HeaderCellCustomSort
                  name={`${step.name}: Assignee`}
                  headerContext={props}
                  isStepAssignee={true}
                  style={{ width: `${width}px` }}
                />
              )
            },
            cell: (info) => (
              <Table.Cell itemsCenter>
                <AssigneeCellMemoized
                  assignee={info.getValue().actualValue as TAssigneeUser}
                  stepRun={info.getValue().stepRun}
                  baseId={base.id}
                  baseRunId={info.getValue().baseRunId}
                />
              </Table.Cell>
            ),
          }),
        ]),
        flatten
      )(base.steps),
    [base.id, base.steps, baseView.id]
  )

  const formattedAutomatedSteps = useMemo(
    () =>
      flow(
        filter((step: TStep) => step.stepTemplate.type === 'full_auto'),
        map((step) => {
          const width = calculateTextWidth(step.name) + 100
          return [
            columnHelper.accessor(step.name, {
              id: step.name,
              enableColumnFilter: false,
              enableSorting: false,
              header: (props) => (
                <HeaderCellCustomSort
                  name={step.name}
                  headerContext={props}
                  isStepStatus={true}
                  icon={<RobotHeadIcon width={16} height={16} />}
                  style={{ width: `${width}px` }}
                />
              ),
              cell: (info) => (
                <Table.Cell itemsCenter>
                  <AutomatedStepStatusCellMemoized
                    status={info.getValue().actualValue ?? 'notStarted'}
                    stepRun={info.getValue().stepRun}
                  />
                </Table.Cell>
              ),
            }),
          ]
        }),
        flatten
      )(base.steps),
    [base.steps]
  )

  const formattedChildBases = useMemo(
    () =>
      flow(
        map((childBase: TBase) =>
          columnHelper.accessor(childBase.id ?? 0, {
            enableColumnFilter: false,
            enableSorting: false,
            header: (props) => (
              <HeaderCellCustomSort
                name={childBase.name}
                headerContext={props}
                isStepStatus={true}
                icon={<SumIcon width={16} height={16} />}
              />
            ),
          })
        )
      )(base.children),
    [base.children]
  )

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('id', {
          enableColumnFilter: false,
          enableSorting: false,
          header: (props) => (
            <HeaderCellCustomSort name='ID' headerContext={props} style={{ width: '320px' }} />
          ),
        }),
        columnHelper.accessor('baseRunStatus', {
          enableColumnFilter: false,
          enableSorting: false,
          header: (props) => (
            <HeaderCellCustomSort
              name='BaseRun Status'
              headerContext={props}
              style={{ width: '200px' }}
              isBaseRunStatus={true}
            />
          ),
          cell: (info) => (
            <Table.Cell itemsCenter key={info.getValue()}>
              <BaseRunStatusCellMemoized status={info.getValue()} />
            </Table.Cell>
          ),
        }),
        columnHelper.accessor('timeleft', {
          enableColumnFilter: false,
          enableSorting: false,
          header: (props) => (
            <HeaderCellCustomSort
              name='Time Left'
              headerContext={props}
              sortId='timeleft'
              style={{ width: '150px' }}
            />
          ),
          cell: (info) => (
            <Table.Cell itemsCenter>
              {info.getValue() ? (
                <DurationCellMemoized
                  duration={info.getValue()}
                  type={
                    new Date(info.getValue()).getTime() - new Date().getTime() < 10000000
                      ? 'danger'
                      : new Date(info.getValue()).getTime() - new Date().getTime() < 20000000
                      ? 'warning'
                      : 'safe'
                  }
                />
              ) : null}
            </Table.Cell>
          ),
        }),
        ...(base.parentId
          ? [
              columnHelper.accessor('parentBaseRunId', {
                enableColumnFilter: false,
                enableSorting: false,
                header: (props) => (
                  <HeaderCellCustomSort
                    name='Batch'
                    headerContext={props}
                    filter='normal'
                    isParentBaseRunId={true}
                  />
                ),
                cell: (info) => (
                  <Table.Cell key={info.getValue()}>
                    <Table.Cell.Span>{info.getValue()}</Table.Cell.Span>
                  </Table.Cell>
                ),
              }),
            ]
          : []),
        columnHelper.accessor('createdAt', {
          enableColumnFilter: false,
          enableSorting: false,
          header: (props) => (
            <HeaderCellCustomSort
              name='Created At'
              headerContext={props}
              timezone={createdAtTZ}
              updateTimezone={updateCreatedAtTz}
              sortId='createdAt'
              filter='datetime'
              style={{ width: '150px' }}
              isLeftSide={((columnOrder ?? []) as string[])?.indexOf('Created At') <= 0}
            />
          ),
          meta: {
            timezone: createdAtTZ,
          },
          cell: (info) => (
            <Table.CellDate
              date={info.getValue()}
              timezone={info.column.columnDef?.meta?.timezone}
            />
          ),
        }),
        columnHelper.accessor('deadline', {
          enableColumnFilter: false,
          enableSorting: false,
          header: (props) => (
            <HeaderCellCustomSort
              name='Deadline'
              headerContext={props}
              sortId='deadline'
              filter='datetime'
              style={{ width: '200px' }}
              isLeftSide={((columnOrder ?? []) as string[])?.indexOf('Created At') <= 0}
            />
          ),
          cell: (info) => (
            <Table.Cell itemsCenter>
              <DeadlineCellMemoized
                baseRunId={info.row.original.id}
                value={info.getValue() ? new Date(info.getValue()) : null}
              />
            </Table.Cell>
          ),
        }),
        columnHelper.accessor('deliveredAt', {
          enableColumnFilter: false,
          enableSorting: false,
          header: (props) => (
            <HeaderCellCustomSort
              name='Delivered At'
              headerContext={props}
              timezone={deliveredAtTZ}
              updateTimezone={updateDeliveredAtTZ}
              sortId='deliveredAt'
              filter='datetime'
              style={{ width: '200px' }}
              isLeftSide={((columnOrder ?? []) as string[])?.indexOf('Created At') <= 0}
            />
          ),
          meta: {
            timezone: deliveredAtTZ,
          },
          cell: (info) => (
            <Table.CellDate
              date={info.getValue()}
              timezone={info.column.columnDef?.meta?.timezone}
            />
          ),
        }),
        ...formattedVariables,
        ...formattedWizardSteps,
        ...formattedWizardlessSteps,
        ...formattedAutomatedSteps,
        ...formattedChildBases,
      ]
        .filter((column) => !hiddenColumns.includes(column.accessorKey))
        .sort((a, b) => {
          const getIndex = (name: string | number) => {
            if (typeof name === 'number') return name
            return ((columnOrder ?? []) as string[])?.indexOf(name) < 0
              ? 1000
              : ((columnOrder ?? []) as string[])?.indexOf(name)
          }

          return getIndex(a.accessorKey) - getIndex(b.accessorKey)
        }),
    [
      createdAtTZ,
      deliveredAtTZ,
      base.parentId,
      columnOrder,
      formattedAutomatedSteps,
      formattedChildBases,
      formattedVariables,
      formattedWizardSteps,
      formattedWizardlessSteps,
      hiddenColumns,
    ]
  )

  return (
    <MemoizedBaseViewTable
      base={base}
      columns={columns}
      data={data}
      baseDataLoading={baseDataLoading}
      itemCount={itemCount}
    />
  )
}
